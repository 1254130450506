import React from "react";
import KwaleeLogo from "../../media/logo.png";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const CheckEmail = () => {
  const classes = useStyles();

  return (
    <div className="full-page">
      <div className="full-page-section flex-column">
        <div className="full-page-form">
          <div className="full-page-form-container text-center">
            <div className="logo">
              <img className={classes.kwaleeLogo} src={KwaleeLogo} alt={""} />
            </div>
            <div className={classes.headText}>ACCOUNT CREATED</div>
            <div className={classes.subText}>
              Your account has been created, please check your email to activate
              it.
            </div>
            <div className={classes.subText}>
              Click
              <Link
                className="text-dark font-weight-bold"
                to="/login"
                style={{ display: "contents" }}
              >
                {" "}
                here
              </Link>{" "}
              to login.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  kwaleeLogo: {
    paddingBottom: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "16rem",
      padding: 0,
    },
  },
  headText: {
    fontSize: "30px",
    fontWeight: "bold",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginTop: "50px",
    },
  },
  subText: {
    fontSize: "18px",
    paddingBottom: 20,
  },
}));

export default CheckEmail;
