//Create a store boilerplate
import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "../reducers"; //because it's index.js we can bring in the folder
import history from "../util/createBrowserHistory";

const initialState = {}; //Initial state will be in the reducers

const middleware = [routerMiddleware(history), thunk, logger];

const store = createStore(
  rootReducer(history),
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
