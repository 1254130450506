import i18next from "i18next";

export default function parseError(error) {
  if (error.isAxiosError && error.response?.data?.loc_key) {
    const err = new Error(
      i18next.t(error.response.data.loc_key, {
        ...error.response.data.loc_kw,
        ns: "server",
      })
    );
    err.loc_key = error.response.data.loc_key;
    err.loc_kw = error.response.data.loc_kw;

    return err;
  }

  return error;
}
