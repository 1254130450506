const PUBLIC = "/public";

const PUBLIC_USERS = `${PUBLIC}/users`;
export const PUBLIC_USER = `${PUBLIC_USERS}/{id}`;

const PUBLIC_ORGANISATIONS = `${PUBLIC}/organisations`;
export const PUBLIC_ORGANISATION = `${PUBLIC_ORGANISATIONS}/{id}`;

export const USER_FEEDBACK = "/user-feedback";

export const ROLES = "/roles";

export const LOCALISATION = "/localisation";

export const HYBRID_SUBMISSION = "/hybrid-submission";
export const HYBRID_SUBMISSION_FILES = "/hybrid-submission-files";
