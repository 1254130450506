import i18next from "i18next";
import parseError from "../../util/parseError";

export const returnMessage = (res) => ({
  loc_key: res.data.loc_key,
  loc_kw: res.data.loc_kw,
});

export async function processEndpoint(
  request,
  { returnData = (res) => res.data.data, onErrorStatusCode = {} } = {}
) {
  try {
    const res = await request;

    if (res.data.is_valid) {
      return returnData(res);
    }

    const err = new Error(
      res.data.loc_key
        ? i18next.t(res.data.loc_key, {
            ...res.data.loc_kw,
            ns: "server",
          })
        : "Unknown server error"
    );
    err.loc_key = res.data.loc_key;
    err.loc_kw = res.data.loc_kw;

    throw err;
  } catch (e) {
    console.error(e);

    if (e.isAxiosError && e.response) {
      const onErrorCode = onErrorStatusCode[e.response.status];
      if (typeof onErrorCode === "function") {
        return onErrorCode(e);
      }
    }

    throw parseError(e);
  }
}
