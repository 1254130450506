import { axiosConfig } from "../../axiosConfig";
import {
  ADMIN_PROJECT_TESTS_ALL,
  ADMIN_PROJECTS,
} from "../admin/admin.endpoints";
import { processEndpoint } from "../service.helper";
import {
  ARES_ERROR_MAPPING,
  HYBRID_PROJECTS,
  PROJECT,
  PROJECT_PERMITTED_TEST_LOGS,
  PROJECT_SHEET,
  PROJECT_TEST,
  PROJECT_TEST_ERROR_LOG,
  PROJECT_TEST_VALIDATE_SDK,
  PROJECT_TEST_WINDOW,
  PROJECT_TESTS,
  TASKS,
} from "./project.endpoints";

export async function getAdminProjects({
  name,
  organisation_id,
  organisation_name,
  secondary_attribution_ids,
  tested_platforms,
  latest_test_current_status,
  status,
  order_by = "created_on",
  order_dir = "desc",
} = {}) {
  return await processEndpoint(
    axiosConfig.get(ADMIN_PROJECTS, {
      params: {
        name,
        organisation_id,
        organisation_name,
        secondary_attribution_ids,
        tested_platforms,
        latest_test_current_status,
        status,
        order_by,
        order_dir,
      },
    }),
    {
      returnData: (res) => {
        let { data } = res.data;

        if (!Array.isArray(data)) {
          data = [];
        }

        return data;
      },
      onErrorStatusCode: {
        404: () => [],
      },
    }
  );
}

export async function getAdminProjectTests({
  status,
  project_id,
  test_type,
  platform,
  project_name,
  campaign_id,
  project_test_account_id,
  created_after,
  created_before,
} = {}) {
  return await processEndpoint(
    axiosConfig.get(ADMIN_PROJECT_TESTS_ALL, {
      params: {
        status,
        project_id,
        test_type,
        platform,
        project_name,
        campaign_id,
        project_test_account_id,
        created_after,
        created_before,
      },
    }),
    {
      returnData: (res) => {
        let { data } = res.data;

        if (!Array.isArray(data)) {
          data = [];
        }

        return data;
      },
      onErrorStatusCode: {
        404: () => [],
      },
    }
  );
}

export async function getProject(id) {
  return await processEndpoint(axiosConfig.get(PROJECT.replace(/{id}/gi, id)));
}

export async function getProjectSheet(id) {
  return await processEndpoint(
    axiosConfig.get(PROJECT_SHEET.replace(/{id}/gi, id))
  );
}

export async function getProjectTests(id) {
  return await processEndpoint(
    axiosConfig.get(PROJECT_TESTS.replace(/{id}/gi, id)),
    {
      onErrorStatusCode: {
        404: () => [],
      },
    }
  );
}

export async function getProjectTest(id, testId) {
  return await processEndpoint(
    axiosConfig.get(
      PROJECT_TEST.replace(/{id}/gi, id).replace(/{testId}/gi, testId)
    ),
    {
      onErrorStatusCode: {
        404: () => undefined,
      },
    }
  );
}

export async function createProjectTestWindow(id, testId) {
  return await processEndpoint(
    axiosConfig.post(
      PROJECT_TEST_WINDOW.replace(/{id}/gi, id).replace(/{testId}/gi, testId)
    ),
    {
      returnData: (res) => res.data,
    }
  );
}

export async function validateProjectTestSdk(id, testId) {
  return await processEndpoint(
    axiosConfig.post(
      PROJECT_TEST_VALIDATE_SDK.replace(/{id}/gi, id).replace(
        /{testId}/gi,
        testId
      )
    ),
    {
      returnData: (res) => res.data,
    }
  );
}

export async function getProjectPermittedTestLogs(
  id,
  { page = 0, page_size = 10, order_by = "created_on", order_dir = "desc" } = {}
) {
  return await processEndpoint(
    axiosConfig.get(PROJECT_PERMITTED_TEST_LOGS.replace(/{id}/gi, id), {
      params: {
        page,
        page_size,
        order_by,
        order_dir,
      },
    }),
    {
      onErrorStatusCode: {
        404: () => ({
          logs: [],
          total_size: 0,
          page: 1,
          page_size,
        }),
      },
    }
  );
}

export async function getTasks(projectId, testId) {
  return await processEndpoint(
    axiosConfig.get(
      TASKS.replace(/{id}/gi, projectId).replace(/{testId}/gi, testId)
    ),
    {
      onErrorStatusCode: {
        404: () => ({
          status: "inactive",
          pages: [],
        }),
      },
    }
  );
}

export async function getErrorMapping(id) {
  return await processEndpoint(
    axiosConfig.get(ARES_ERROR_MAPPING.replace(/{id}/gi, id)),
    {
      onErrorStatusCode: {
        404: () => undefined,
      },
    }
  );
}

export async function getProjectTestErrorLog(testId, status) {
  return await processEndpoint(
    axiosConfig.get(PROJECT_TEST_ERROR_LOG, {
      params: {
        project_test_id: testId,
        status,
      },
    }),
    {
      onErrorStatusCode: {
        404: () => [],
      },
    }
  );
}

export async function getHybridGames() {
  return await processEndpoint(axiosConfig.get(HYBRID_PROJECTS), {
    onErrorStatusCode: {
      404: () => [],
    },
  });
}
