import {
  EDIT_DATA_FAIL,
  EDIT_DATA_SUCCESS,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  LOST_PASSWORD_EMAIL_SUCCESS,
  LOST_PASSWORD_EMAIL_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_LICENCE_SUCCESS,
  GET_LICENCE_FAILURE,
  AGREE_LICENCE_SUCCESS,
  AGREE_LICENCE_FAILURE,
} from "../actions/types";

export function editAccount(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case EDIT_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case EDIT_DATA_FAIL:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const initialState = {
  isEmaliValidate: "",
  status: "",
};

export function validateEmail(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        isEmaliValidate: payload,
      };
    case VALIDATE_EMAIL_FAILURE:
      return {
        ...state,
        status: payload,
      };
    default:
      return state;
  }
}

const initialLostState = {
  isEmaliValidate: "",
  status: "",
};

export function lostPasswordEmailValidate(state = initialLostState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOST_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        isEmaliValidate: payload,
      };
    case LOST_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        status: payload,
      };
    default:
      return state;
  }
}

export function login(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getLicence(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LICENCE_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_LICENCE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function agreeLicence(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case AGREE_LICENCE_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case AGREE_LICENCE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
