import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import cx from "classnames";
import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../actions/notifier";
import { submitHybridFiles } from "../../data/service/public/public.service";

const useStyles = makeStyles((theme) => ({
  upload: {
    color: "#000",
    background: "#FFF",
    boxShadow: "inset 0 0 0 0.1rem #000 !important",

    "&:hover, &:focus": {
      background: "#000",
      color: "#FFF",
    },

    "&:disabled": {
      background: "#FFF",
      color: "#000",
    },
  },
  fileHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionDetails: {
    flexDirection: "column",
    alignItems: "center",
  },
  returnLink: {
    color: "#000 !important",
    display: "block",
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  linkText: {
    color: "#007bff",
    cursor: "pointer",
  },
}));

const METRIC_OPTION = [
  {
    label: "CPI",
    name: "cpi",
    options: [
      "Not Tested",
      "Over $2",
      "Between $1 and $2",
      "Between 50 cents and $1",
      "Under 50 cents",
    ],
  },
  {
    label: "D0 Playtime",
    name: "d0_playtime",
    options: [
      "Not Tested",
      "Under 10 minutes",
      "Between 10 and 20 minutes",
      "Over 20 minutes",
    ],
  },
  {
    label: "Day 1 Retention",
    name: "day_1_retention",
    options: [
      "Not Tested",
      "Under 20%",
      "Between 20% and 30%",
      "Between 30% and 40%",
      "Over 40%",
    ],
  },
  {
    label: "Day 7 Retention",
    name: "day_7_retention",
    options: ["Not Tested", "Under 5%", "Between 5% and 10%", "Over 10%"],
  },
  {
    label: "Day 30 Retention",
    name: "day_30_retention",
    options: ["Not Tested", "Under 2%", "Between 2% and 5%", "Over 5%"],
  },
  {
    label: "LTV at Day 60",
    name: "day_60_ltv",
    options: [
      "Not Tested",
      "Under 50 cents",
      "Between 50 cents and $1",
      "Between $1 and $2",
      "Over $2",
    ],
  },
];

export default function FileUpload({ files, setFiles, hs_deal_id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const fileRef = useRef();

  const [uploadState, setUploadState] = useState(null);

  const [viewedFile, setViewedFile] = useState("");

  const [videoPreviews, setVideoPreviews] = useState({});

  const [testMetrics, setTestMetrics] = useState();

  useEffect(() => {
    let active = true;

    (async () => {
      const previews = {};

      const promises = [];
      for (const file of files) {
        const promise = new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            previews[file.name] = e.target.result;
            resolve();
          };
          reader.readAsDataURL(file);
        });

        promises.push(promise);
      }

      await Promise.allSettled(promises);

      if (active) {
        setVideoPreviews(previews);
      }
    })();

    return () => {
      active = false;
    };
  }, [files]);

  const onFileChange = (e) => {
    const newFiles = Array.from(e.target.files).filter(
      (file) => !files.some((f) => f.name === file.name)
    );

    e.target.value = null;

    // Files must be less than 100MB
    let sizeLimited = false;
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      if (file.size < 100 * 1024 * 1024) {
        continue;
      }

      sizeLimited = true;
      newFiles.splice(i, 1);
    }

    if (sizeLimited) {
      dispatch(enqueueSnackbar("Files must be less than 100MB", "warning"));
    }

    let combined = [...files, ...newFiles];
    if (combined.length > 3) {
      combined = combined.slice(0, 3);

      dispatch(
        enqueueSnackbar("You can not provide more than 3 files...", "warning")
      );
    }

    setFiles(combined);
  };

  const onSubmit = async () => {
    setUploadState("uploading");

    // Submit files
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }

    formData.append("hs_deal_id", hs_deal_id);

    if (testMetrics) {
      if (
        testMetrics.hasOwnProperty("cpi") &&
        testMetrics.cpi !== "Not Tested"
      ) {
        formData.append("cpi", testMetrics.cpi);
      }

      if (
        testMetrics.hasOwnProperty("d0_playtime") &&
        testMetrics.d0_playtime !== "Not Tested"
      ) {
        formData.append("d0_playtime", testMetrics.d0_playtime);
      }

      if (
        testMetrics.hasOwnProperty("day_1_retention") &&
        testMetrics.day_1_retention !== "Not Tested"
      ) {
        formData.append("day_1_retention", testMetrics.day_1_retention);
      }

      if (
        testMetrics.hasOwnProperty("day_7_retention") &&
        testMetrics.day_7_retention !== "Not Tested"
      ) {
        formData.append("day_7_retention", testMetrics.day_7_retention);
      }

      if (
        testMetrics.hasOwnProperty("day_30_retention") &&
        testMetrics.day_30_retention !== "Not Tested"
      ) {
        formData.append("day_30_retention", testMetrics.day_30_retention);
      }

      if (
        testMetrics.hasOwnProperty("day_60_ltv") &&
        testMetrics.day_60_ltv !== "Not Tested"
      ) {
        formData.append("day_60_ltv", testMetrics.day_60_ltv);
      }
    }

    try {
      // Create submission
      await submitHybridFiles(formData);

      // Complete
      setUploadState("complete");
    } catch (error) {
      dispatch(
        enqueueSnackbar(
          i18next.t(error.loc_key, {
            ...error.loc_kw,
            ns: "server",
          }),
          "error"
        )
      );

      setUploadState(null);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box component="h2" textAlign="center">
          Thank you for your submission!
        </Box>
        <Box p={2}>
          <Typography gutterBottom>
            We will now review your submission. Please add{" "}
            <a href="mailto:publishing@kwalee.com">publishing@kwalee.com</a> to
            your game's analytics system to allow us to see your metrics.
          </Typography>
          {uploadState === "complete" ? (
            <Typography>
              Thank you for adding additional supporting documentation.
            </Typography>
          ) : (
            <Typography>
              If you have any supporting documentation please upload it below.
            </Typography>
          )}
        </Box>
      </Grid>
      {uploadState !== "complete" && (
        <>
          <Grid item xs={12}>
            {files.length > 0 && (
              <Box mb={2}>
                {files.map((file) => (
                  <Accordion
                    key={file.name}
                    expanded={viewedFile === file.name}
                    onChange={() =>
                      setViewedFile(viewedFile === file.name ? "" : file.name)
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.fileHeading}>
                        {file.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      {file.type.startsWith("video/") &&
                        (videoPreviews[file.name] ? (
                          <video
                            src={videoPreviews[file.name]}
                            controls
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ))}

                      <label
                        className={classes.linkText}
                        onClick={(e) => {
                          e.preventDefault();

                          if (uploadState !== null) {
                            return;
                          }

                          setFiles(files.filter((f) => f.name !== file.name));
                        }}
                      >
                        Remove
                      </label>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}

            <input
              ref={fileRef}
              type="file"
              hidden
              multiple
              accept="application/pdf"
              onChange={onFileChange}
            />
            <button
              type="button"
              disabled={uploadState !== null}
              className={cx("btn btn-secondary w-100 mb-2", classes.upload)}
              onClick={() => fileRef.current.click()}
            >
              Select Files
            </button>
          </Grid>

          <Box py={1} px={2}>
            <Typography gutterBottom>
              Additionally, If you've previously tested your game and have some
              metrics, please provide them below.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {METRIC_OPTION.map(({ name, label, options }, index) => (
              <Grid key={index} item xs={12} md={6}>
                <div className="form-group">
                  <label>{label}</label>
                  <select
                    className="form-control"
                    name={name}
                    value={testMetrics && testMetrics[name]}
                    onChange={(e) => {
                      const { name, value } = e.target;

                      setTestMetrics((prevState) => ({
                        ...prevState,
                        [name]: value,
                      }));
                    }}
                  >
                    {options.map((selectOption) => (
                      <option key={selectOption} value={selectOption}>
                        {selectOption}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {uploadState !== "complete" && (
          <button
            type="button"
            className="btn btn-secondary btn-hover-outline w-100"
            disabled={uploadState !== null}
            onClick={onSubmit}
          >
            {uploadState === "uploading" ? (
              <span>Uploading...</span>
            ) : (
              <>
                {t("upload.text")}{" "}
                <i className="fa fa-arrow-right" aria-hidden="true" />
              </>
            )}
          </button>
        )}
        <a href="/" className={classes.returnLink}>
          Return to homepage
        </a>
      </Grid>
    </Grid>
  );
}
