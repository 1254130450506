import { PROJECT_TEST_ERROR_LOG } from "../project/project.endpoints";
import { USER_FEEDBACK } from "../public/public.endpoints";

export const ADMIN = "/admin";

export const ADMIN_TAGS = `${ADMIN}/tag`;
export const ADMIN_TAG = `${ADMIN_TAGS}/{id}`;

export const ADMIN_INVITE_LINKS = `${ADMIN}/invite_link`;
export const ADMIN_INVITE_LINK = `${ADMIN_INVITE_LINKS}/{id}`;

export const ADMIN_INVITE_FUNNEL_COUNT = `${ADMIN}/invite_funnel_counts`;
export const ADMIN_INVITE_FUNNEL_ORG_LIST = `${ADMIN}/invite_funnel_organisation_list`;
export const ADMIN_GAME_TEST_FUNNEL_COUNT = `${ADMIN}/game_submission_funnel_count`;

const ADMIN_INVITE_LINK_KPIS = `${ADMIN}/invite_link_kpi`;
export const ADMIN_INVITE_LINK_KPI = `${ADMIN_INVITE_LINK_KPIS}/{id}`;

export const ADMIN_TEST_ACCOUNTS = `${ADMIN}/project_test_accounts`;
export const ADMIN_TEST_ACCOUNT = `${ADMIN_TEST_ACCOUNTS}/{id}`;
export const ADMIN_TEST_ACCOUNT_KPIS = `${ADMIN_TEST_ACCOUNT}/kpis`;

export const ADMIN_CURRENCIES = `${ADMIN}/currencies`;
export const ADMIN_CURRENCY = `${ADMIN_CURRENCIES}/{id}`;

const ADMIN_TEST_COSTS = `${ADMIN}/project_test_costs`;
export const ADMIN_TEST_COST = `${ADMIN_TEST_COSTS}/{id}`;

export const ADMIN_PROJECTS = `${ADMIN}/projects`;
export const ADMIN_PROJECT = `${ADMIN_PROJECTS}/{id}`;

export const ADMIN_PROJECT_TESTS_ALL = `${ADMIN}/project_tests`;

export const ADMIN_PROJECT_SHEET = `${ADMIN_PROJECT}/sheet`;

export const ADMIN_PROJECT_TESTS = `${ADMIN_PROJECT}/tests`;
export const ADMIN_PROJECT_TEST = `${ADMIN_PROJECT_TESTS}/{testId}`;

export const ADMIN_PROJECT_TEST_WINDOW = `${ADMIN_PROJECT_TEST}/windows`;
export const ADMIN_PROJECT_TEST_VALIDATE_SDK = `${ADMIN_PROJECT_TEST}/validate_sdk`;

export const ADMIN_PROJECT_TEST_TOOLS = `${ADMIN_PROJECT_TEST}/tools`;

export const ADMIN_PROJECT_TEMPLATE = `${ADMIN}/project_template`;

export const ADMIN_PROJECT_TEST_SUBMISSION_TEMPLATES = `${ADMIN}/project_test_submission_templates`;
export const ADMIN_PROJECT_TEST_SUBMISSION_TEMPLATE = `${ADMIN_PROJECT_TEST_SUBMISSION_TEMPLATES}/{id}`;

export const ADMIN_ORGANISATIONS = `${ADMIN}/organisations`;
export const ADMIN_ORGANISATION_SINGULAR = `${ADMIN}/organisation`;
export const ADMIN_ORGANISATIONS_NOTIFICATION_DEFAULT_PREFERENCE = `${ADMIN_ORGANISATIONS}/notification_default_preference`;

export const ADMIN_ORGANISATION = `${ADMIN_ORGANISATIONS}/{id}`;
export const ADMIN_ORGANISATION_RESTORE = `${ADMIN_ORGANISATION_SINGULAR}/{id}/restore`;
export const ADMIN_ORGANISATION_WALLET = `${ADMIN_ORGANISATION}/wallet`;
export const ADMIN_ORGANISATION_PROJECTS = `${ADMIN_ORGANISATION}/projects`;
export const ADMIN_ORGANISATION_NOTIFICATION_PREFERENCE = `${ADMIN_ORGANISATION}/notification_preference`;

export const ADMIN_LOGS = `${ADMIN}/logs`;

export const ADMIN_USERS = `${ADMIN}/users`;
export const ADMIN_USERS_NOTIFICATION_DEFAULT_PREFERENCE = `${ADMIN_USERS}/notification_default_preference`;

export const ADMIN_USER = `${ADMIN_USERS}/{id}`;
export const ADMIN_USER_NOTIFICATION_PREFERENCE = `${ADMIN_USER}/notification_preference`;

export const ADMIN_MESSAGE_CATEGORIES = `${ADMIN}/message_categories`;
export const ADMIN_MESSAGE_CATEGORY = `${ADMIN_MESSAGE_CATEGORIES}/{id}`;

export const ADMIN_MESSAGE_TEMPLATES = `${ADMIN}/message_templates`;
export const ADMIN_MESSAGE_TEMPLATE = `${ADMIN_MESSAGE_TEMPLATES}/{id}`;

export const ADMIN_PROJECT_TEST_RESULT_CONFIGS = `${ADMIN}/project_test_result_config`;
export const ADMIN_PROJECT_TEST_RESULT_CONFIG = `${ADMIN_PROJECT_TEST_RESULT_CONFIGS}/{id}`;

export const ADMIN_LICENSES = `${ADMIN}/licences`;
export const ADMIN_LICENSE = `${ADMIN_LICENSES}/{id}`;
export const ADMIN_LICENSE_VERSIONS = `${ADMIN_LICENSE}/versions`;
export const ADMIN_LICENSE_VERSION = `${ADMIN_LICENSE_VERSIONS}/{versionId}`;

export const ADMIN_USER_FEEDBACK_LIST = `${ADMIN}${USER_FEEDBACK}`;
export const ADMIN_USER_FEEDBACK = `${ADMIN_USER_FEEDBACK_LIST}/{id}`;

export const ADMIN_ROLES = `${ADMIN}/roles`;
export const ADMIN_ROLE = `${ADMIN_ROLES}/{id}`;
export const ADMIN_ROLES_AUTO_ASSIGN = `${ADMIN}/auto_assign_role`;
export const ADMIN_PERMISSIONS = `${ADMIN}/permissions`;

export const ADMIN_FUNNEL_DAYS_BACK = `${ADMIN}/days_back_funnel`;
export const ADMIN_FUNNEL_PROJECT_TEST_SUBMISSION = `${ADMIN}/project_test_submission_funnel`;

export const ADMIN_REPORT = `${ADMIN}/report`;

export const ADMIN_PROJECT_TEST_REPORT = `${ADMIN}/project_test_report`;

export const ADMIN_EMAIL_REPORTS = `${ADMIN}/email-recipients`;
export const ADMIN_EMAIL_REPORT = `${ADMIN_EMAIL_REPORTS}/{id}`;

export const ADMIN_ATTRIBUTIONS = `${ADMIN}/secondary-attribution`;
export const ADMIN_ATTRIBUTION = `${ADMIN_ATTRIBUTIONS}/{id}`;

export const ADMIN_ARES_ERROR_MAPPINGS = `${ADMIN}/platform-to-ares-error-mapping`;
export const ADMIN_ARES_ERROR_MAPPING = `${ADMIN_ARES_ERROR_MAPPINGS}/{id}`;

export const ADMIN_PROJECT_TEST_ERROR_LOGS = `${ADMIN}${PROJECT_TEST_ERROR_LOG}`;
export const ADMIN_PROJECT_TEST_ERROR_LOG = `${ADMIN_PROJECT_TEST_ERROR_LOGS}/{id}`;
