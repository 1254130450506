import { darken, makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../media/logo-kwalee-hand.png";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "end",
    padding: 4,
    marginLeft: 10,
    marginRight: 10,
    flexDirection: (props) => (props.type === "own" ? "row" : "row-reverse"),
  },
  timeStampWrapper: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  timeStamp: {
    color: darken(theme.palette.kwaleeYellow, 0.2),
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },
  avatar: {
    backgroundColor: theme.palette.kwaleeYellow,
    padding: 5,

    "& img": {
      objectFit: "contain",
    },
  },
  message: {
    wordBreak: "break-word",
    padding: 7,
    backgroundColor: (props) => (props.type === "own" ? "lightgrey" : "black"),
    marginLeft: 10,
    color: (props) => (props.type === "own" ? "black" : "white"),
    maxWidth: 500,
    borderRadius: 10,
    borderBottomLeftRadius: (props) => (props.type === "own" ? 0 : 10),
    borderBottomRightRadius: (props) => (props.type === "users" ? 0 : 10),
  },
}));

export const Message = ({ message, isTyping }) => {
  const { t } = useTranslation();

  const classes = useStyles({ type: message.type, isTyping: isTyping });

  const isOwnMessage = message.type === "own";

  return (
    <>
      <div className={classes.timeStampWrapper}>
        <small className={classes.timeStamp}>
          {moment(message.time).format(t("dateFormat.timeAmPm"))}
        </small>
      </div>
      <div className={classes.row}>
        {isOwnMessage && (
          <Avatar className={classes.avatar} src={logo}>
            K
          </Avatar>
        )}
        <div className={classes.message}>
          <span>{message.message}</span>
        </div>
      </div>
    </>
  );
};
