import { makeStyles, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { startsWith } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import endPoints from "../../_helpers/endPoints.json";
import { editUserData, getUserData } from "../../actions/accounts.js";
import { enqueueSnackbar } from "../../actions/notifier";
import missingProfilePicture from "../../media/profile-pic.png";
import countryCodes from "../companyInfo/countryCodes.json";
import { userPublicKeysOptions } from "./YourAccount";

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

const useStyle = makeStyles((theme) => ({
  removeShadow: {
    "&:focus": {
      boxShadow: "none",
    },
    "& .MuiInput-input:focus": {
      boxShadow: "none",
    },
  },
}));

export default function EditAccount() {
  const classes = useStyle();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [formData, changeFormData] = useState(emptyForm());
  const [submitCheck, handleSubmitCheck] = useState(false);

  const [inputKey, changeInputKey] = useState(new Date());

  const [upImg, setUpImg] = useState();

  const [openProfile, setOpenProfile] = React.useState(false);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 1 });

  const [completedCrop, setCompletedCrop] = useState(null);
  const history = useHistory();

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      (blob) => {
        blob.name = formData.files.profile_image.name;

        try {
          const file = new File([blob], formData.files.profile_image.name, {
            type: "image/png",
          });
          changeFormData((prev) => ({
            ...prev,
            files: {
              ...prev.files,
              profile_image: file,
            },
          }));
        } catch (e) {
          console.error(e);
        }
      },
      "image/png",
      1
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  function emptyForm() {
    return {
      organisation_name: "",
      email: "",
      country: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      position: "",
      skype_id: "",
      files: { profile_image: {}, developer_logo: {} },
      profile_image: "",
      developer_logo: "",
    };
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "country") {
      const updateFormData = { ...formData };
      updateFormData.phone_number = "";
      updateFormData[name] = value;
      changeFormData(updateFormData);
    } else changeFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  function submit(event) {
    event.preventDefault();

    handleSubmitCheck(true);
    if (
      ["country", "first_name", "last_name", "email"].find(
        (data) => formData[data] === ""
      )
    )
      return;

    const newData = (({
      email,
      country,
      first_name,
      last_name,
      phone_number,
      position,
      skype_id,
      files,
      locale,
      public_keys,
    }) => {
      let final = {
        email,
        country,
        first_name,
        last_name,
        phone_number,
        position,
        skype_id,
        locale,
        public_keys: JSON.stringify(public_keys),
      };

      let sudoFiles = {};
      if (files.profile_image instanceof File)
        sudoFiles["profile_image"] = files.profile_image;
      if (files.developer_logo instanceof File)
        sudoFiles["developer_logo"] = files.developer_logo;
      if (Object.keys(files).length > 0) return { ...final, files: sudoFiles };
      return final;
    })(formData);

    let files = new FormData();
    for (let key in newData) {
      if (key === "files") {
        if (newData.files.profile_image)
          files.append("profile_image", newData.files.profile_image);
        if (newData.files.developer_logo)
          files.append("developer_logo", newData.files.developer_logo);
      } else {
        files.append(key, newData[key]);
      }
    }
    if (
      (formData.phone_number?.length ?? 0) > 0 &&
      !validatePhone(formData.phone_number)
    ) {
      dispatch(enqueueSnackbar(t("error.phoneNumber.invalid"), "warning"));
    } else {
      dispatch(editUserData(endPoints.EditAccount, files));
    }
    history.push("/account");
  }

  useEffect(() => {
    dispatch(getUserData(endPoints.GetAccount));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userData } = useSelector((state) => ({
    userData: state.userData,
  }));

  const picUpload = (input) => {
    const exten = ["image/png", "image/jpeg", "image/jpg"];

    if (!exten.includes(input.target.files[0].type)) {
      dispatch(enqueueSnackbar(t("error.fileType.invalid"), "warning"));
      changeInputKey(new Date());
      return;
    }
    if (input.target.files && input.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(input.target.files[0]);
      changeFormData({
        ...formData,
        [input.target.name]: reader.result,
        files: {
          ...formData.files,
          [input.target.name]: input.target.files[0],
        },
      });
    }
    changeInputKey(new Date());
    setOpenProfile(true);
  };

  useEffect(() => {
    if (userData.is_valid) {
      changeFormData((prev) => ({ ...prev, ...userData.data }));
    }
  }, [userData]);

  const handleOk = () => {
    setOpenProfile(false);
  };

  const handleClose = () => {
    setOpenProfile(false);
  };

  function validatePhone(phone) {
    const re = /^\d{9,15}$/;
    return re.test(phone);
  }

  return (
    <>
      <section className="full-page-headed mb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 ">
              <form onSubmit={submit}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="uploadphoto">
                      <canvas
                        id="imagePreview-pro"
                        ref={previewCanvasRef}
                        className="uploadphoto__img"
                        style={{
                          backgroundImage: `url("${formData.profile_image}")`,
                        }}
                      />
                      <div className="uploadphoto__text">
                        <input
                          key={inputKey}
                          type="file"
                          name="profile_image"
                          onChange={picUpload}
                          id="imageUpload-pro"
                          accept="image/*"
                        />
                        <label htmlFor="imageUpload-pro">
                          <img src="img/icon-pick.svg" alt="" />
                          {t("upload.image")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("name.first")}</label>
                    <div className="form-group input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-user.svg" alt="" />
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("name.first")}
                        onChange={handleChange}
                        name="first_name"
                        value={formData.first_name}
                      />
                    </div>
                    {submitCheck && formData.first_name === "" && <Required />}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("name.last")}</label>
                    <div className="form-group input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-user.svg" alt="" />
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("name.last")}
                        onChange={handleChange}
                        name="last_name"
                        value={formData.last_name}
                      />
                    </div>
                    {submitCheck && formData.last_name === "" && <Required />}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("company")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-building.svg" alt="" />
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("company")}
                        disabled
                        name="organisation_name"
                        value={formData.organisation_name ?? ""}
                      />
                    </div>
                    {submitCheck && formData.organisation_name === "" && (
                      <Required />
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("position")}</label>
                    <div className="form-group input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/position.svg" alt="" />
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("position")}
                        onChange={handleChange}
                        name="position"
                        value={formData.position}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("skypeId")}</label>
                    <div className="form-group input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-skype.svg" alt="" />
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("skypeId")}
                        onChange={handleChange}
                        name="skype_id"
                        value={formData.skype_id}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("phoneNumber")}</label>
                    <PhoneInput
                      disableSearchIcon
                      enableSearch
                      inputClass="w-100"
                      inputStyle={{ zIndex: 0, height: "auto" }}
                      country={
                        formData?.phone_number
                          ? ""
                          : formData?.country?.toLowerCase()
                      }
                      value={
                        (formData?.phone_number ?? "").length > 0
                          ? formData?.phone_number
                          : undefined
                      }
                      onChange={(phone) => {
                        changeFormData((prevState) => ({
                          ...prevState,
                          phone_number: phone,
                        }));
                      }}
                      isValid={(inputNumber, country, countries) => {
                        return countries.some((country) => {
                          return (
                            (startsWith(inputNumber, country.dialCode) ||
                              startsWith(country.dialCode, inputNumber)) &&
                            inputNumber.length ===
                              country.format.split(".").length - 1
                          );
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="">{t("email")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-enevelope1.svg" alt="" />
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="email"
                        placeholder={t("email")}
                        disabled
                        name="email"
                        value={formData.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("country.plural_one")}</label>
                    <div
                      className="form-group input-group"
                      style={{ zIndex: 0 }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/location.svg" alt="" />
                        </span>
                      </div>
                      <select
                        onChange={handleChange}
                        value={formData.country}
                        name="country"
                        className="form-control"
                      >
                        <option value="" hidden>
                          {t("country.plural_one")}**
                        </option>
                        {countryCodes.map((data) => (
                          <option value={data.code} key={data.code}>
                            {t(`country.name.${data.code}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("locale")}</label>
                    <div
                      className="form-group input-group"
                      style={{ zIndex: 0 }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/globe1.png" alt="" />
                        </span>
                      </div>
                      <select
                        onChange={handleChange}
                        value={formData.locale}
                        name="locale"
                        className="form-control"
                      >
                        <option value="en_US">English</option>
                        <option value="ru_RU">Russian</option>
                        <option value="tr_TR">Turkish</option>
                        <option value="zh-Hans_CN">Chinese</option>
                      </select>
                    </div>
                  </div>
                  <div className="col col-12 col-md-12">
                    <label>{t("publicKey.plural_other")}</label>
                    <Autocomplete
                      multiple
                      style={{ width: "100%" }}
                      onChange={(event, value) => {
                        changeFormData((prevState) => ({
                          ...prevState,
                          public_keys: value.map((option) => option.value),
                        }));
                      }}
                      value={
                        userPublicKeysOptions.filter((option) =>
                          formData.public_keys?.includes(option.value)
                        ) ?? []
                      }
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      getOptionLabel={(option) => t(option.label)}
                      options={userPublicKeysOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            className: classes.removeShadow,
                          }}
                          label={t("currency.plural_one")}
                          InputLabelProps={{ shrink: false }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="submit-btn-group ">
                  <div className="row justify-content-center">
                    <div className="col-md-9">
                      <button
                        type="submit"
                        className="btn btn-secondary btn-hover-outline w-100"
                      >
                        {t("confirm")}{" "}
                        <i className="fa fa-arrow-right" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/*process Section End*/}
      <Dialog
        maxWidth="lg"
        open={openProfile}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("upload.image")}</DialogTitle>
        <DialogContent>
          <ReactCrop
            src={upImg ?? missingProfilePicture}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button onClick={handleOk}>{t("ok")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const Required = ({ children }) => {
  const { t } = useTranslation();

  return (
    <small className="form-text text-danger text-right">
      {children ?? `${t("required")}*`}
    </small>
  );
};
