import React from "react";
import {
  PERMISSION_ACCESS_READ,
  PERMISSION_ACCESS_WRITE,
} from "../../../permissions/permissionAccessLevels";
import {
  PERMISSION_LICENSE,
  PERMISSION_USER_PROFILE,
} from "../../../permissions/permissions";
import { permissionRequired } from "../../authorisations";

const ROUTES_USER = [
  {
    id: "account",

    path: "/account",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_USER_PROFILE,
      PERMISSION_ACCESS_READ
    ),

    Component: React.lazy(() =>
      import("../../../components/account/YourAccount")
    ),

    children: [
      {
        id: "edit",

        path: "/account/edit",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_USER_PROFILE,
          PERMISSION_ACCESS_WRITE
        ),

        Component: React.lazy(() =>
          import("../../../components/account/EditAccount")
        ),
      },
      {
        id: "reviewLicense",

        path: "/account/review-license",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_LICENSE,
          PERMISSION_ACCESS_READ
        ),

        Component: React.lazy(() =>
          import("../../../components/ReviewLicence/ReviewLicence")
        ),
      },
    ],
  },
];

export default ROUTES_USER;
