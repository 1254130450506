import {
  CLOSE_SNACKBAR,
  SHOW_CONFIRMBOX,
  TOGGLE_SUBMIT_GAME_POPOVER,
} from "./types";

export const showConfirmbox = (payload = {}) => {
  return {
    type: SHOW_CONFIRMBOX,
    payload,
  };
};

export const hideConfirmbox = () => {
  return {
    type: CLOSE_SNACKBAR,
  };
};

export const toggleSubmitGamePopover = (payload) => {
  return {
    type: TOGGLE_SUBMIT_GAME_POPOVER,
    payload,
  };
};
