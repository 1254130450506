import { axiosConfig } from "../../axiosConfig";
import { processEndpoint, returnMessage } from "../service.helper";
import {
  HYBRID_SUBMISSION,
  HYBRID_SUBMISSION_FILES,
  LOCALISATION,
  PUBLIC_USER,
  ROLES,
  USER_FEEDBACK,
} from "./public.endpoints";

export async function getUser(id) {
  return await processEndpoint(
    axiosConfig.get(PUBLIC_USER.replace(/{id}/gi, id)),
    {
      onErrorStatusCode: {
        404: () => undefined,
      },
    }
  );
}

export async function submitFeedback(
  feedback,
  { email, project_id, project_test_id } = {},
  url = window.location.href
) {
  return await processEndpoint(
    axiosConfig.post(USER_FEEDBACK, {
      feedback,
      project_id,
      project_test_id,
      email,
      page_url: url,
    }),
    {
      returnData: returnMessage,
    }
  );
}

export async function getRoles() {
  return await processEndpoint(axiosConfig.get(ROLES), {
    onErrorStatusCode: {
      404: () => [],
    },
  });
}

export async function getLocalisation(locale) {
  return await processEndpoint(
    axiosConfig.get(LOCALISATION, {
      params: {
        locale,
      },
      headers: {
        timestamp: Date.now().toString(),
      },
    }),
    {
      returnData: (res) => res.data.data.localisation,
      onErrorStatusCode: {
        404: () => ({}),
      },
    }
  );
}

export async function submitHybrid(data) {
  return await processEndpoint(axiosConfig.post(HYBRID_SUBMISSION, data));
}

export async function submitHybridFiles(data) {
  return await processEndpoint(
    axiosConfig.post(HYBRID_SUBMISSION_FILES, data),
    {
      returnData: returnMessage,
    }
  );
}
