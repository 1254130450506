import React from "react";
import {
  PERMISSION_ACCESS_READ,
  PERMISSION_ACCESS_WRITE,
} from "../../../permissions/permissionAccessLevels";
import {
  PERMISSION_LICENSE,
  PERMISSION_ORGANISATION,
  PERMISSION_WALLET_RESOURCE,
} from "../../../permissions/permissions";
import { loginRequired, permissionRequired } from "../../authorisations";

export const ROUTE_WALLET = {
  id: "wallet",

  path: "/wallet",
  exact: true,
  isAuthorised: permissionRequired(
    PERMISSION_WALLET_RESOURCE,
    PERMISSION_ACCESS_READ
  ),

  Component: React.lazy(() =>
    import("../../../components/coin-balance/Wallet")
  ),
};

const ROUTES_ORGANISATION = [
  {
    id: "organisation",

    path: "/organisation",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ORGANISATION,
      PERMISSION_ACCESS_READ
    ),

    Component: React.lazy(() =>
      import("../../../components/my-organisation/MyOrganisation")
    ),

    children: [
      {
        id: "edit",

        path: "/organisation/edit",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_ORGANISATION,
          PERMISSION_ACCESS_WRITE
        ),

        Component: React.lazy(() =>
          import("../../../components/my-organisation/EditUserOrganisation")
        ),
      },
      {
        id: "reviewLicense",

        path: "/organisation/review-license",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_LICENSE,
          PERMISSION_ACCESS_READ
        ),

        Component: React.lazy(() =>
          import("../../../components/ReviewLicence/ReviewOrganisationLicence")
        ),
      },
      {
        id: "info",

        path: "/organisation/info",
        exact: true,
        isAuthorised: loginRequired,

        hasHeading: false,

        Component: React.lazy(() =>
          import("../../../components/companyInfo/CompanyInfo")
        ),
      },
    ],
  },
  ROUTE_WALLET,
];

export default ROUTES_ORGANISATION;
