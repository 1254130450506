import React from "react";
import { Redirect } from "react-router-dom";
import { PERMISSION_ACCESS_READ } from "../../../permissions/permissionAccessLevels";
import {
  PERMISSION_PROJECT_COLLECTION,
  PERMISSION_PROJECT_RESOURCE,
  PERMISSION_PROJECT_TEST,
} from "../../../permissions/permissions";
import { permissionRequired } from "../../authorisations";

const ROUTES_PROJECT = [
  {
    id: "myGames",

    path: "/my-games",
    exact: true,

    Component: () => <Redirect from="/my-games" to="/games" />,
  },
  {
    id: "games",

    path: "/games",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_PROJECT_COLLECTION,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      title: "My Games",
      default: true,
      order: 1,
    },

    Component: React.lazy(() => import("../../../components/game/MyGames")),

    children: [
      {
        id: "game",

        path: "/games/:projectId",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_PROJECT_RESOURCE,
          PERMISSION_ACCESS_READ
        ),

        Component: React.lazy(() =>
          import("../../../components/game/iteration/GameIterationDashboard")
        ),

        children: [
          {
            id: "test",

            path: "/games/:projectId/:testId",
            exact: true,
            isAuthorised: permissionRequired(
              PERMISSION_PROJECT_TEST,
              PERMISSION_ACCESS_READ
            ),

            Component: React.lazy(() =>
              import("../../../components/game/test/TestDashboard")
            ),
          },
        ],
      },
    ],
  },
];

export default ROUTES_PROJECT;
