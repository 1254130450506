import React from "react";
import { Route } from "react-router-dom";
import Header from "../components/common/header/header";

export default function ComponentRoute({
  hasHeading = true,
  headerTitle,
  ...rest
}) {
  return (
    <div id="wrapper">
      {hasHeading && <Header headerTitle={headerTitle} />}
      <Route {...rest} />
    </div>
  );
}
