import history from "../util/createBrowserHistory";
import moment from "moment/moment";
import { getToken } from "../data/interceptor/authInterceptor";

function fetchKeepAlive(url, data) {
  data = JSON.stringify(data);

  // noinspection JSIgnoredPromiseFromCall
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
    keepalive: true,
  });
}

function sendBeacon(url, data) {
  if (!("navigator" in window) || !("sendBeacon" in window.navigator)) {
    return;
  }

  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  window.navigator.sendBeacon(url, formData);
}

function sendEvent(obj) {
  const url = `${process.env.REACT_APP_API_URL}/track_event`;

  let userAgent = "browser";
  if ("navigator" in window && "userAgent" in navigator) {
    userAgent = navigator.userAgent;
  }

  if (userAgent.toLowerCase().indexOf("firefox") > -1) {
    // Firefox does not support keepalive yet
    sendBeacon(url, obj);
  } else {
    // Every other browser supports keepalive
    fetchKeepAlive(url, obj);
  }
}

function trackEvent(obj) {
  if (!obj.auth_token) {
    return;
  }

  sendEvent(obj);
}

function createPageNavigateEvent() {
  return {
    auth_token: getToken(),
    event_info_type: "page_navigate",
    event_info_created: `${moment.utc().valueOf()}`,
    generic_string_0: history.location.pathname,
    generic_string_1: history.location.search,
  };
}

window.addEventListener("load", () => {
  trackEvent(createPageNavigateEvent());
});

history.listen(() => {
  trackEvent(createPageNavigateEvent());
});

window.addEventListener("beforeunload", () => {
  trackEvent(createPageNavigateEvent());
});
