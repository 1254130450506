import { axiosConfig } from "../data/axiosConfig";
import { MAINTENANCE_STATE, RELEVANT_FEEDBACK_INFO } from "./types";

export const validateMaintenance = () => async (dispatch) => {
  let isMaintenance = false;
  try {
    // Throwaway result
    await axiosConfig.get("/");
  } catch (err) {
    isMaintenance = err.response?.status === 503;
  }

  dispatch({
    type: MAINTENANCE_STATE,
    payload: isMaintenance,
  });
};

export const setRelevantFeedbackInfo =
  ({ project_id, project_test_id }) =>
  (dispatch) => {
    dispatch({
      type: RELEVANT_FEEDBACK_INFO,
      payload: { project_id, project_test_id },
    });
  };
