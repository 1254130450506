import React from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import ComponentRoute from "./ComponentRoute";
import PrivateRoute from "./PrivateRoute";

export default function RouteRenderer({ routes, children }) {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(
        ({ path, exact, id, hasHeading, isAuthorised, Component }, index) => {
          let RouteComponent = ComponentRoute;
          if (isAuthorised !== undefined && isAuthorised !== null) {
            RouteComponent = PrivateRoute;
          }

          return (
            <RouteComponent
              key={path ?? index}
              exact={exact}
              path={path}
              isAuthorised={isAuthorised}
              component={Component}
              headerTitle={t(`header.navigation.route.${id}.title`)}
              hasHeading={hasHeading}
            />
          );
        }
      )}

      {children}
    </Switch>
  );
}
