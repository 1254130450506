export const PROJECTS = "/projects";
export const HYBRID_PROJECTS = "/hybrid-projects";
export const PROJECT = `${PROJECTS}/{id}`;

export const PROJECT_SHEET = `${PROJECT}/sheet`;

export const PROJECT_TESTS = `${PROJECT}/tests`;
export const PROJECT_TEST = `${PROJECT_TESTS}/{testId}`;
export const PROJECT_TEST_WINDOW = `${PROJECT_TEST}/windows`;
export const PROJECT_TEST_VALIDATE_SDK = `${PROJECT_TEST}/validate_sdk`;

export const PROJECT_PERMITTED_TEST_LOGS = `${PROJECT}/permitted-test-logs`;

export const TASKS = `${PROJECT_TEST}/submission`;

export const ARES_ERROR_MAPPING = `/platform-to-ares-error-mapping/{id}`;
export const PROJECT_TEST_ERROR_LOG = `/project-test-error-log`;
