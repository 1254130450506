import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import {
  agreeLicence,
  editAccount,
  getLicence,
  login,
  lostPasswordEmailValidate,
  validateEmail,
} from "./accounts";
import { confirmboxPopup as confirmbox, submitGamePopover } from "./confirmbox";
import {
  deleteGame,
  facebookValidate,
  gameFirstStage,
  gameStage,
  getAdminAttributions,
  getAdminProjectSheet,
  getAdminProjectsTest,
  getAdminProjectTestId,
  getAdminTriggerApproval,
  getAdminUsers,
  getDetailProject,
  getErrorMappings,
  getListProject,
  getOrganisation,
  getProjectHead,
  getProjectResource,
  getProjectSheet,
  getProjectSheetForDetails,
  getTask,
  getTaskTemplate,
  getWalletDetails,
  projectInformation,
  projectList,
  projectSheetGet,
  projectSheetUpdate,
  projectTestGet,
  stepFourReducer,
  stepLastReducer,
  stepOneReducer,
  stepThreeReducer,
  stepTwoReducer,
  submitProjectTest,
  submitSdkButton,
  submitStoreButton,
  submitVideoButton,
  taskUpdate,
  updateAdminTriggerApproval,
  updateOrganisation,
  validateAppStore,
} from "./game";
import { maintenanceState, relevantFeedbackInfo } from "./generic";
import { dispatchNotificaion } from "./notifier";
import { storeStep } from "./storeStep";
import userData from "./userData";
import { videoUpload, videoValidation } from "./videoValidation";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    userData,
    editAccount,
    validateEmail,
    lostPasswordEmailValidate,
    gameStage,
    gameFirstStage,
    storeStep,
    videoValidation,
    videoUpload,
    projectList,
    projectInformation,
    stepOneReducer,
    stepTwoReducer,
    stepThreeReducer,
    stepFourReducer,
    stepLastReducer,
    // new flow
    getListProject,
    getDetailProject,
    getTask,
    getProjectSheet,
    getTaskTemplate,
    facebookValidate,
    taskUpdate,
    projectSheetUpdate,
    projectSheetGet,
    validateAppStore,
    getProjectSheetForDetails,
    projectTestGet,
    deleteGame,
    submitSdkButton,
    submitStoreButton,
    submitVideoButton,
    submitProjectTest,
    getProjectResource,
    getAdminTriggerApproval,
    updateAdminTriggerApproval,
    getAdminUsers,
    getOrganisation,
    updateOrganisation,
    getAdminProjectSheet,
    getWalletDetails,
    getProjectHead,
    getAdminProjectsTest,
    getAdminProjectTestId,
    login,
    getLicence,
    agreeLicence,
    maintenanceState,
    relevantFeedbackInfo,
    dispatchNotificaion,
    confirmbox,
    getAdminAttributions,
    getErrorMappings,
    submitGamePopover,
  });
