import { TextField } from "@material-ui/core";
import { EmailOutlined } from "@material-ui/icons";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import endPoints from "../../_helpers/endPoints.json";
import { userActions } from "../../actions/auth";
import EventLogo from "../common/EventLogo";
import Logo from "../Logo";

const LostPassword = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [email, handleUserName] = useState("");

  const [showModal1, setShowModal1] = useState(false);

  const status = useSelector((state) => state.lostPasswordEmailValidate.status);

  const submit = () => {
    if (!validateEmail(email)) return;

    dispatch(userActions.lostPassword(endPoints.forgotPassword, { email }));
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const closeModal = () => {
    dispatch(userActions.recoverAccount(endPoints.RecoverAccount, { email }));
    setShowModal1(false);
  };

  const closeModalWithBtn = () => {
    setShowModal1(false);
    window.location.reload();
  };

  useEffect(() => {
    if (status === 403) {
      setShowModal1(true);
    }
  }, [status]);

  return (
    <div className="full-page">
      {/*Main wrapper start*/}
      <div className="full-page-section">
        <div className="full-page-form">
          <div className="full-page-form-container">
            <div className="full-page-head">
              <Logo />
              <EventLogo />
              <h1>{t("page.lostPassword.title")}</h1>
            </div>
            <form
              id="loginForm"
              onSubmit={(event) => {
                event.preventDefault();

                submit();

                return false;
              }}
            >
              <div
                className={cx({
                  invalid: !validateEmail(email),
                })}
              >
                <TextField
                  fullWidth
                  type="email"
                  placeholder={t("email")}
                  autoComplete="email"
                  onChange={(e) => handleUserName(e.target.value)}
                  value={email}
                  InputProps={{
                    startAdornment: (
                      <EmailOutlined className="mui-textfield-icon" />
                    ),
                  }}
                  inputProps={{
                    className: "mui-icon-textfield",
                  }}
                />
                <div className="custom-helper-text">
                  {t("page.lostPassword.helperText")}
                </div>
              </div>
              <div className="submit-btn-group ">
                <button
                  type="button"
                  disabled={!validateEmail(email)}
                  className="btn btn-secondary btn-hover-outline w-100"
                  onClick={submit}
                >
                  {t("submit")}{" "}
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showModal1 && (
        <React.Fragment>
          <div
            className={`modal modal-custom fade show`}
            id="recoverpopup"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
            aria-modal="true"
            aria-labelledby="recoverpopup"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>{t("page.lostPassword.modal.title")}</h2>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label={t("close")}
                    onClick={closeModalWithBtn}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <p>{t("page.lostPassword.modal.content")}</p>
                  <div className="submit-btn-group ">
                    <button
                      onClick={closeModal}
                      className="btn btn-secondary btn-hover-outline w-100"
                    >
                      {t("page.lostPassword.modal.submit")}{" "}
                      <i className="fa fa-arrow-right" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </React.Fragment>
      )}
    </div>
  );
};
export default LostPassword;
