import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Trans from "../common/Trans";
import GDPRCategories from "./GDPRCategories";

export default function GDPRBanner() {
  const { t } = useTranslation();

  const [isCustomising, setCustomising] = useState(false);

  const [cookies, setCookie] = useCookies(["consent"]);
  if ((cookies.consent?.length ?? 0) > 0) {
    return null;
  }

  const onCustomise = (value = !isCustomising) => {
    setCustomising(value);
  };

  const onAgree = () => {
    setCookie("consent", Object.keys(GDPRCategories), {
      path: "/",
      maxAge: 30 * 24 * 60 * 60,
    });
  };

  return (
    <div className="gdpr-banner">
      <h1>{t("gdpr.banner.title")}</h1>
      <Trans i18nKey="gdpr.banner.description" />

      <div className="actions">
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          onClick={() => onCustomise(true)}
        >
          {t("gdpr.banner.learnMore")}
        </button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-right"
          onClick={onAgree}
        >
          {t("agree")}
        </button>
      </div>

      <GDPRModal isOpen={isCustomising} onClose={() => onCustomise(false)} />
    </div>
  );
}

function GDPRModal({ isOpen, onClose }) {
  const { t } = useTranslation();

  const [openTab, setOpenTab] = useState(0);

  const [cookies, setCookie] = useCookies(["consent"]);
  const [allowedCookies, setAllowedCookies] = useState(
    cookies.consent ? cookies.consent : ["necessary"]
  );

  const handleChange = (event, newValue) => {
    setOpenTab(newValue);
  };

  const toggleCookie = (category, allowed) => {
    const categories = [...allowedCookies];
    const index = categories.findIndex((c) => c === category);
    if (allowed) {
      if (index >= 0) {
        return;
      }

      categories.push(category);
    } else {
      if (index < 0) {
        return;
      }

      categories.splice(index, 1);
    }

    setAllowedCookies(categories);
  };

  const onSave = () => {
    setCookie("consent", allowedCookies, {
      path: "/",
      maxAge: 30 * 24 * 60 * 60,
    });

    onClose();
  };

  return (
    <Dialog onClose={onClose} open={isOpen} className="gdpr-modal">
      <DialogTitle onClose={onClose}>{t("gdpr.modal.title")}</DialogTitle>
      <DialogContent dividers className="gdpr-modal-dialog">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={openTab}
          onChange={handleChange}
          className="tab-container"
        >
          <Tab
            label={t("gdpr.modal.yourPrivacy")}
            className="tab"
            disableRipple
          />
          {Object.keys(GDPRCategories).map((id) => (
            <Tab
              key={id}
              label={t(`gdpr.modal.category.${id}.name`)}
              className="tab"
              disableRipple
            />
          ))}
        </Tabs>
        {openTab === 0 && (
          <div role="tabpanel" className="tab-pane">
            <p className="small">{t("gdpr.modal.cookieDescription")}</p>
            <p className="small">{t("gdpr.modal.privacyDescription")}</p>
          </div>
        )}
        {Object.entries(GDPRCategories).map(([id, options], index) => {
          if (index + 1 !== openTab) {
            return null;
          }

          return (
            <div key={id} role="tabpanel" className="tab-pane">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={!options.canToggle}
                  checked={allowedCookies.includes(id)}
                  onChange={(event) => toggleCookie(id, event.target.checked)}
                />
                <label className="form-check-label">
                  {t("gdpr.modal.optIn")}
                </label>
              </div>

              <p className="small">
                {t(`gdpr.modal.category.${id}.description`)}
              </p>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <button className="btn btn-primary btn-sm" onClick={onSave}>
          {t("save")}
        </button>
      </DialogActions>
    </Dialog>
  );
}
