import React from "react";
import { PERMISSION_ACCESS_READ } from "../../../permissions/permissionAccessLevels";
import {
  PERMISSION_ADMIN_PROJECT_RESOURCE,
  PERMISSION_ADMIN_PROJECT_TEMPLATE,
  PERMISSION_ADMIN_PROJECT_TEST,
  PERMISSION_ADMIN_PROJECT_TEST_COST,
  PERMISSION_ADMIN_PROJECT_TEST_RESULT_CONFIG,
  PERMISSION_ADMIN_PROJECT_TEST_SUBMISSION_TEMPLATE,
  PERMISSION_PROJECT_TEST,
} from "../../../permissions/permissions";
import { permissionRequired } from "../../authorisations";
import { HomeRedirect } from "../../PrivateRoute";

const ROUTES_ADMIN_PROJECT = [
  {
    id: "admin.project.test.resultConfig",

    path: "/admin/project/test-result-config",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_PROJECT_TEST_RESULT_CONFIG,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 8,
      group: "Project",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/ProjectTestResultConfigAdmin")
    ),
  },
  {
    id: "admin.project.test.cost",

    path: "/admin/project/test-cost",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_PROJECT_TEST_COST,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 9,
      group: "Project",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/ProjectTestCostAdmin")
    ),
  },
  {
    id: "admin.projectTests",
    path: "/admin/project-tests",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_PROJECT_TEST,
      PERMISSION_ACCESS_READ
    ),
    navigation: {
      internal: true,
      order: 8,
      group: "Project",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/ProjectTests")
    ),
  },
  {
    id: "admin.project.test.submissionTemplate",

    path: "/admin/project/test-submission-template",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_PROJECT_TEST_SUBMISSION_TEMPLATE,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 10,
      group: "Project",
    },

    Component: React.lazy(() =>
      import(
        "../../../components/admin/project/TestSubmissionTemplate/ProjectTestSubmissionTemplateAdmin"
      )
    ),
  },
  {
    id: "admin.project.template",

    path: "/admin/project/template",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_PROJECT_TEMPLATE,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 11,
      group: "Project",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/ProjectTemplateAdmin")
    ),
  },
  {
    id: "admin.project",

    path: "/admin/project",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_PROJECT_RESOURCE,
      PERMISSION_ACCESS_READ
    ),

    hasHeading: false,

    Component: () => <HomeRedirect from="/admin/project" />,

    children: [
      {
        id: "inspect",

        path: "/admin/project/:projectId",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_ADMIN_PROJECT_RESOURCE,
          PERMISSION_ACCESS_READ
        ),

        Component: React.lazy(() =>
          import("../../../components/admin/project/ProjectAdmin")
        ),

        children: [
          {
            id: "test",

            path: "/admin/project/:projectId/test",
            exact: true,
            isAuthorised: permissionRequired(
              PERMISSION_ADMIN_PROJECT_TEST,
              PERMISSION_ACCESS_READ
            ),

            hasHeading: false,

            Component: () => (
              <HomeRedirect from="/admin/project/:projectId/test" />
            ),

            children: [
              {
                id: "inspect",

                path: "/admin/project/:projectId/test/:testId",
                exact: true,
                isAuthorised: permissionRequired(
                  PERMISSION_ADMIN_PROJECT_TEST,
                  PERMISSION_ACCESS_READ
                ),

                Component: React.lazy(() =>
                  import("../../../components/admin/project/test/TestAdmin")
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default ROUTES_ADMIN_PROJECT;
