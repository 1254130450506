import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR } from "./types";

export const enqueueSnackbar = (message, variant, options = {}) => {
  // key - unique identifier for indivisual snac
  const key = new Date().getTime();
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...options,
      message,
      variant,
      key,
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  key,
});
