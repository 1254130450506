import { axiosConfig } from "./data/axiosConfig";
import { getToken } from "./data/interceptor/authInterceptor";

export const userService = {
  postWithoutToken,
  login,
  register,
  logout,
  commonGet,
  changePassword,
  createPassword,
  addCompanyInfo,
  lostPassword,
  recoverAccount,
};

function recoverAccount(apiName, data) {
  return axiosConfig.post(apiName, data).then(handleResponse);
}

function login(apiName, data) {
  return axiosConfig.post(apiName, data).then(handleResponse);
}

function register(apiName, data) {
  return axiosConfig.post(apiName, data).then(handleResponse);
}

function logout(apiName) {
  return axiosConfig
    .post(
      apiName,
      { auth_token: getToken() },
      {
        headers: {
          "Permission-Code": "logout_api",
        },
      }
    )
    .then(handleResponse);
}

function createPassword(apiName, data) {
  return axiosConfig.post(apiName, data).then(handleResponse);
}

function addCompanyInfo(apiName, data) {
  return axiosConfig
    .post(apiName, data, {
      headers: {
        "Permission-Code": "user_profile_api",
      },
    })
    .then(handleResponse);
}

function changePassword(apiName, userData) {
  return axiosConfig.put(apiName, userData).then(handleResponse);
}

/**************GET***************/
function commonGet(apiName) {
  return axiosConfig.get(apiName).then(handleResponse);
}

/**************Post**************/
function lostPassword(apiName, data) {
  return axiosConfig.post(apiName, data).then(handleResponse);
}

function postWithoutToken(apiName, data = {}) {
  return axiosConfig.post(apiName, data).then(handleResponse);
}

/**************Helpers**************/
function handleResponse(response) {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202
  ) {
    return response;
  } else {
    const error = response;
    return Promise.reject(error);
  }
}
