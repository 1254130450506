import axios from "axios";
import { API_URL } from "../_helpers/config";

export const axiosConfig = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export function isValidURL(config) {
  return (
    config.url &&
    (config.url.startsWith(API_URL) ||
      (config.baseURL === API_URL && config.url.startsWith("/")))
  );
}
