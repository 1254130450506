import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import endPoints from "../../_helpers/endPoints.json";
import { getUserData } from "../../actions/accounts.js";
import missingProfilePicture from "../../media/profile-pic.png";
import { PERMISSION_ACCESS_WRITE } from "../../permissions/permissionAccessLevels";
import usePermissions from "../../permissions/PermissionLoader";
import { PERMISSION_USER_PROFILE } from "../../permissions/permissions";
import PermissionTooltip from "../../permissions/PermissionTooltip";
import countryCodes from "../companyInfo/countryCodes.json";
import { Skeleton } from "@material-ui/lab";

export const userPublicKeysOptions = [
  { label: "name.first", value: "first_name" },
  { label: "name.last", value: "last_name" },
  { label: "account.image", value: "profile_image" },
  { label: "company", value: "organisation_name" },
  { label: "position", value: "position" },
  { label: "skypeId", value: "skype_id" },
  { label: "phoneNumber", value: "phone_number" },
  { label: "email", value: "email" },
  { label: "country.plural_one", value: "country" },
  { label: "database.object_id", value: "object_id" },
  { label: "database.object_type", value: "object_type" },
  { label: "organisation.id", value: "organisation_id" },
];

export default function YourAccount() {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const dispatch = useDispatch();

  const canEdit = hasPermission(
    PERMISSION_USER_PROFILE,
    PERMISSION_ACCESS_WRITE
  );

  useEffect(() => {
    dispatch(getUserData(endPoints.GetAccount));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userData } = useSelector((state) => ({
    userData: state.userData,
  }));

  return (
    <>
      <section className="full-page-headed mb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 ">
              <form>
                <div className="row">
                  <div className="col-sm-12">
                    {!userData?.data ? (
                      <Skeleton width={130} height={180} />
                    ) : (
                      <div className="uploadphoto">
                        <div
                          id="imagePreview-pro"
                          className="uploadphoto__img avatar-preview"
                          style={{
                            backgroundImage: `url(${
                              userData.data?.profile_image ??
                              missingProfilePicture
                            })`,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("name.first")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-user.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        placeholder={t("name.first")}
                        value={userData.data?.first_name ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("name.last")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-user.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        placeholder={t("name.last")}
                        value={userData.data?.last_name ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("company")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-building.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        placeholder={t("company")}
                        value={userData.data?.organisation_name ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("position")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/position.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        placeholder={t("position")}
                        value={userData.data?.position ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("skypeId")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-skype.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        placeholder={t("skypeId")}
                        value={userData.data?.skype_id ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("phoneNumber")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-smartphone.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="tel"
                        placeholder={t("phoneNumber")}
                        value={userData.data?.phone_number ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("email")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/icon-enevelope1.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="email"
                        placeholder={t("email")}
                        value={userData.data?.email ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("country.plural_one")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/location.svg" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        placeholder={t("country.plural_one")}
                        value={countryCodes
                          .filter(
                            (item) => item.code === userData.data?.country
                          )
                          .map((c) => c.country)
                          .toString()}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{t("locale")}</label>
                    <div className="form-group input-group disabled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="img/globe1.png" alt="" />
                        </span>
                      </div>
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        placeholder={t("country.plural_one")}
                        value={
                          {
                            en_US: "English",
                            ru_RU: "Russian",
                            tr_TR: "Turkish",
                            "zh-Hans_CN": "Chinese",
                          }[userData.data?.locale ?? ""]
                        }
                      />
                    </div>
                  </div>
                  <div className="col col-12 col-md-12">
                    <label>{t("publicKey.plural_other")}</label>
                    <Autocomplete
                      multiple
                      disabled
                      style={{ width: "100%" }}
                      value={
                        userPublicKeysOptions.filter((option) =>
                          userData?.data?.public_keys?.includes(option.value)
                        ) ?? []
                      }
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      getOptionLabel={(option) => t(option.label)}
                      options={userPublicKeysOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("currency.plural_one")}
                          InputLabelProps={{ shrink: false }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="submit-btn-group ">
                  <div className="row justify-content-center">
                    <div className="col-md-9 text-center">
                      <PermissionTooltip hasPermission={canEdit}>
                        <Link
                          type="div"
                          className={classNames(
                            "btn btn-secondary btn-hover-outline w-100 mb-2",
                            { disabled: !canEdit }
                          )}
                          style={{ pointerEvents: "auto" }}
                          to="/account/edit"
                          onClick={(event) => {
                            if (!canEdit) {
                              event.preventDefault();
                            }
                          }}
                        >
                          {t("account.edit")}{" "}
                          <i className="fa fa-arrow-right" aria-hidden="true" />
                        </Link>
                      </PermissionTooltip>

                      <Link className="text-dark" to="/account/review-license">
                        {t("termsAndConditions.viewAccepted")}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
