import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function HashLinkScroll({ children }) {
  const history = useHistory();

  const scrollToHash = (hash) => {
    if (hash.length < 1) {
      return;
    }

    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 200);
  };

  useEffect(() => {
    scrollToHash(history.location.hash);

    const unregister = history.listen(({ hash }) => {
      scrollToHash(hash);
    });

    return () => {
      unregister();
    };
  }, [history]);

  return children;
}
