import React from "react";
import { PERMISSION_ACCESS_READ } from "../../../permissions/permissionAccessLevels";
import {
  PERMISSION_ADMIN_USER,
  PERMISSION_ADMIN_USER_NOTIFICATION_PREFERENCE,
} from "../../../permissions/permissions";
import { permissionRequired } from "../../authorisations";

const ROUTES_ADMIN_USER = [
  {
    id: "admin.user.preferences",

    path: "/admin/user/preferences",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_USER_NOTIFICATION_PREFERENCE,
      PERMISSION_ACCESS_READ
    ),

    Component: React.lazy(() =>
      import("../../../components/admin/user/UserPreferenceAdmin")
    ),
  },
  {
    id: "admin.user",

    path: "/admin/user",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_USER,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 5,
      group: "Users",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/user/UsersAdmin")
    ),

    children: [
      {
        id: "inspect",

        path: "/admin/user/:userId",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_ADMIN_USER,
          PERMISSION_ACCESS_READ
        ),

        Component: React.lazy(() =>
          import("../../../components/admin/user/UserAdmin")
        ),
      },
    ],
  },
];

export default ROUTES_ADMIN_USER;
