import React, { useState } from "react";

const BETA_HIDDEN_KEY = "betaTagHidden";
const getBetaHiddenFromStore = () =>
  localStorage.getItem(BETA_HIDDEN_KEY) === "true";

export const BetaHiddenContext = React.createContext({
  isBetaHidden: getBetaHiddenFromStore(),
  setBetaHidden: () => {},
});

export default function BetaHiddenProvider({ children }) {
  const [isBetaHidden, setBetaHiddenState] = useState(getBetaHiddenFromStore());

  const setBetaHidden = (isHidden) => {
    setBetaHiddenState(isHidden);
    localStorage.setItem(BETA_HIDDEN_KEY, isHidden);
  };

  return (
    <BetaHiddenContext.Provider value={{ isBetaHidden, setBetaHidden }}>
      {children}
    </BetaHiddenContext.Provider>
  );
}
