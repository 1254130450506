import {
  ADD_FIRST_STEP_FAILURE,
  ADD_FIRST_STEP_SUCCESS,
  FACEBOOK_VALIDATE_FAILURE,
  FACEBOOK_VALIDATE_REQUEST,
  FACEBOOK_VALIDATE_SUCCESS,
  GAME_STAGE_SUCCESS,
  GET_ADMIN_ATTRIBUTIONS_FAILURE,
  GET_ADMIN_ATTRIBUTIONS_REQUEST,
  GET_ADMIN_ATTRIBUTIONS_SUCCESS,
  GET_ADMIN_PROJECT_SHEET_FAILURE,
  GET_ADMIN_PROJECT_SHEET_SUCCESS,
  GET_ADMIN_PROJECTS_TESTS_FAILURE,
  GET_ADMIN_PROJECTS_TESTS_ID_FAILURE,
  GET_ADMIN_PROJECTS_TESTS_ID_SUCCESS,
  GET_ADMIN_PROJECTS_TESTS_SUCCESS,
  GET_ADMIN_TRIGGER_APPROVAL_FAILURE,
  GET_ADMIN_TRIGGER_APPROVAL_REQUEST,
  GET_ADMIN_TRIGGER_APPROVAL_SUCCESS,
  GET_ADMIN_USERS_FAILURE,
  GET_ADMIN_USERS_SUCCESS,
  GET_DETAILS_PROJECT_FAILURE,
  GET_DETAILS_PROJECT_SUCCESS,
  GET_ERROR_MAPPING_FAILURE,
  GET_ERROR_MAPPING_SUCCESS,
  GET_LIST_PROJECT_FAILURE,
  GET_LIST_PROJECT_REQUEST,
  GET_LIST_PROJECT_SUCCESS,
  GET_ORGANISATION_FAILURE,
  GET_ORGANISATION_REQUEST,
  GET_ORGANISATION_SUCCESS,
  GET_PROJECT_HEAD_FAILURE,
  GET_PROJECT_HEAD_SUCCESS,
  GET_PROJECT_INFORMATION_SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_RESOURCE_FAILURE,
  GET_PROJECT_RESOURCE_SUCCESS,
  GET_PROJECT_SHEET_FAILURE,
  GET_PROJECT_SHEET_FOR_DETAILS_FAILURE,
  GET_PROJECT_SHEET_FOR_DETAILS_SUCCESS,
  GET_PROJECT_SHEET_SUCCESS,
  GET_TASK_FAILURE,
  GET_TASK_PURGE,
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_TEMPLATE_FAILURE,
  GET_TASK_TEMPLATE_SUCCESS,
  GET_WALLET_DETAILS_FAILURE,
  GET_WALLET_DETAILS_SUCCESS,
  PROJECT_DELETE_FAILURE,
  PROJECT_DELETE_SUCCESS,
  PROJECT_SHEET_FAILURE,
  PROJECT_SHEET_GET_SUCCESS,
  PROJECT_SHEET_PURGE,
  PROJECT_SHEET_UPDATE_FAILURE,
  PROJECT_SHEET_UPDATE_SUCCESS,
  PROJECT_TEST_GET_FAILURE,
  PROJECT_TEST_GET_SUCCESS,
  PROJECT_TEST_PURGE,
  PROJECT_TESTS_GET_FAILURE,
  PROJECT_TESTS_GET_SUCCESS,
  SUBMIT_PROJECT_TEST_FAILURE,
  SUBMIT_PROJECT_TEST_SUCCESS,
  SUBMIT_SDK_BUTTON_FAILURE,
  SUBMIT_SDK_BUTTON_PURGE,
  SUBMIT_SDK_BUTTON_SUCCESS,
  SUBMIT_STEP_FOUR_FAILURE,
  SUBMIT_STEP_FOUR_SUCCESS,
  SUBMIT_STEP_LAST_FAILURE,
  SUBMIT_STEP_LAST_SUCCESS,
  SUBMIT_STEP_ONE_FAILURE,
  SUBMIT_STEP_ONE_PURGE,
  SUBMIT_STEP_ONE_SUCCESS,
  SUBMIT_STEP_THREE_FAILURE,
  SUBMIT_STEP_THREE_SUCCESS,
  SUBMIT_STEP_TWO_FAILURE,
  SUBMIT_STEP_TWO_SUCCESS,
  SUBMIT_STORE_BUTTON_FAILURE,
  SUBMIT_STORE_BUTTON_PURGE,
  SUBMIT_STORE_BUTTON_SUCCESS,
  SUBMIT_VIDEO_BUTTON_FAILURE,
  SUBMIT_VIDEO_BUTTON_PURGE,
  SUBMIT_VIDEO_BUTTON_SUCCESS,
  TASK_UPDATE_FAILURE,
  TASK_UPDATE_SUCCESS,
  UPDATE_ADMIN_TRIGGER_APPROVAL_FAILURE,
  UPDATE_ADMIN_TRIGGER_APPROVAL_SUCCESS,
  UPDATE_ORGANISATION_FAILURE,
  UPDATE_ORGANISATION_SUCCESS,
  VALIDATE_APP_STORE_FAILURE,
  VALIDATE_APP_STORE_PURGE,
  VALIDATE_APP_STORE_REQUEST,
  VALIDATE_APP_STORE_SUCCESS,
} from "../actions/types";

export function gameStage(
  state = { payload: { project_id: "", stage: "" } },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case GAME_STAGE_SUCCESS:
      return {
        ...state,
        payload,
      };
    default:
      return state;
  }
}

export function gameFirstStage(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_FIRST_STEP_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case ADD_FIRST_STEP_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function projectList(state = { loading: true }, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        payload,
        loading: false,
      };
    }
    case GET_PROJECT_LIST_FAILURE: {
      return {
        ...state,
        payload,
        loading: false,
      };
    }
    default:
      return state;
  }
}

export function projectInformation(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECT_INFORMATION_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const initialState = {
  project_id: "",
  isValid: "",
};

export function stepOneReducer(state = initialState, action) {
  const { type, payload, isValid } = action;

  switch (type) {
    case SUBMIT_STEP_ONE_SUCCESS:
      return {
        ...state,
        project_id: payload.data.project_id,
        isValid,
      };

    case SUBMIT_STEP_ONE_FAILURE:
      return {
        ...state,
        ...payload,
      };

    case SUBMIT_STEP_ONE_PURGE:
      return null;

    default:
      return state;
  }
}

export function stepTwoReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_STEP_TWO_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case SUBMIT_STEP_TWO_FAILURE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

export function stepThreeReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_STEP_THREE_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case SUBMIT_STEP_THREE_FAILURE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

export function stepFourReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_STEP_FOUR_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case SUBMIT_STEP_FOUR_FAILURE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

export function stepLastReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_STEP_LAST_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case SUBMIT_STEP_LAST_FAILURE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

/**
 * ---------------------------- New flow Api --------------------------------------
 */

const initialStateForListProject = {
  listProject: [],
};

export function getListProject(state = initialStateForListProject, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_PROJECT_SUCCESS:
      return {
        ...state,
        listProject: payload,
        loading: false,
      };
    case GET_LIST_PROJECT_FAILURE:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    default:
      return state;
  }
}

export function getDetailProject(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DETAILS_PROJECT_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_DETAILS_PROJECT_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const initialStateTask = {
  task: [],
  loading: true,
};

export function getTask(state = initialStateTask, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TASK_SUCCESS:
      return {
        ...state,
        task: payload.data,
        loading: false,
      };
    case GET_TASK_FAILURE:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GET_TASK_PURGE:
      return null;
    default:
      return state;
  }
}

export function getProjectSheet(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECT_SHEET_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_PROJECT_SHEET_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const initialStateTemplate = {
  template: [],
  is_valid: false,
};

export function getTaskTemplate(state = initialStateTemplate, action) {
  const { type, payload, is_valid } = action;

  switch (type) {
    case GET_TASK_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: payload,
        is_valid: is_valid,
      };
    case GET_TASK_TEMPLATE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function facebookValidate(state = { loading: false }, action) {
  const { type, payload } = action;

  switch (type) {
    case FACEBOOK_VALIDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FACEBOOK_VALIDATE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case FACEBOOK_VALIDATE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const initialStateTaskUpdate = {
  completed: "",
};

export function taskUpdate(state = initialStateTaskUpdate, action) {
  const { type, payload } = action;

  switch (type) {
    case TASK_UPDATE_SUCCESS:
      return {
        ...state,
        completed: payload,
      };
    case TASK_UPDATE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const initialStateSheetUpdate = {
  assets: [],
};

export function projectSheetUpdate(state = initialStateSheetUpdate, action) {
  const { type, payload } = action;

  switch (type) {
    case PROJECT_SHEET_UPDATE_SUCCESS:
      return {
        ...state,
        assets: payload.data.assets,
      };
    case PROJECT_SHEET_UPDATE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const initialStateStore = {
  projectLogo: "",
  appStoreId: "",
  gameName: "",
  is_valid: "",
  loading: true,
};

export function validateAppStore(state = initialStateStore, action) {
  const { type, payload } = action;

  switch (type) {
    case VALIDATE_APP_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VALIDATE_APP_STORE_SUCCESS:
      return {
        ...state,
        projectLogo: payload.data.project_logo,
        appStoreId: payload.data.app_store_id,
        bundle_id: payload.data.bundle_id,
        gameName: payload.data.game_name,
        is_valid: payload.is_valid,
        loading: false,
      };
    case VALIDATE_APP_STORE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case VALIDATE_APP_STORE_PURGE:
      return null;
    default:
      return state;
  }
}

const initialStateProjectSheetGet = {
  is_valid: false,
  data: {
    assets: [],
    versions: [],
    integrations: [],
  },
};

export function projectSheetGet(state = initialStateProjectSheetGet, action) {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_SHEET_GET_SUCCESS:
    case PROJECT_SHEET_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case PROJECT_SHEET_PURGE:
      return null;
    default:
      return state;
  }
}

const initialStateForDetails = {
  logoUrl: "",
  data: [],
};

export function getProjectSheetForDetails(
  state = initialStateForDetails,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECT_SHEET_FOR_DETAILS_SUCCESS:
      return {
        ...state,
        logoUrl: payload.project_logo_full_url,
        data: payload.assets,
      };
    case GET_PROJECT_SHEET_FOR_DETAILS_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const initialStateForTestGet = {
  startDate: "",
  endDate: "",
  data: "",
};

export function projectTestsGet(state = initialStateForTestGet, action) {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_TESTS_GET_SUCCESS:
      return {
        ...state,
        data: payload ? payload : undefined,
      };
    case PROJECT_TESTS_GET_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function projectTestGet(state = initialStateForTestGet, action) {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_TEST_GET_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case PROJECT_TEST_GET_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case PROJECT_TEST_PURGE:
      return null;
    default:
      return state;
  }
}

export function deleteGame(state = { isValid: "" }, action) {
  const { type, payload } = action;

  switch (type) {
    case PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        ...payload,
        isValid: payload.is_valid,
      };
    case PROJECT_DELETE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function submitSdkButton(state = {}, action) {
  const { type, payload, res1, res2 } = action;

  switch (type) {
    case SUBMIT_SDK_BUTTON_SUCCESS:
      return {
        ...state,
        ...payload,
        res1,
        res2,
      };
    case SUBMIT_SDK_BUTTON_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case SUBMIT_SDK_BUTTON_PURGE:
      return null;
    default:
      return state;
  }
}

export function submitStoreButton(state = { assets: [] }, action) {
  const { type, payload, res1, res2, res3 } = action;

  switch (type) {
    case SUBMIT_STORE_BUTTON_SUCCESS:
      return {
        ...state,
        ...payload,
        res1,
        res2,
        res3,
        assets: payload.data.assets,
      };
    case SUBMIT_STORE_BUTTON_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case SUBMIT_STORE_BUTTON_PURGE:
      return null;
    default:
      return state;
  }
}

export function submitVideoButton(state = { assets: [] }, action) {
  const { type, payload, res1, res2, res3 } = action;

  switch (type) {
    case SUBMIT_VIDEO_BUTTON_SUCCESS:
      return {
        ...state,
        ...payload,
        res1,
        res2,
        res3,
        assets: payload.data.assets,
      };
    case SUBMIT_VIDEO_BUTTON_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case SUBMIT_VIDEO_BUTTON_PURGE:
      return null;
    default:
      return state;
  }
}

export function submitProjectTest(
  state = { project_id: "", project_test_id: "" },
  action
) {
  const { type, payload, isValid } = action;

  switch (type) {
    case SUBMIT_PROJECT_TEST_SUCCESS:
      return {
        ...state,
        ...payload,
        project_id: payload.data.project_id,
        project_test_id: payload.data.project_test_id,
        isValid,
      };
    case SUBMIT_PROJECT_TEST_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getProjectResource(state = { data: {} }, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PROJECT_RESOURCE_SUCCESS:
      return {
        ...state,
        ...payload,
        data: payload,
      };
    case GET_PROJECT_RESOURCE_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getAdminTriggerApproval(
  state = { noObject: "", loading: true },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_TRIGGER_APPROVAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADMIN_TRIGGER_APPROVAL_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GET_ADMIN_TRIGGER_APPROVAL_FAILURE:
      return {
        ...state,
        ...payload,
        noObject: payload,
      };
    default:
      return state;
  }
}

export function updateAdminTriggerApproval(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ADMIN_TRIGGER_APPROVAL_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_ADMIN_TRIGGER_APPROVAL_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getAdminUsers(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_ADMIN_USERS_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getAdminProjectSheet(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_PROJECT_SHEET_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_ADMIN_PROJECT_SHEET_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getOrganisation(state = { data: {} }, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORGANISATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
        data: payload.data,
      };
    case GET_ORGANISATION_FAILURE:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export function updateOrganisation(state = { valid: "" }, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        ...payload,
        valid: payload.is_valid,
      };
    case UPDATE_ORGANISATION_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getWalletDetails(state = { balances: [] }, action) {
  const { type, payload, statusCode } = action;
  switch (type) {
    case GET_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        ...payload,
        balances: payload.data.wallet.balances,
        status: statusCode,
      };
    case GET_WALLET_DETAILS_FAILURE:
      return {
        ...state,
        ...payload,
        balances: [],
        status: statusCode,
      };
    default:
      return state;
  }
}

export function getProjectHead(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PROJECT_HEAD_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_PROJECT_HEAD_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getAdminProjectsTest(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_PROJECTS_TESTS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_ADMIN_PROJECTS_TESTS_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getAdminProjectTestId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_PROJECTS_TESTS_ID_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_ADMIN_PROJECTS_TESTS_ID_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function getAdminAttributions(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADMIN_ATTRIBUTIONS_REQUEST:
      return {
        ...state,
      };
    case GET_ADMIN_ATTRIBUTIONS_SUCCESS:
      return payload;
    case GET_ADMIN_ATTRIBUTIONS_FAILURE:
      return state;
    default:
      return state;
  }
}

export function getErrorMappings(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ERROR_MAPPING_SUCCESS:
      return {
        ...state,
        [payload.object_id]: payload,
      };
    case GET_ERROR_MAPPING_FAILURE:
      return state;
    default:
      return state;
  }
}
