import i18next from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import { getLocalisation } from "../data/service/public/public.service";
import translationEN_US from "./en-US/translation.json";
import translationRU_RU from "./ru-RU/translation.json";
import translationTR_TR from "./tr-TR/translation.json";
import translationZH_Hans_CN from "./zh-CN/translation.json";

export const LANGUAGE_ID_MAP = new Map([
  ["en-US", "en_US"],
  ["ru-RU", "ru_RU"],
  ["tr-TR", "tr_TR"],
  ["zh-CN", "zh-Hans_CN"],
]);

i18next.use(initReactI18next).init({
  resources: {
    "en-US": {
      translation: translationEN_US,
    },
    "ru-RU": {
      translation: translationRU_RU,
    },
    "tr-TR": {
      translation: translationTR_TR,
    },
    "zh-Hans_CN": {
      translation: translationZH_Hans_CN,
    },
  },
  lng: "en-US",
  fallbackLng: "en-US",

  interpolation: {
    escapeValue: false, // React covers the XSS
  },
});

loadServerLocale("en-US");

export async function loadServerLocale(locale) {
  const serverLocale = LANGUAGE_ID_MAP.get(locale);

  try {
    const localisation = await getLocalisation(serverLocale);

    i18next.addResourceBundle(locale, "server", localisation);
  } catch (e) {
    console.error(e);
  }
}

export function updateMomentLocale() {
  const relativeTime = {};

  for (const key of ["future", "past"]) {
    relativeTime[key] = i18next.t(`relativeTime.${key}`, { count: "%s" });
  }

  for (const key of [
    "s",
    "ss",
    "m",
    "mm",
    "h",
    "hh",
    "d",
    "dd",
    "w",
    "ww",
    "M",
    "MM",
    "y",
    "yy",
  ]) {
    relativeTime[key] = i18next.t(`relativeTime.${key}`, { count: "%d" });
  }

  return moment.updateLocale("en", {
    relativeTime,
  });
}
