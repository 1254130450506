import cx from "classnames";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import zxcvbn from "zxcvbn";
import endPoints from "../../_helpers/endPoints.json";
import { userActions } from "../../actions/auth";
import { enqueueSnackbar } from "../../actions/notifier";
import Logo from "../Logo";
import PasswordStrengthMeter from "./PasswordStrengthMeter";

export const PASSWORD_VALIDATION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

const CreatePassword = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState();
  const [strongPassword, handleStrongPassword] = useState(false);

  const [passwordView, changePasswordView] = useState(false);
  const [forgotPage, changePageLabel] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search ?? "");

    const token = queryParams.get("token") ?? undefined;
    if (!token) {
      history.push("/");
    }

    if (queryParams.get("resetpassword") === "true") {
      changePageLabel(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    let testedResult = "";
    if (password && password !== "") {
      testedResult = zxcvbn(password);
    }

    setPasswordStrength(testedResult);

    if (typeof testedResult === "object") {
      switch (testedResult.score) {
        case 3:
        case 4:
          handleStrongPassword(true);
          return;
        default:
          break;
      }
    }

    handleStrongPassword(false);
  }, [password]);

  const submit = () => {
    const queryParams = new URLSearchParams(history.location.search ?? "");

    if (confirmPassword.length < 1) {
      return;
    }
    if (password.length < 8 || !strongPassword) {
      dispatch(enqueueSnackbar(t("error.password.weak"), "warning"));
      return;
    }
    if (password !== confirmPassword) {
      dispatch(enqueueSnackbar(t("error.password.noMatch"), "warning"));
      return;
    }

    if (
      !password.match(/[a-z]/g) &&
      !password.match(/[A-Z]/g) &&
      !password.match(/[0-9]/g)
    ) {
      dispatch(enqueueSnackbar(t("error.password.requirements")));
      return;
    }
    if (forgotPage === true) {
      dispatch(
        userActions.createPassword(endPoints.resetPassword, {
          auth_token: queryParams.get("token"),
          password: `f(${md5(password)})`,
        })
      );
    } else {
      dispatch(
        userActions.createPassword(endPoints.EmailVerify, {
          auth_token: queryParams.get("token"),
          password: `f(${md5(password)})`,
        })
      );
    }
  };

  function handlePasswordView() {
    changePasswordView((prevState) => !prevState);
  }

  const isMeetingRequirements = PASSWORD_VALIDATION.exec(password);

  return (
    <div className="full-page">
      {/*Main wrapper start*/}
      <div className="full-page-section">
        <div className="full-page-form">
          <div className="full-page-form-container">
            <div className="full-page-head">
              <Logo />
              <h1>
                {t(
                  `page.createPassword.title.${
                    !!forgotPage ? "reset" : "create"
                  }`
                )}
              </h1>
            </div>
            <form id="loginForm">
              <div
                className={cx("form-group input-group", {
                  invalid: !isMeetingRequirements,
                })}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src="img/icon-password.svg" alt="icon-password" />
                  </span>
                </div>
                <input
                  className="form-control"
                  type={passwordView ? "text" : "password"}
                  placeholder={t("password.new")}
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <div
                  className="input-group-append"
                  onClick={handlePasswordView}
                >
                  <span className="input-group-text">
                    <i className={`fa fa-eye${passwordView ? "" : "-slash"}`} />
                  </span>
                </div>
              </div>

              <div
                className={cx("form-group input-group", {
                  invalid: confirmPassword !== password,
                })}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src="img/icon-password.svg" alt="icon-password" />
                  </span>
                </div>
                <input
                  className="form-control"
                  type="password"
                  placeholder={t("password.confirm")}
                  autoComplete="new-password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
              </div>

              <div className="row">
                <div className="col-12">
                  <p className="small">{t("password.requirements")}</p>
                  <PasswordStrengthMeter result={passwordStrength} />
                </div>
              </div>
              <div className="submit-btn-group ">
                <button
                  type="button"
                  disabled={
                    !isMeetingRequirements || confirmPassword !== password
                  }
                  className="btn btn-secondary btn-hover-outline w-100"
                  onClick={submit}
                >
                  {t("confirm")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreatePassword;
