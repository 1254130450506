import i18next from "i18next";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  LANGUAGE_ID_MAP,
  loadServerLocale,
  updateMomentLocale,
} from "./locale";
import sha256 from "./util/sha256";

const USER_DATA_KEY = "userData";

export const getLocalStorageUserData = () => {
  let json = undefined;

  const data = localStorage.getItem(USER_DATA_KEY);
  if (data) {
    try {
      json = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }

  return json;
};

export const setLocalStorageUserData = (data) => {
  if (data) {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(data));
  } else {
    localStorage.removeItem(USER_DATA_KEY);
  }

  // Informs current tab of storage update
  window.dispatchEvent(new Event("storage"));
};

const UserDataContext = createContext(undefined);

export function UserDataProvider({ children }) {
  const [userData, setUserData] = useState(getLocalStorageUserData());

  useEffect(() => {
    const onStorageChange = () => {
      setUserData(getLocalStorageUserData());
    };

    // Load initial data
    onStorageChange();

    // Register event
    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { locale } = userData ?? {};
  useEffect(() => {
    if (!userData) {
      return;
    }

    const [l] = Array.from(LANGUAGE_ID_MAP.entries()).find(
      ([i18n, server]) => server === locale
    ) ?? [LANGUAGE_ID_MAP.keys()[0]];

    i18next
      .changeLanguage(l)
      .then(() => loadServerLocale(l))
      .then(() => updateMomentLocale());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const userId = userData?.user_id;
  useEffect(() => {
    // Update Google Analytics user ID
    if (window.dataLayer) {
      let promise = Promise.resolve(undefined);
      if (!!userId) {
        promise = sha256(userId);
      }

      promise.then((hashedId) => {
        window.dataLayer.push({ event: "userIdSet", user_id: hashedId });
      });
    }
  }, [userId]);

  return (
    <UserDataContext.Provider value={userData}>
      {children}
    </UserDataContext.Provider>
  );
}

export default function useUserData() {
  return useContext(UserDataContext);
}
