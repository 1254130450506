import React, { Component } from "react";
import Trans from "../common/Trans";

// TODO Don't update state in the render cycle
class PasswordStrengthMeter extends Component {
  createPasswordLabel = (result) => {
    switch (result?.score) {
      case 0:
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  render() {
    return (
      <div className="password-strength-meter">
        <label
          className="password-strength-meter-label"
          style={{ width: "100%" }}
        >
          <progress
            className={`password-strength-meter-progress strength-${this.createPasswordLabel(
              this.props.result
            )}`}
            value={this.props.result?.score}
            max="4"
            style={{ width: "100%" }}
          />
          <div className="password-strength">
            <p>
              <Trans
                i18nKey="password.strength.label"
                values={{
                  strength: this.createPasswordLabel(this.props.result),
                }}
              />
            </p>
          </div>
        </label>
      </div>
    );
  }
}

export default PasswordStrengthMeter;
