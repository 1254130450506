import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const InviteContext = React.createContext(undefined);

export default function InviteProvider({ children }) {
  const location = useLocation();

  const [code, setCode] = useState(undefined);

  useEffect(() => {
    const invite = sessionStorage.getItem("invite");
    if (invite) {
      setCode(invite);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search ?? "");

    let invite = queryParams.get("invite");
    if ((invite?.length ?? 0) < 1) {
      invite = undefined;
    }

    if (invite && invite !== code) {
      sessionStorage.setItem("invite", invite);
      setCode(invite);
    }
  }, [code, location.search]);

  return (
    <InviteContext.Provider value={code}>{children}</InviteContext.Provider>
  );
}
