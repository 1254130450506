import { axiosConfig, isValidURL } from "../axiosConfig";

function appendTimestampHeader(config) {
  if (isValidURL(config) && !config.noTimestamp) {
    if (config.headers?.timestamp === undefined) {
      if (config.headers === undefined) {
        config.headers = {};
      }

      config.headers.timestamp = Date.now().toString();
    }
  }

  return config;
}

axiosConfig.interceptors.request.use(appendTimestampHeader, (err) => err);
