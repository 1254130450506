import { ADD_STORE_SUCCESS, ADD_STORE_FAILURE } from "../actions/types";

export function storeStep(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_STORE_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case ADD_STORE_FAILURE:
      return {};
    default:
      return state;
  }
}
