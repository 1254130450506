import { Tooltip } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export default function PermissionTooltip({ children, hasPermission = true }) {
  const { t } = useTranslation();

  if (hasPermission === true) {
    return children;
  }

  return (
    <Tooltip
      title={
        typeof hasPermission === "string"
          ? hasPermission
          : t("error.permission")
      }
    >
      {children}
    </Tooltip>
  );
}
