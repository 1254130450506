import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";

export default {
  necessary: {
    icon: faCheckCircle,
    canToggle: false,
  },
  targeting: {
    icon: faRetweet,
    canToggle: true,
  },
};
