const styles = {
  // Root
  root: {
    maxWidth: 300,
    "& > div": {
      flexDirection: "column-reverse",
    },
    "& #notistack-snackbar": {
      marginRight: 15,
    },
  },
  // Green colour variant
  success: { backgroundColor: "#DFF8D3", color: "#40871D" },
  // Red colour variant
  error: { backgroundColor: "#F0D9D9", color: "#B33F40" },
  // Orange colour variant
  warning: { backgroundColor: "#FFE7CC", color: "#C26700" },
  // Blue colour variant
  info: { backgroundColor: "#D5EBF2", color: "#5B96A8" },
};

export default styles;
