import CheckEmail from "../../components/checkEmail/CheckEmail";
import AcceptInvite from "../../components/createPassword/AcceptInvite";
import CreatePassword from "../../components/createPassword/CreatePassword";
import Index from "../../components/home/home";
import HybridSubmission from "../../components/hybrid-submission/HybridSubmission";
import Login from "../../components/login/Login";
import LostPassword from "../../components/lostPassword/LostPassword";
import { NotFound } from "../../components/notFound/NotFound";
import Signup from "../../components/signup/Signup";
import { TestErrorPage } from "../../ErrorBoundary";
import { loginRequired, noLoginRequired } from "../authorisations";
import ROUTES_ADMIN from "./admin";
import ROUTES_COACHING from "./coaching.routes";
import ROUTES_GENERAL from "./general.routes";
import ROUTES_ORGANISATION from "./organisation";
import ROUTES_PROJECT from "./project";
import ROUTES_SUBMIT_GAME from "./submit-game.routes";
import ROUTES_USER from "./user";

const ROUTES = [
  {
    id: "index",

    path: "/",
    exact: true,
    isAuthorised: loginRequired,

    Component: Index,

    children: [
      ...ROUTES_GENERAL,
      ...ROUTES_COACHING,
      ...ROUTES_USER,
      ...ROUTES_ORGANISATION,
      ...ROUTES_SUBMIT_GAME,
      ...ROUTES_PROJECT,
      ...ROUTES_ADMIN,
    ],
  },
  {
    id: "login",

    path: "/login",
    exact: true,
    isAuthorised: noLoginRequired,

    hasHeading: false,

    Component: Login,
  },
  {
    id: "checkEmail",

    path: "/check-email",
    exact: true,
    isAuthorised: noLoginRequired,

    hasHeading: false,
    Component: CheckEmail,
  },
  {
    id: "signup",

    path: "/signup",
    exact: true,
    isAuthorised: noLoginRequired,

    hasHeading: false,

    Component: Signup,
  },
  {
    id: "acceptInvite",

    path: "/accept-invite",
    exact: true,

    hasHeading: false,

    Component: AcceptInvite,
  },
  {
    id: "createPassword",

    path: "/create-password",
    exact: true,

    hasHeading: false,

    Component: CreatePassword,
  },
  {
    id: "lostPassword",

    path: "/lost-password",
    exact: true,

    hasHeading: false,

    Component: LostPassword,
  },
  {
    id: "hybrid-submission",

    path: "/hybrid",
    exact: true,

    hasHeading: false,

    Component: HybridSubmission,
  },
  {
    id: "testError",

    path: "/test-error",
    exact: true,

    hasHeading: false,

    Component: TestErrorPage,
  },
  {
    id: "pageNotFound",

    hasHeading: false,

    Component: NotFound,
  },
];

function processIds(routes, id = "") {
  for (let route of routes) {
    route.id = id.length > 0 ? `${id}.${route.id}` : route.id;

    if (route.children) {
      processIds(route.children, route.id);
    }
  }
}

processIds(ROUTES);

export default ROUTES;

function generateFlatRoutes(routes) {
  const newRoutes = [];

  for (const route of routes) {
    newRoutes.push(route);

    if (route.children) {
      newRoutes.push(...generateFlatRoutes(route.children));
    }
  }

  return newRoutes;
}

export const ROUTES_FLAT = generateFlatRoutes(ROUTES);
