import { getLocalStorageUserData } from "../../UserDataContext";
import { axiosConfig, isValidURL } from "../axiosConfig";

export function getToken() {
  const userData = getLocalStorageUserData();

  return userData?.auth_token;
}

function appendAuthHeader(config) {
  if (isValidURL(config) && !config.headers.Authorization && !config.noAuth) {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }

  return config;
}

axiosConfig.interceptors.request.use(appendAuthHeader, (err) => err);
