import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toggleSubmitGamePopover } from "../../actions/confirmbox";
import a1 from "../../media/home/a1.png";
import B01 from "../../media/home/B01.png";
import coachingBanner from "../../media/home/coaching.png";
import homeBannerBg from "../../media/home/homebannerbg.jpg";
import myGamesBanner from "../../media/home/myGames.png";
import videoPlaceholder from "../../media/home/videoplaceholder.jpg";
import useUserData from "../../UserDataContext";
import EventLogo from "../common/EventLogo";
import Footer from "../common/footer/footer";

const Index = () => {
  const dispatch = useDispatch();

  const userData = useUserData();
  const history = useHistory();

  useEffect(() => {
    if (!userData) {
      history.push("/");
      return;
    }

    if (!userData.is_signup_completed) {
      history.push("/organisation/info");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showHypercasualHeroes = false;

  return (
    <>
      <section
        className="banner"
        style={{ backgroundImage: `url(${homeBannerBg})` }}
      >
        <div className="banner-homecaption">
          <div className="container">
            <div className="bannerContentPanel">
              {showHypercasualHeroes ? (
                <div
                  className="hypercasual-heroes text-center position-relative d-flex align-items-center"
                  style={{
                    padding: 10,
                  }}
                >
                  <div className="side-panel d-flex align-items-center">
                    <EventLogo
                      style={{ width: 375, height: 120, marginBottom: 50 }}
                      timeRemainingStyle={{
                        transform: "rotate(336deg) translate(-10px, 10px)",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: 15,
                        borderRadius: "75px 75px 0 75px",
                        width: 275,
                        border: "4px solid #FCBF00",
                        fontSize: 14,
                      }}
                    >
                      A brand new Tesla up for grabs... Submit your game and if
                      it’s a hit, you could drive away in a car fit for a hero:
                      a Tesla Model 3!*
                    </div>
                  </div>
                  <div
                    className="position-relative flex-grow-1 flex-shrink-1 p-2"
                    style={{ minWidth: 360 }}
                  >
                    <div
                      style={{
                        color: "#898F93",
                        fontSize: 18,
                        margin: "0 0 10px 0",
                        lineHeight: 1.4,
                      }}
                    >
                      Get the chance to earn up to $100K, a Tesla car and a big
                      fat publishing deal – with only a prototype or a gameplay
                      video!
                    </div>
                    <div style={{ fontSize: 16, color: "#3E484E" }}>
                      Challenge open until 31st August 2021
                    </div>
                    <div className="mt-4">
                      <Link
                        className="btn btn-lg btn-secondary btn-hover-outline"
                        to="/submit-game"
                      >
                        Submit Game
                      </Link>
                    </div>
                  </div>
                  <div
                    className="side-panel d-flex align-items-center"
                    style={{
                      position: "relative",
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        backgroundColor: "#fff",
                        padding: 15,
                        borderRadius: "75px 0 75px 75px",
                        width: 275,
                        border: "4px solid #FCBF00",
                        fontSize: 14,
                      }}
                    >
                      Big cash for your game Hypercasual Heroes can earn up to
                      $100,000 in advances per game – plus uncapped royalties!
                    </div>
                    <div>
                      <img
                        src="https://s3.us-west-2.amazonaws.com/kwcdn.kwalee.com/wp-content/uploads/2021/07/09091258/David_Tesla_2.png"
                        style={{ width: 225, height: 175 }}
                        alt="Hypercasual Hero Tesla"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="bannerContentPanel__left">
                    <Link to={userData.is_internal ? "#" : "/submit-game/info"}>
                      <img src={B01} alt="img" />
                    </Link>
                  </div>
                  <div className="bannerContentPanel__center">
                    <h2>All you need to top the charts</h2>
                    <p>
                      Submit your game to start our journey together. You’ll get
                      quick testing and results, comprehensive design feedback
                      and much more from our proven experts in hyper casual,
                      hybrid and casual games. We’ll leave no stone unturned in
                      our mission to create the next hit with you!
                    </p>
                  </div>
                  <div className="bannerContentPanel__right">
                    <Link to={userData.is_internal ? "#" : "/submit-game/info"}>
                      <img src={a1} alt="img" />
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {userData.is_internal ? null : (
        <div className="buttons-section mb-80">
          <div className="container">
            <div className=" row">
              <div className=" col-md-4 text-center">
                <Link
                  component="button"
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={() => {
                    dispatch(toggleSubmitGamePopover(true));
                  }}
                >
                  <span>
                    {/* <img className="black-icon" src="img/icon-game1.svg" alt="" />
                  <img className="white-icon" src="img/icon-game1-white.svg" alt="" /> */}
                    <i className="fa fa-envelope" />
                  </span>{" "}
                  Submit Your Game
                </Link>
              </div>
              <div className=" col-md-4 text-center">
                <Link
                  to="/games"
                  type="button"
                  className="btn btn-lg btn-primary icon-mobile"
                >
                  <span className="align-middle">
                    {/* <img className="black-icon" src="img/icon-game.svg" alt="" />
                  <img className="white-icon" src="img/icon-game-white.svg" alt="" /> */}
                    <i className="fa fa-mobile" />
                  </span>{" "}
                  My Games
                </Link>
              </div>
              <div className=" col-md-4 text-center">
                <Link
                  to="/coaching"
                  type="button"
                  className="btn btn-lg btn-primary"
                >
                  <span>
                    <i className="fa fa-rocket" />
                  </span>{" "}
                  Coaching
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="process-section mb-80">
        <div className="container">
          <div className="process row">
            <div className="process__item col-lg-4 col-md-6">
              <div className="process__title">
                <h2>Let’s start by submitting your game in 4 easy steps</h2>
              </div>
              <div className="process__block">
                <div className="process__img">
                  <video
                    controls
                    playsInline
                    poster={videoPlaceholder}
                    src="https://kwalee-ares.s3.eu-west-1.amazonaws.com/live/portal/CPI+Test+Set+Up+21.12.2021.mp4"
                  />
                </div>
                <div className="process__text">
                  <p>
                    Our short tutorial covers the whole process of submitting
                    your game in less than two minutes – it’s that simple!.
                    Check it out and let’s start to submit your game
                  </p>
                </div>
              </div>
            </div>
            <div className="process__item col-lg-4 col-md-6">
              <div className="process__title">
                <h2>
                  Check the status of
                  <br /> your submitted games
                </h2>
              </div>
              <div className="process__block">
                <div className="process__img">
                  <figure>
                    <Link to="/games">
                      <img src={myGamesBanner} alt="" />
                    </Link>
                  </figure>
                </div>
                <div className="process__text">
                  <p>
                    In my game section you can track all your ongoing and
                    finalized tests. You can find your best performing video and
                    you can track CPI on a daily basis.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="process__item col-lg-4 col-md-6">
              <div className="process__title">
                <h2>
                  Everything you need to know about
                  <br /> mobile games
                </h2>
              </div>
              <div className="process__block">
                <div className="process__img">
                  <figure>
                    <Link to="/coaching">
                      <img src={coachingBanner} alt="" />
                    </Link>
                  </figure>
                </div>
                <div className="process__text">
                  <p>
                    You can find best practices, informative videos, our success
                    stories and much more in the coaching section. Don't forget
                    to check it regularly. for great new content to read and
                    watch!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default connect(null)(Index);
