import React from "react";
import { withRouter } from "react-router";
import Trans from "./components/common/Trans";
import Logo from "./components/Logo";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  _logError(err) {
    if (err === null || err === undefined) {
      return;
    }

    let errName = "Error";
    let errStr;
    if (typeof err === "object" && !Array.isArray(err)) {
      if (err.name) {
        errName = err.name;
      }

      errStr = err.message ? err.message : err.toString();
    } else {
      errStr = err.toString();
    }

    if (!errStr) {
      return;
    }

    window.dataLayer.push({
      event: "error",
      "gtm.errorMessage": errStr,
      "gtm.errorLineNumber": err.lineNumber ?? undefined,
      "gtm.errorUrl": window.location.href,
    });
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this._logError(error);
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div className="full-page">
        <div className="full-page-section">
          <div className="full-page-form">
            <div className="full-page-form-container">
              <div className="full-page-head">
                <Logo />
                <h1>
                  <Trans i18nKey="page.error.title" />
                </h1>
                <button
                  type="button"
                  onClick={() => {
                    this.props.history.push("/");
                    this.setState({ hasError: false });
                    window.location.reload();
                  }}
                  className="btn btn-secondary btn-hover-outline w-100"
                >
                  <Trans i18nKey="page.notFound.goHome" />{" "}
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ErrorBoundary);

export function TestErrorPage() {
  // eslint-disable-next-line no-self-compare
  if (1 === 1) {
    throw new Error("Test Error");
  }

  return <div>Testing error...</div>;
}
