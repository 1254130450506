import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ENV } from "../_helpers/config";
import chris from "../media/chris.png";
import logo from "../media/logo.png";
import { BetaHiddenContext } from "./common/BetaHiddenProvider";

export default function Logo({ to, live, dev, betaTag }) {
  const { isBetaHidden, setBetaHidden } = useContext(BetaHiddenContext);

  return (
    <Link
      className="navbar-brand"
      to={to ?? "/"}
      onContextMenu={(event) => {
        if (event.shiftKey) {
          event.preventDefault();
          setBetaHidden(!isBetaHidden);
        }
      }}
    >
      <img
        src={ENV === "production" || isBetaHidden ? live ?? logo : dev ?? chris}
        alt="logo"
        title="Kwalee"
      />

      {!!betaTag && !isBetaHidden && <div>BETA</div>}
    </Link>
  );
}
