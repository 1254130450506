import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar as closeSnackbarOption } from "../../actions/notifier";

let displayed = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (store) => store.dispatchNotificaion.notifications || []
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key) => {
    displayed = [...displayed, key];
  };

  React.useEffect(() => {
    notifications.forEach((snack) => {
      const { key, message } = snack;
      const options = {
        ...snack,
        // action - custom close button
        action: (
          <Close
            onClick={() => dispatch(closeSnackbarOption(key))}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 11,
              right: 7,
            }}
          />
        ),
        persist: true,
      };

      // Dismiss flag is set true if user clicks on close button
      if (options.dismissed) {
        closeSnackbar(key);
        return;
      }

      // If snack is already displayed, return
      if (displayed.includes(key)) return;

      // Display a snack
      enqueueSnackbar(message, options);

      // Track all displayed snacks
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
