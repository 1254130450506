import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { ZoomIn, ZoomOut } from "@material-ui/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useDispatch, useSelector } from "react-redux";
import { NODE_ENV } from "../../_helpers/config";
import endPoints from "../../_helpers/endPoints.json";
import { agreeLicence } from "../../actions/accounts";
import { userActions } from "../../actions/auth";
import Trans from "../common/Trans";

const SCROLL_THRESHOLD = 50;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  document: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,

    "& .react-pdf__Page__canvas": {
      margin: "0 auto",
    },
  },
  zoom: {
    position: "sticky",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    display: "flex",

    "& > div": {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",

      "& > span": {
        backgroundColor: `${theme.palette.common.white} !important`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
        padding: theme.spacing(1),
      },
    },
  },
}));

const endcapProps = {
  display: "block",
  backgroundColor: "#ffcb05",
  zIndex: 1,
  transition: "box-shadow 0.3s ease-in-out",
};

export let failedRequests = [];
export default function LicenseModal() {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();

  const licenses = useSelector(
    (state) => state.getLicence?.data?.outstanding_licences
  );
  const licensesAvailable = !!licenses && licenses.length > 0;

  const contentRef = useRef();

  const hasVerticalScroll = () =>
    (contentRef.current?.scrollHeight ?? 0) > (contentRef?.clientHeight ?? 0);

  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(undefined);

  const [isTop, setTop] = useState(true);
  const [isBottom, setBottom] = useState(!hasVerticalScroll());

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onUpdateWidth = () => {
    setWidth(contentRef.current?.clientWidth - 48);
  };

  useEffect(() => {
    onUpdateWidth();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onResize = () => {
    onScroll(contentRef.current);
    onUpdateWidth();
  };

  useEffect(() => {
    if (!licensesAvailable) {
      const failed = [...failedRequests];
      failedRequests = [];

      while (failed.length > 0) {
        const request = failed.shift();
        dispatch(request.func.apply(null, request.args ?? []));
      }
    }
  }, [dispatch, licensesAvailable]);

  const license = (licenses?.length ?? 0) > 0 ? licenses[0] : undefined;

  const onScroll = useCallback((event) => {
    if (!event) {
      return;
    }

    const target = "target" in event ? event.target : event;

    setTop(!hasVerticalScroll() || target.scrollTop <= SCROLL_THRESHOLD);
    setBottom(
      !hasVerticalScroll() ||
        target.scrollHeight - target.scrollTop <=
          target.clientHeight + SCROLL_THRESHOLD
    );
  }, []);

  const onDisagree = () => {
    dispatch(userActions.logout(endPoints.Logout));
  };

  const onAgree = () => {
    dispatch(
      agreeLicence({
        licence_id: license.licence_id,
        version_number: license.version_number,
      })
    );
  };

  return (
    <Dialog open={licensesAvailable}>
      <DialogTitle
        id="customized-dialog-title"
        style={{
          ...endcapProps,
          boxShadow: `0 2px 20px -6px ${
            !hasVerticalScroll() || isTop ? "transparent" : "black"
          }`,
        }}
      >
        {license?.display_name}
        <div className="small">{t("error.license.agreeSpecific")}</div>
      </DialogTitle>
      <DialogContent
        className={classes.content}
        ref={contentRef}
        onScroll={onScroll}
      >
        <Document
          className={classes.document}
          loading="Loading license..."
          error="Failed to load license."
          file={
            license?.document_url
              ? NODE_ENV === "production"
                ? license.document_url
                : `https://api.codetabs.com/v1/proxy?quest=${license.document_url}`
              : undefined
          }
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log(error)}
        >
          {Array.from(new Array(numPages), (_, i) => (
            <Page key={i} pageNumber={i + 1} scale={scale} width={width} />
          ))}
        </Document>

        <Box className={classes.zoom}>
          <Box>
            <IconButton
              color="inherit"
              aria-label="zoom in"
              component="span"
              onClick={() => setScale((scale) => scale + 0.1)}
            >
              <ZoomIn />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="zoom out"
              component="span"
              onClick={() => setScale((scale) => Math.max(0.1, scale - 0.1))}
            >
              <ZoomOut />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          ...endcapProps,
          boxShadow: `0 -2px 20px -6px ${
            !hasVerticalScroll() || isBottom ? "transparent" : "black"
          }`,
        }}
      >
        <div className="w-100 small p-2">
          {t("modal.license.acknowledgeStatement", {
            license: license?.display_name,
          })}
        </div>
        <Box textAlign="right" p={2}>
          <button className="btn btn-sm btn-primary" onClick={onDisagree}>
            <Trans i18nKey="modal.license.response.disagree" />
          </button>
          <button
            autoFocus
            className="btn btn-sm btn-secondary btn-hover-outline"
            disabled={!isBottom}
            onClick={onAgree}
          >
            {t("modal.license.response.agree")}
          </button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
