import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR } from "../actions/types";

const defaultState = {
  notifications: [],
};

export const dispatchNotificaion = (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          return {
            ...notification,
            // If dismissed is set to true, snack will be removed
            dismissed: notification.key === action.key,
          };
        }),
      };

    default:
      return state;
  }
};
