import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { validateMaintenance } from "../../actions/generic";

const Maintenance = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isMaintenance = useSelector((state) => state.maintenanceState);

  useEffect(() => {
    dispatch(validateMaintenance());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMaintenance) {
      return;
    }

    const id = setInterval(() => {
      // Try to get result every 5 minutes
      dispatch(validateMaintenance());
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(id);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaintenance]);

  if (!isMaintenance) {
    return null;
  }

  return (
    <div
      className="full-page"
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999999,
      }}
    >
      <div className="full-page-section flex-column">
        <i className="fa fa-cog fa-5x mb-5" />
        <h1>{t("page.maintenance.title")}</h1>
        <h1>{t("page.maintenance.subtitle")}</h1>
      </div>
    </div>
  );
};

export default Maintenance;
