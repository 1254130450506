import {
  CLOSE_SNACKBAR,
  SHOW_CONFIRMBOX,
  TOGGLE_SUBMIT_GAME_POPOVER,
} from "../actions/types";

const defaultState = {
  title: "",
  content: "",
  confirmText: "OK",
  cancelText: "Cancel",
  icon: "fa fa-question-circle text-dark",
  onConfirm: () => {},
  onClose: () => {},
  open: false,
};

export const confirmboxPopup = (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_CONFIRMBOX:
      return {
        ...state,
        ...action.payload,
        open: true,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export const submitGamePopover = (state = { isOpen: false }, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_SUBMIT_GAME_POPOVER:
      return {
        ...state,
        isOpen: payload,
      };
    default:
      return state;
  }
};
