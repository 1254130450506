import React, { useCallback, useContext, useEffect, useState } from "react";
import { getRoles } from "../data/service/public/public.service";
import { Placeholder } from "../LoadingSuspense";
import useUserData from "../UserDataContext";
import {
  PERMISSION_ACCESS_READ,
  PERMISSION_ACCESS_WRITE,
} from "./permissionAccessLevels";

const PermissionContext = React.createContext({
  roles: [],
  permissions: {},
  hasPermission: (id, access) => false,
});

export function PermissionLoader({ children }) {
  const userData = useUserData();

  const [isLoading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});

  const userId = userData?.user_id;
  useEffect(() => {
    loadPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const loadPermissions = async () => {
    if (!userData) {
      setPermissions({});
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const roles = await getRoles();
      const userRoles = userData.role_ids
        .map((id) => roles.find((role) => role.object_id === id))
        .filter((role) => role !== undefined);

      setRoles(userRoles);

      const permissions = {};
      for (const role of userRoles) {
        for (const { code, can_read, can_write } of role.permissions) {
          let perm = permissions[code];
          if (!perm) {
            permissions[code] = perm = {
              can_read: false,
              can_write: false,
            };
          }

          perm.can_read = perm.can_read || can_read;
          perm.can_write = perm.can_write || can_write;
        }
      }

      setPermissions(permissions);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const hasPermission = useCallback(
    (id, access) => {
      if (userData?.is_superuser) {
        return true;
      }

      const permission = permissions[id];
      if (!permission) {
        return false;
      }

      switch (access) {
        case PERMISSION_ACCESS_READ:
          return permission.can_read;
        case PERMISSION_ACCESS_WRITE:
          return permission.can_write;
        default:
          break;
      }

      return false;
    },
    [userData, permissions]
  );

  return (
    <PermissionContext.Provider
      value={{
        roles,
        permissions,
        hasPermission,
      }}
    >
      {isLoading ? <Placeholder /> : children}
    </PermissionContext.Provider>
  );
}

export default function usePermissions() {
  return useContext(PermissionContext);
}
