import {
  makeStyles,
  Menu,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import cx from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ENV } from "../../../_helpers/config";
import endPoints from "../../../_helpers/endPoints.json";
import { userActions } from "../../../actions/auth.jsx";
import { hideConfirmbox, showConfirmbox } from "../../../actions/confirmbox";
import { getListProject, getWalletDetails } from "../../../actions/game";
import bronzeBadge from "../../../media/badge/bronze-badge.svg";
import goldBadge from "../../../media/badge/gold-badge.svg";
import silverBadge from "../../../media/badge/silver-badge.svg";
import logo from "../../../media/logo-kwalee.png";
import { ReactComponent as Monday } from "../../../media/monday.svg";
import usePermissions from "../../../permissions/PermissionLoader";
import {
  loginRequired,
  superUserRequired,
} from "../../../routes/authorisations";
import { ROUTES_FLAT } from "../../../routes/definitions";
import ROUTES_ORGANISATION, {
  ROUTE_WALLET,
} from "../../../routes/definitions/organisation";
import ROUTES_USER from "../../../routes/definitions/user";
import useUserData from "../../../UserDataContext";
import Logo from "../../Logo";
import { ArrowDropDownOutlined } from "@material-ui/icons";
import useDebounce from "../../../util/useDebounce";

const useStyles = makeStyles((theme) => ({
  announcement: {
    backgroundColor: "#000",
    color: theme.palette.error.contrastText,
    textAlign: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: "1rem",

    "& a": {
      color: "inherit",
      fontWeight: "bold",
    },
  },
  navigation: {
    color: "#343a40",
    fontWeight: 500,
    cursor: "pointer",
    textTransform: "capitalize",
    margin: 0,

    "&:hover": {
      color: " #ffcb05",
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
    },
  },
  popOverRoot: {
    pointerEvents: "none",
    // marginTop:'5px'
  },
}));

const Header = ({ headerTitle }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userData = useUserData();
  const permissions = usePermissions();

  const [balances, setBalances] = useState({
    coinCode: "",
    coin: 0,
    tierCode: "",
    tier: 1,
  });

  const orgBal = useSelector((state) => state.getWalletDetails.balances);

  useEffect(() => {
    if (orgBal) {
      for (const bal of orgBal) {
        if (bal.code === "coin") {
          setBalances((prev) => ({
            ...prev,
            coinCode: bal.code,
            coin: bal.value,
          }));
        }
      }
    }
  }, [orgBal]);

  let onOk = () => {
    dispatch(userActions.logout(endPoints.Logout));
    dispatch(hideConfirmbox());
  };

  function logout(event) {
    event.preventDefault();

    dispatch(
      showConfirmbox({
        title: t("modal.logout.title"),
        content: t("modal.logout.content"),
        confirmText: t("modal.logout.confirm"),
        cancelText: t("modal.logout.cancel"),
        icon: "fa fa-question-circle text-dark",
        onConfirm: onOk,
      })
    );
  }

  const isInternal = !!userData?.is_internal;

  useEffect(() => {
    if (!isInternal) {
      dispatch(getListProject());
      dispatch(getWalletDetails());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navItemGroups = useMemo(() => {
    const filteredRoutes = ROUTES_FLAT.filter((route) => {
      let { isAuthorised } = route;

      let isAuthorisedResult;
      if (typeof isAuthorised === "boolean") {
        isAuthorisedResult = isAuthorised;
      } else {
        if (isAuthorised === undefined || isAuthorised === null) {
          isAuthorised = loginRequired;
        }

        isAuthorisedResult = isAuthorised({
          userData,
          permissions,
        });
      }

      return isAuthorisedResult;
    }).sort((a, b) => {
      const aOrder = a.navigation?.order;
      const bOrder = b.navigation?.order;
      if (aOrder === undefined) {
        if (bOrder === undefined) {
          return a.id.localeCompare(b.id);
        }

        return 1;
      } else if (bOrder === undefined) {
        return -1;
      }

      return aOrder - bOrder;
    });
    const groups = [];
    for (const route of filteredRoutes) {
      if (!route.navigation) {
        continue;
      }

      if (isInternal) {
        if (route.navigation.internal !== true) {
          continue;
        }
      } else if (route.navigation.default !== true) {
        continue;
      }

      if (!route.navigation.group) {
        groups.push({
          id: route.id,
          items: [route],
        });

        continue;
      }

      let group = groups.find((group) => group.id === route.navigation.group);
      if (!group) {
        group = {
          id: route.navigation.group,
          items: [],
        };

        groups.push(group);
      }

      group.items.push(route);
    }

    return groups;
  }, [isInternal, userData, permissions]);

  return (
    <header className="header sticky-top">
      <div className={classes.announcement}>
        <strong>New Kwalee Publishing Portal - Coming Soon 2024</strong>
        <br />
        Your one-stop shop for submitting, testing, and analysing your game’s
        performance with real players.
      </div>
      <div className="header-top">
        <div className="container">
          <div className="navbar-header">
            <Logo
              betaTag={ENV !== "production"}
              to="/"
              live={logo}
              dev={logo}
            />
          </div>
          <div className="header-midheading">
            <h1 className="navbar-title">{headerTitle}</h1>
          </div>
          <div className="header-right">
            <div className="nav-actions">
              {!isInternal && (
                <Tooltip
                  title={t(`header.navigation.route.${ROUTE_WALLET.id}.title`)}
                >
                  <Link className="d-inline-block" to={ROUTE_WALLET.path}>
                    <img className="h-100 mr-2" src="img/money.svg" alt="" />
                    <span className="text-dark">{balances.coin}</span>
                  </Link>
                </Tooltip>
              )}

              {isInternal && (
                <Tooltip title="Bugs/Requests">
                  <a
                    className="circled"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://forms.monday.com/forms/786ebcf2088f4b4b9828ce6f1c2ed822?r=use1"
                  >
                    <Monday width={18} height={null} />
                  </a>
                </Tooltip>
              )}

              {isInternal && (
                <Tooltip title={t("search")}>
                  <Link className="circled" to="/admin/search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </Link>
                </Tooltip>
              )}

              {/*{isInternal && (
                <Tooltip title="Quality Assurance">
                  <Link className="circled" to="/admin/quality-assurance">
                    <i className="fa fa-check-square-o" aria-hidden="true" />
                  </Link>
                </Tooltip>
              )}*/}

              <Tooltip
                title={t(
                  `header.navigation.route.${ROUTES_ORGANISATION[0].id}.title`
                )}
              >
                <Link className="circled" to={ROUTES_ORGANISATION[0].path}>
                  <i className="fa fa-building" aria-hidden="true" />
                  {!isInternal && (
                    <span className="account__tier">
                      <img
                        src={
                          balances.tier === 3
                            ? goldBadge
                            : balances.tier === 2
                            ? silverBadge
                            : bronzeBadge
                        }
                        alt=""
                      />
                    </span>
                  )}
                </Link>
              </Tooltip>

              <Tooltip
                title={t(`header.navigation.route.${ROUTES_USER[0].id}.title`)}
              >
                <Link className="circled" to={ROUTES_USER[0].path}>
                  <i className="fa fa-user" aria-hidden="true" />
                </Link>
              </Tooltip>

              <Tooltip title={t("header.signOut")}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="circled cursor-pointer" onClick={logout}>
                  <i className="fa fa-sign-out" aria-hidden="true" />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarfirst"
              aria-controls="navbarfirst"
              aria-expanded="false"
              aria-label={t("header.navigation.toggle")}
            >
              <span className="sr-only">{t("header.navigation.toggle")}</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <div
              className="navbar-collapse collapse"
              id="navbarfirst"
              style={{
                whiteSpace: "nowrap",
                overflow: "auto",
                padding: "10px 0",
              }}
            >
              <ul className="navbar-nav navigation">
                {navItemGroups.map((group, index) => (
                  <NavItem key={group.id} index={index} group={group} />
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export const NavItem = ({ group, index }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const anchorRef = useRef();
  const [_isActive, setActive] = useState(false);
  const isActive = useDebounce(_isActive, 0);

  const onOpen = () => {
    setActive(true);
  };

  const onClose = () => {
    setActive(false);
  };

  if (group.items.length === 1) {
    return group.items.map((route) =>
      !!route.NavigationRender ? (
        <React.Fragment key={route.path}>
          {route.NavigationRender()}
        </React.Fragment>
      ) : (
        <li
          key={group.id}
          className={cx("nav-item", {
            "nav-item-superuser": route.isAuthorised === superUserRequired,
          })}
        >
          <Tooltip title={t(`header.navigation.route.${route.id}.description`)}>
            <Link to={route.path} className="nav-link">
              {t(`header.navigation.route.${route.id}.navigation.title`)}
            </Link>
          </Tooltip>
        </li>
      )
    );
  }

  return (
    <li key={index}>
      <label
        id="button"
        ref={anchorRef}
        aria-haspopup="true"
        className={`${classes.navigation} nav-item`}
        onClick={onOpen}
        onMouseOver={onOpen}
        onMouseLeave={onClose}
      >
        {t(`header.navigation.route.group.${group.id}.navigation.title`)}
        <ArrowDropDownOutlined
          style={{
            transform: isActive ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "transform .5s",
          }}
        />
      </label>
      <Menu
        id={"mouse-over-popover" + index}
        open={isActive}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={onClose}
        MenuListProps={{
          onMouseEnter: onOpen,
          onMouseLeave: onClose,
          style: {
            pointerEvents: "auto",
            padding: isMobile && "0 10px",
          },
        }}
        PopoverClasses={{
          root: classes.popOverRoot,
        }}
      >
        {group.items.map((subRoute, index) => (
          <span key={index}>
            <Tooltip
              title={t(`header.navigation.route.${subRoute.id}.description`)}
            >
              <Link
                to={subRoute.path}
                className={`${classes.navigation} nav-link`}
              >
                {t(`header.navigation.route.${subRoute.id}.navigation.title`)}
              </Link>
            </Tooltip>
          </span>
        ))}
      </Menu>
    </li>
  );
};

export default connect(null)(Header);
