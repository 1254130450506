import React from "react";
import { Redirect } from "react-router-dom";
import SubmitGamePopover from "../../components/common/header/SubmitGamePopover";
import { loginRequired } from "../authorisations";

const ROUTES_SUBMIT_GAME = [
  {
    id: "submitGame",

    path: "/submit-game",
    exact: true,
    isAuthorised: loginRequired,

    hasHeading: false,

    navigation: {
      default: true,
      order: 0,
    },

    NavigationRender: () => <SubmitGamePopover />,

    Component: () => <Redirect from="/submit-game" to="/submit-game/info" />,

    children: [
      {
        id: "info",

        path: "/submit-game/info",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/GameName")
        ),
      },
      {
        id: "testType",

        path: "/submit-game/select-type/:projectId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/SelectTestType")
        ),
      },
      {
        id: "ctr",

        path: "/submit-game/ctr-test/:projectId/:testId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/CtrTest")
        ),
      },
      {
        id: "sdkIntegrated",

        path: "/submit-game/sdk/:projectId/:testId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/SDKIntegrated")
        ),
      },
      {
        id: "sdkKwalee",

        path: "/submit-game/sdk/kwalee/:projectId/:testId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/RetentionSDK")
        ),
      },
      {
        id: "sdkFacebook",

        path: "/submit-game/sdk/facebook/:projectId/:testId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/FacebookIntegration")
        ),
      },
      {
        id: "storeUpdate",

        path: "/submit-game/store/update/:projectId/:testId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/RetentionStoreUpdated")
        ),
      },
      {
        id: "store",

        path: "/submit-game/store/:projectId/:testId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/AppStore")
        ),
      },
      {
        id: "video",

        path: "/submit-game/video/:projectId/:testId",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/VideoUpload")
        ),
      },
      {
        id: "completeSubmission",

        path: "/submit-game/thank-you",
        exact: true,
        isAuthorised: loginRequired,

        Component: React.lazy(() =>
          import("../../components/game/submission/ThankYou")
        ),
      },
    ],
  },
];

export default ROUTES_SUBMIT_GAME;
