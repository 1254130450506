import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

const UTM_KEYS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

const UTM_STORAGE_KEY = "utmTracking";

export const getSessionStorageUTM = () => {
  let json = undefined;

  const data = sessionStorage.getItem(UTM_STORAGE_KEY);
  if (data) {
    try {
      json = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }

  return json;
};

export const setSessionStorageUTM = (data) => {
  if (data) {
    sessionStorage.setItem(UTM_STORAGE_KEY, JSON.stringify(data));
  } else {
    sessionStorage.removeItem(UTM_STORAGE_KEY);
  }

  // Informs current tab of storage update
  window.dispatchEvent(new Event("storage"));
};

const UTMTrackerContext = createContext(undefined);

export function UTMTrackerProvider({ children }) {
  const [utmData, setUtmData] = useState(getSessionStorageUTM());
  const location = useLocation();

  useEffect(() => {
    const onStorageChange = () => {
      setUtmData(getSessionStorageUTM());
    };

    // Load initial data
    onStorageChange();

    // Register event
    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = location.search;
  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    const relativeKeys = Object.fromEntries(
      Array.from(queryParams.entries())
        // Map key to lowercase
        .map(([key, value]) => [key.toLowerCase(), value])
        // Check if associated key
        .filter(([key]) => UTM_KEYS.includes(key))
    );

    if (Object.keys(relativeKeys).length > 0) {
      setSessionStorageUTM(relativeKeys);
    }
  }, [search]);

  return (
    <UTMTrackerContext.Provider value={utmData}>
      {children}
    </UTMTrackerContext.Provider>
  );
}

export default function useUTMData() {
  return useContext(UTMTrackerContext);
}
