import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  lighten,
  makeStyles,
  useTheme,
  Zoom,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import FeedbackIcon from "@material-ui/icons/Feedback";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import cx from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setRelevantFeedbackInfo } from "../../actions/generic";
import { enqueueSnackbar } from "../../actions/notifier";
import { submitFeedback } from "../../data/service/public/public.service";
import useUserData from "../../UserDataContext";
import { Message } from "./Message";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 3,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  messageBoxWrapper: {
    display: "flex",
    flexDirection: "column-reverse",
    height: 400,
    overflowY: "auto",
  },
  messageBox: {
    backgroundColor: lighten(theme.palette.kwaleeYellow, 0.4),
    height: 400,
    border: `1px solid ${lighten(theme.palette.kwaleeYellow, 0.2)}`,
    borderRadius: 4,
    marginBottom: 10,
    paddingBottom: 10,
    overflow: "auto",
  },
  message: {
    padding: 4,
    backgroundColor: "lightgrey",
    margin: 10,
    borderRadius: 4,
  },
  typingIcon: {
    animation: `$blinker 1s ease-in-out infinite`,
  },
  "@keyframes blinker": {
    "0%": {
      opacity: 0.5,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.5,
    },
  },
}));

export function TrackFeedbackInfo() {
  const { projectId, testId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setRelevantFeedbackInfo({
        project_id: projectId,
        project_test_id: testId,
      })
    );

    return () => {
      dispatch(setRelevantFeedbackInfo({}));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, testId]);

  return null;
}

const MAX_CHAR_COUNT = 1000;

export default function Feedback() {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const userData = useUserData();

  const feedbackInfo = useSelector((state) => state.relevantFeedbackInfo);
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");

  const [isOpen, setOpen] = useState(false);

  // Message types - own, users
  const initialMessage = {
    type: "own",
    message: t("page.feedback.message.initial"),
    time: moment().valueOf(),
  };

  const [messages, setMessages] = useState([initialMessage]);
  const [isTyping, setIsTyping] = useState(false);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const displayIsTyping = useCallback(async () => {
    await setTimeout(() => setIsTyping(false), 2000);
    scrollMessageBox();
  }, []);

  const scrollMessageBox = () => {
    const messageBox = document.getElementById("message-box-wrapper");
    messageBox.scrollTop = messageBox.scrollHeight;
  };

  const onSubmit = useCallback(
    async (message) => {
      try {
        await submitFeedback(
          message,
          userData && !email.trim() ? feedbackInfo : { ...feedbackInfo, email }
        );
      } catch (e) {
        console.error(e);

        dispatch(enqueueSnackbar(e.message));
      }
    },
    [dispatch, feedbackInfo, email, userData]
  );

  const addMessage = useCallback(
    async (message, type = "users") => {
      const trimmed = message.trim();
      const isValid = trimmed.length > 0 && message.length <= MAX_CHAR_COUNT;

      if (!isValid) {
        dispatch(enqueueSnackbar(t("error.message.invalid"), "warning"));
      }
      const newMessages = [...messages];
      newMessages.push({ type, message, time: moment().valueOf() });
      setMessages(newMessages);
      setFeedback("");
      scrollMessageBox();

      // Submit feedback
      if (type === "users") {
        onSubmit(message).then(async () => {
          setIsTyping(true);
          await displayIsTyping();

          newMessages.push({
            type: "own",
            message: t("page.feedback.message.response"),
            time: moment().valueOf(),
          });

          setTimeout(() => scrollMessageBox(), 2010);
        });
      }
    },
    [dispatch, messages, displayIsTyping, onSubmit, t]
  );

  useEffect(() => {
    // Listen for search bar focus
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && !event.shiftKey && !!feedback.trim()) {
        setTimeout(() => addMessage(feedback), 10);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [feedback, addMessage]);

  const onClose = () => {
    setOpen(false);
  };

  const trimmed = feedback.trim();
  const isValid = trimmed.length > 0 && feedback.length <= MAX_CHAR_COUNT;

  return (
    <>
      <Zoom
        in={!!userData}
        timeout={transitionDuration}
        mountOnEnter
        unmountOnExit
      >
        <Fab
          color="primary"
          variant="extended"
          aria-label="feedback"
          className={classes.fab}
          onClick={() => setOpen(true)}
        >
          <FeedbackIcon className={classes.extendedIcon} />
          {t("page.feedback.buttonText")}
        </Fab>
      </Zoom>

      <Dialog
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#ffcb05",
          },
        }}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{t("page.feedback.title")}</DialogTitle>
        <DialogContent>
          <div className={classes.messageBoxWrapper}>
            <div className={classes.messageBox} id="message-box-wrapper">
              {messages.map((message, index) => {
                const previousTime = index !== 0 && messages[index - 1].time;
                return (
                  <Message
                    key={index}
                    message={message}
                    timeDifferentFromPrevious={previousTime !== message.time}
                  />
                );
              })}
              {isTyping && (
                <Message
                  isTyping
                  message={{
                    type: "own",
                    message: <MoreHoriz className={classes.typingIcon} />,
                  }}
                />
              )}
            </div>
          </div>

          <div className="form-group">
            <textarea
              disabled={isTyping}
              rows={5}
              className={cx("form-control", {
                invalid: !isValid,
              })}
              placeholder={t("message.enter")}
              value={feedback}
              onChange={(event) => setFeedback(event.target.value)}
            />
            <div className="text-right font-weight-bold">
              {feedback.length}/{MAX_CHAR_COUNT}
            </div>
          </div>
          {!userData && (
            <div className="form-group">
              <label>{t("email")}</label>
              <input
                className="form-control"
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={onClose}
          >
            {t("close")}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary btn-hover-outline"
            onClick={() => addMessage(feedback)}
            disabled={!isValid}
          >
            <KeyboardReturnIcon className={classes.extendedIcon} /> {t("send")}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
