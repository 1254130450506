export const PERMISSION_LICENSE = "licence_api";
export const PERMISSION_LOGOUT = "logout_api";

export const PERMISSION_PERMITTED_TEST_LOG = "permitted_test_log_api";

export const PERMISSION_TASK = "task_api";

export const PERMISSION_ROLE_COLLECTION = "role_collection_api";

export const PERMISSION_WALLET_RESOURCE = "wallet_resource_api";
export const PERMISSION_CURRENCY_COLLECTION = "currency_collection_api";

export const PERMISSION_ORGANISATION = "organisation_api";
export const PERMISSION_ORGANISATION_RESOURCE = "organisation_resource_api";

export const PERMISSION_USER_PROFILE = "user_profile_api";
export const PERMISSION_USER_FEEDBACK = "user_feedback_api";

export const PERMISSION_PROJECT_SHEET = "project_sheet_api";
export const PERMISSION_PROJECT_TEST = "project_test_api";
export const PERMISSION_PROJECT_RESOURCE = "project_resource_api";
export const PERMISSION_PROJECT_COLLECTION = "project_collection_api";
export const PERMISSION_PROJECT_MANAGEMENT = "project_management_api";

export const PERMISSION_PROJECT_TEST_WINDOW = "project_test_window_api";
export const PERMISSION_PROJECT_TEST_SUBMISSION = "project_test_submission_api";

export const PERMISSION_VALIDATE_FB_APPLICATION_ID =
  "validate_fb_application_id_api";
export const PERMISSION_VALIDATE_APP_STORE = "validate_app_store_api";
export const PERMISSION_VALIDATE_VIDEO = "validate_video_api";
export const PERMISSION_VALIDATE_LOGO = "validate_logo_api";
export const PERMISSION_VALIDATE_SDK = "validate_sdk_api";

export const PERMISSION_COACHING = "page_api";

export const PERMISSION_ADMIN_LICENSE = "admin_licence_api";
export const PERMISSION_ADMIN_LICENSE_VERSION = "admin_licence_version_api";

export const PERMISSION_ADMIN_WALLET_RESOURCE = "admin_wallet_resource_api";
export const PERMISSION_ADMIN_CURRENCY = "admin_currency_api";

export const PERMISSION_ADMIN_TASK_TEMPLATE = "admin_task_template_api";

export const PERMISSION_ADMIN_ROLE = "admin_role_api";
export const PERMISSION_ADMIN_ROLE_AUTO_ASSIGN = "admin_auto_assign_role_api";

export const PERMISSION_ADMIN_ORGANISATION = "admin_organisation_api";
export const PERMISSION_ADMIN_ORGANISATION_PROJECT_COLLECTION =
  "admin_organisation_project_collection_api";

export const PERMISSION_ADMIN_ORGANISATION_NOTIFICATION_PREFERENCE =
  "admin_organisation_notification_preference_api";

export const PERMISSION_ADMIN_USER = "admin_user_api";
export const PERMISSION_ADMIN_USER_FEEDBACK = "admin_user_feedback_api";

export const PERMISSION_ADMIN_USER_NOTIFICATION_PREFERENCE =
  "admin_user_notification_preference_api";

export const PERMISSION_ADMIN_PROJECT_TEMPLATE = "admin_project_template_api";
export const PERMISSION_ADMIN_PROJECT_TEST_COST = "admin_project_test_cost_api";
export const PERMISSION_ADMIN_PROJECT_TEST_ACCOUNT =
  "admin_project_test_account_api";
export const PERMISSION_ADMIN_PROJECT_TEST_RESULT_CONFIG =
  "admin_project_test_result_config_api";
export const PERMISSION_ADMIN_PROJECT_TEST_TOOLS =
  "admin_project_test_tools_api";
export const PERMISSION_ADMIN_PROJECT_TEST_WINDOW =
  "admin_project_test_window_api";

export const PERMISSION_ADMIN_PROJECT_TEST_SUBMISSION_TEMPLATE =
  "admin_project_test_submission_template_api";

export const PERMISSION_ADMIN_PROJECT_RESOURCE = "admin_project_resource_api";
export const PERMISSION_ADMIN_PROJECT_SHEET = "admin_project_sheet_api";
export const PERMISSION_ADMIN_PROJECT_TEST = "admin_project_test_api";

export const PERMISSION_ADMIN_TRIGGER_APPROVAL = "trigger_approval";
export const PERMISSION_ADMIN_VALIDATE_SDK = "admin_validate_sdk_api";

export const PERMISSION_ADMIN_INVITE_LINK = "invite_link_api";

export const PERMISSION_ADMIN_MESSAGE_CATEGORY = "admin_message_category_api";
export const PERMISSION_ADMIN_MESSAGE_TEMPLATE = "admin_message_template_api";

export const PERMISSION_ADMIN_LOG = "admin_log_api";

export const PERMISSION_ADMIN_COACHING = "admin_page_api";
export const PERMISSION_ADMIN_COACHING_CATEGORY = "admin_page_category_api";

export const PERMISSION_ADMIN_ARES_ERROR_MAPPING =
  "admin_platform_to_ares_error_mapping_api";

export const PERMISSION_ADMIN_EMAIL_REPORT_API =
  "admin_email_recipient_collection_api";
