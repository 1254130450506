import { MAINTENANCE_STATE, RELEVANT_FEEDBACK_INFO } from "../actions/types";

export function maintenanceState(state = false, action) {
  const { type, payload } = action;

  switch (type) {
    case MAINTENANCE_STATE:
      return payload;
    default:
      return state;
  }
}

export function relevantFeedbackInfo(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case RELEVANT_FEEDBACK_INFO:
      return payload;
    default:
      return state;
  }
}
