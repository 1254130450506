import { Box, makeStyles } from "@material-ui/core";
import cx from "classnames";
import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { enqueueSnackbar } from "../../actions/notifier";
import { submitHybrid } from "../../data/service/public/public.service";
import Logo from "../../media/logo.png";
import useUserData from "../../UserDataContext";
import FileUpload from "./FileUpload";
import GameInfo from "./GameInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h1": {
      margin: `${theme.spacing(2)}px 0 0 0 !important`,
    },
    "& h2": {
      margin: `${theme.spacing(2)}px 0 !important`,
    },
  },
  success: {
    "& a": {
      color: "#000",
    },
  },
}));

export default function HybridSubmission() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const [isSubmitting, setSubmitting] = useState(false);

  const queryParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  );

  const hs_deal_id = queryParams.get("id") ?? null;
  const stage = hs_deal_id === null ? "user-game-info" : "file-upload";

  const setHsDealId = useCallback(
    (hs_deal_id) => {
      const params = new URLSearchParams(history.location.search);
      params.set("id", hs_deal_id);

      history.replace({
        ...history.location,
        search: params.toString(),
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.location.search]
  );

  const { auth_token } = useUserData() ?? {};

  const [contact, setContact] = useState({
    email: "",
    firstname: "",
    lastname: "",
    company_name: "",
  });

  const [game, setGame] = useState({
    project_name: "",
    app_store_link: "",
    google_play_link: "",
  });

  const [files, setFiles] = useState([]);

  const onSubmit = async () => {
    setSubmitting(true);

    const submissionData = {
      project_info: game,
    };

    if (!!auth_token) {
      submissionData.auth_token = auth_token;
    } else {
      const { company_name, ...rest } = contact;
      submissionData.contact_info = rest;
      submissionData.company_name = company_name;
    }

    let tracking_data = {
      source_page: window.location.href.split("?")[0],
    };

    if (queryParams.get("utm_source"))
      tracking_data.utm_source = queryParams.get("utm_source");

    if (queryParams.get("utm_medium"))
      tracking_data.utm_medium = queryParams.get("utm_medium");

    if (queryParams.get("utm_campaign"))
      tracking_data.utm_campaign = queryParams.get("utm_campaign");

    if (queryParams.get("utm_content"))
      tracking_data.utm_content = queryParams.get("utm_content");

    if (queryParams.get("utm_term"))
      tracking_data.utm_term = queryParams.get("utm_term");

    if (queryParams.get("invite_link"))
      tracking_data.invite_link = queryParams.get("invite_link");

    if (Object.keys(tracking_data).length > 0)
      submissionData.tracking_data = tracking_data;

    try {
      // Create submission
      const { hs_deal_id } = await submitHybrid(submissionData);

      // Complete
      setHsDealId(hs_deal_id);
    } catch (error) {
      dispatch(
        enqueueSnackbar(
          i18next.t(error.loc_key, {
            ...error.loc_kw,
            ns: "server",
          }),
          "error"
        )
      );
    }

    setSubmitting(false);
  };

  return (
    <div className={cx("full-page", classes.root)}>
      <Box className="full-page-section" flexDirection="column">
        <Box textAlign="center" padding={2}>
          <img src={Logo} alt="logo" />
          <h1>Hybrid Game Submission</h1>
        </Box>

        <Box padding={2} maxWidth="500px">
          {stage === "user-game-info" && (
            <GameInfo
              contact={contact}
              setContact={setContact}
              game={game}
              setGame={setGame}
              isSubmitting={isSubmitting}
              onNext={onSubmit}
            />
          )}

          {stage === "file-upload" && (
            <FileUpload
              files={files}
              setFiles={setFiles}
              hs_deal_id={hs_deal_id}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}
