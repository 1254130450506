import "bootstrap/dist/js/bootstrap.bundle.min";
import $ from "jquery";
import mixpanel from "mixpanel-browser";
import moment from "moment";
// noinspection ES6UnusedImports
import Popper from "popper.js"; // eslint-disable-line no-unused-vars
import React from "react";
import ReactDOM from "react-dom";
import "react-phone-input-2/lib/style.css";
import "@fontsource/unbounded/500.css";
import "@fontsource/unbounded/600.css";

// Initialise analytics
import "./analytics";
import App from "./App";
// Initialise data handlers
import "./data/index";
// Initialise localisation
import "./locale";
import { updateMomentLocale } from "./locale";
//import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./style/main.scss";

window.jQuery = $;

updateMomentLocale();

moment.relativeTimeThreshold("ss", 1);

mixpanel.init("022941c6bb996d899bd7be9b29bb7536", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

/*function sendToGoogleAnalytics({ name, delta, value, id }) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  window.gtag("event", name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  });
}

reportWebVitals(sendToGoogleAnalytics);*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
