import { push } from "connected-react-router";
import store from "../../_helpers/store";
import { MAINTENANCE_STATE } from "../../actions/types";
import {
  getLocalStorageUserData,
  setLocalStorageUserData,
} from "../../UserDataContext";
import { axiosConfig, isValidURL } from "../axiosConfig";

function interceptMaintenance(err) {
  if (isValidURL(err.config) && err.response?.status === 503) {
    if (getLocalStorageUserData()) {
      setLocalStorageUserData(undefined);

      store.dispatch(push("/"));
    }

    store.dispatch({
      type: MAINTENANCE_STATE,
      payload: true,
    });
  }

  throw err;
}

axiosConfig.interceptors.response.use(
  (response) => response,
  interceptMaintenance
);
