import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideConfirmbox } from "../../actions/confirmbox";

function ConfirmBox() {
  const confirmbox = useSelector((state) => state.confirmbox);
  const {
    title,
    content,
    icon: iconParam,
    open,
    onConfirm,
    confirmText,
    cancelText,
  } = confirmbox;
  const icon = `${iconParam} confirmbox-icon`;
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideConfirmbox());
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="confirmbox-container">
        <DialogTitle className="confirmbox-title-container">
          <i className={icon}></i>
          <div className="confirmbox-title-text">{title}</div>
        </DialogTitle>
        <DialogContent>
          <div className="confirmbox-content">{content}</div>
        </DialogContent>
        <div className="confirmbox-buttons">
          {confirmText && (
            <button
              className="confirmbox-btn confirmbox-btn-success"
              onClick={onConfirm}
            >
              {confirmText}
            </button>
          )}
          {cancelText && (
            <button
              className="confirmbox-btn confirmbox-btn-cancel"
              onClick={onClose}
            >
              {cancelText}
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmBox;
