import MomentUtils from "@date-io/moment";
import {
  MuiThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import breakpoints from "./options/breakpoints";

// TODO Waiting on material-ui to update their StrictMode issues (v5 should fix it but in alpha)
const theme = createMuiTheme({
  breakpoints: { values: { ...breakpoints.values } },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
  },
  palette: {
    kwaleeYellow: "#ffcb05",
  },
  overrides: {
    MuiInput: {
      root: {
        backgroundColor: "white",
        borderRadius: 5,
      },
      input: {
        height: "calc(1.5em + 1.5rem) !important",
        padding: "0 20px",

        fontSize: 18,

        display: "flex",
        alignItems: "center",

        "&:focus": {
          borderRadius: "5px !important",
          backgroundColor: "transparent !important",
          boxShadow: "0 2px 4px rgb(0 0 0 / 42%)",
        },

        [breakpoints.down("xs")]: {
          height: "45px !important",
          fontSize: 15,
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          fontWeight: "bold",
        },
      },
    },
  },
});

export const MuiProviders = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {children}
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);
