import React from "react";
import { PERMISSION_ACCESS_READ } from "../../permissions/permissionAccessLevels";
import { PERMISSION_COACHING } from "../../permissions/permissions";
import { permissionRequired } from "../authorisations";

const COACHING_AUTH = permissionRequired(
  PERMISSION_COACHING,
  PERMISSION_ACCESS_READ
);

const ROUTES_COACHING = [
  {
    id: "coaching",

    path: "/coaching",
    exact: true,
    isAuthorised: COACHING_AUTH,

    navigation: {
      internal: true,
      default: true,
      order: 17,
    },

    Component: React.lazy(() => import("../../components/coaching/Coaching")),

    children: [
      {
        id: "topic",

        path: "/coaching/:topic",
        exact: true,
        isAuthorised: COACHING_AUTH,

        hasHeading: false,

        Component: React.lazy(() =>
          import("../../components/coaching/CoachingDetails")
        ),

        children: [
          {
            id: "post",

            path: "/coaching/:topic/:post",
            exact: true,
            isAuthorised: COACHING_AUTH,

            hasHeading: false,

            Component: React.lazy(() =>
              import("../../components/coaching/CoachingBlog")
            ),
          },
          {
            id: "post-edit",

            path: "/coaching/:topic/:post/edit",
            exact: true,
            isAuthorised: COACHING_AUTH,

            hasHeading: false,

            Component: React.lazy(() =>
              import("../../components/coaching/CoachingBlog")
            ),
          },
        ],
      },
    ],
  },
];

export default ROUTES_COACHING;
