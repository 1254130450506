import { push } from "connected-react-router";
import i18next from "i18next";
import mixpanel from "mixpanel-browser";
import React from "react";
import { Link } from "react-router-dom";
import endPoints from "../_helpers/endPoints.json";
import Trans from "../components/common/Trans";
import { userService } from "../service";
import {
  getLocalStorageUserData,
  setLocalStorageUserData,
} from "../UserDataContext";
import sha256 from "../util/sha256";
import { hideConfirmbox, showConfirmbox } from "./confirmbox";
import { enqueueSnackbar } from "./notifier";
import {
  LOGIN_SUCCESS,
  LOST_PASSWORD_EMAIL_FAILURE,
  LOST_PASSWORD_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  VALIDATE_EMAIL_SUCCESS,
} from "./types";

// Call one of available functions

export const userActions = {
  login,
  register,
  recoverAccount,
  logout,
  createPassword,
  addCompanyInfo,
  lostPassword,
};

//-- Login function
function login(apiName, data, redirect) {
  return (dispatch) => {
    userService.login(apiName, data).then(
      (response) => {
        setLocalStorageUserData(response.data.data);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data.data,
        });

        sha256(`user_${data.email}`).then((hash) => {
          if (window.dataLayer) {
            window.dataLayer.push({
              event: "login",
              method: "Portal",
              user_id: hash,
            });
          }

          mixpanel.identify(hash);

          mixpanel.track("Login");
        });

        if (redirect) {
          dispatch(
            push(
              response.data?.data?.is_signup_completed
                ? redirect ?? "/"
                : "/organisation/info"
            )
          );
        }
      },
      (error) => {
        if (error.data) {
          alert(
            i18next.t(error.data.loc_key, {
              ...error.data.loc_kw,
              ns: "server",
            })
          );
        } else if (error?.response?.data?.loc_key) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.response.data.loc_key, {
                ...error.response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        } else {
          dispatch(enqueueSnackbar(i18next.t("error.unknown"), "warning"));
        }
      }
    );
  };
}

/**
 * * This api is for registering the user
 * * It will send an verfication email to the submitted user email
 * @param {*} apiName contains the api name
 * @param {*} data contains the api data
 */
function register(apiName, data) {
  return (dispatch) => {
    userService.register(apiName, data).then(
      (response) => {
        if (response.data.data) {
          dispatch({
            type: VALIDATE_EMAIL_SUCCESS,
            payload: response.data.data.is_email_validated,
          });

          if (window.dataLayer) {
            sha256(`user_${data.email}`).then((hash) => {
              window.dataLayer.push({
                event: "email_verify",
                user_id: hash,
              });
            });
          }
        }
        if (response.data.is_valid) {
          if (response.data.data.auth_token) {
            /*dispatch(
              enqueueSnackbar(
                i18next.t(response.data.loc_key, {
                  ...response.data.loc_kw,
                  ns: "server",
                }),
                "success"
              )
            );*/

            sha256(`user_${data.email}`).then((hash) => {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: "sign_up",
                  method: "Portal",
                  user_id: hash,
                });
              }

              mixpanel.identify(hash);

              mixpanel.track("Sign Up");
            });
            dispatch(push("/check-email"));
          }
        } else {
          dispatch(
            enqueueSnackbar(
              i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      },
      (error) => {
        if (error) {
          dispatch({
            type: VALIDATE_EMAIL_FAILURE,
            payload: error.response.status,
          });
        }

        if (error?.response) {
          if (error.response.data?.loc_key) {
            dispatch(
              enqueueSnackbar(
                i18next.t(error.response.data.loc_key, {
                  ...error.response.data.loc_kw,
                  ns: "server",
                }),
                "warning"
              )
            );
          } else if (error.response.status === 409) {
            dispatch(
              enqueueSnackbar(
                <Trans
                  i18nKey="error.email.inUse"
                  components={{
                    lostPassword: (
                      <Link
                        to="/lost-password"
                        style={{ display: "contents" }}
                      />
                    ),
                  }}
                />,
                "warning"
              )
            );
          }
        }
      }
    );
  };
}

function recoverAccount(apiName, data) {
  return (dispatch) => {
    userService.recoverAccount(apiName, data).then(
      (response) => {
        if (response.data.is_valid) {
          // notifier.success(response.data.message, nextCallOptions)
          dispatch(
            showConfirmbox({
              title: i18next.t("successfullySent"),
              content: i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              confirmText: i18next.t("continue"),
              icon: "fa fa-check-circle text-success",
              onConfirm: () => {
                dispatch(hideConfirmbox());
                dispatch(push("/"));
              },
            })
          );
        } else {
          dispatch(
            enqueueSnackbar(
              i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      },
      (error) => {
        if (error.data) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.data.loc_key, {
                ...error.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        } else if (error?.response?.data?.loc_key) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.response.data.loc_key, {
                ...error.response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      }
    );
  };
}

//-- addCompanyInfo
function addCompanyInfo(apiName, data) {
  return (dispatch) => {
    userService.addCompanyInfo(apiName, data).then(
      (response) => {
        if (response.data.is_valid) {
          const userData = getLocalStorageUserData();
          userData.is_signup_completed = true;
          setLocalStorageUserData(userData);
          dispatch(
            enqueueSnackbar(
              i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              "success"
            )
          );

          dispatch(push(userData.is_organisation_owner ? "/welcome" : "/"));
        } else {
          dispatch(
            enqueueSnackbar(
              i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      },
      (error) => {
        if (error.data) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.data.loc_key, {
                ...error.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        } else if (error?.response?.data?.loc_key) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.response.data.loc_key, {
                ...error.response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      }
    );
  };
}

//-- logout
function logout(apiName = {}) {
  return (dispatch) =>
    userService
      .logout(apiName)
      .then((response) => {
        dispatch(
          enqueueSnackbar(
            i18next.t(response.data.loc_key, {
              ...response.data.loc_kw,
              ns: "server",
            }),
            "success"
          )
        );
      })
      .catch(() => {})
      .finally(() => {
        setLocalStorageUserData(undefined);
        mixpanel.reset();
      });
}

function createPassword(apiName, data) {
  return (dispatch) => {
    setLocalStorageUserData(undefined);

    userService.createPassword(apiName, data).then(
      (response) => {
        if (response.data.is_valid) {
          if (apiName === endPoints.EmailVerify) {
            window.dataLayer.push({
              event: "verify_password",
              invited: false,
            });

            if (window.gtag) {
              window.gtag("event", "conversion", {
                send_to: "AW-318137117/FRCRCMPDsOkCEJ3G2ZcB",
              });
            }

            window.lintrk("track", { conversion_id: 4658650 });
          }

          const email = response?.data?.data?.email;
          const password = data?.password;
          const cred = { email, password };

          setTimeout(function () {
            if (email) {
              dispatch(userActions.login(endPoints.Login, cred, "/"));
            } else {
              dispatch(push("/"));
            }
          }, 3000);

          dispatch(
            enqueueSnackbar(
              i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              "success"
            )
          );
        } else {
          dispatch(
            enqueueSnackbar(
              i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      },
      (error) => {
        if (error.data) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.data.loc_key, {
                ...error.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        } else if (error?.response?.data?.loc_key) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.response.data.loc_key, {
                ...error.response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      }
    );
  };
}

function lostPassword(apiName, data) {
  return (dispatch) => {
    userService.lostPassword(apiName, data).then(
      (response) => {
        if (response.data.data?.is_email_validated !== undefined) {
          dispatch({
            type: LOST_PASSWORD_EMAIL_SUCCESS,
            payload: response.data.data.is_email_validated,
          });
        } else {
          dispatch(
            enqueueSnackbar(
              i18next.t(response.data.loc_key, {
                ...response.data.loc_kw,
                ns: "server",
              }),
              "success"
            )
          );
        }
      },
      (error) => {
        if (error.response.status === 404) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.response.data.loc_key, {
                ...error.response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
        if (error) {
          dispatch({
            type: LOST_PASSWORD_EMAIL_FAILURE,
            payload: error.response.status,
          });
        } else if (error?.response?.data?.loc_key) {
          dispatch(
            enqueueSnackbar(
              i18next.t(error.response.data.loc_key, {
                ...error.response.data.loc_kw,
                ns: "server",
              }),
              "warning"
            )
          );
        }
      }
    );
  };
}
