import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import endPoints from "../../_helpers/endPoints.json";
import { getUserData } from "../../actions/accounts";
import useUserData from "../../UserDataContext";

export default function GameInfo({
  contact,
  setContact,
  game,
  setGame,
  isSubmitting,
  onNext,
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { auth_token } = useUserData() ?? {};

  useEffect(() => {
    dispatch(getUserData(endPoints.GetAccount));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth_token]);

  const { data: userData } = useSelector((state) => state.userData);

  useEffect(() => {
    if (!!userData) {
      setContact({
        email: userData.email,
        firstname: userData.first_name,
        lastname: userData.last_name,
        company_name: userData.organisation_name,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const [isAcceptPrivacyPolicy, setPrivacyPolicy] = useState(false);

  const onContactChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const onGameChange = (e) => {
    setGame({ ...game, [e.target.name]: e.target.value });
  };

  const isDisabled = !(
    (userData ||
      (contact.email &&
        contact.firstname &&
        contact.lastname &&
        contact.company_name)) &&
    game.project_name &&
    (game.app_store_link || game.google_play_link) &&
    isAcceptPrivacyPolicy
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onNext();
      }}
    >
      <Grid container spacing={2}>
        {!userData && (
          <>
            <Grid item xs={12}>
              <input
                required
                type="email"
                name="email"
                placeholder="Email"
                className="form-control"
                value={contact.email}
                onChange={onContactChange}
                disabled={isSubmitting || !!userData}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <input
                required
                type="text"
                name="firstname"
                placeholder="First Name"
                className="form-control"
                value={contact.firstname}
                onChange={onContactChange}
                pattern="[a-zA-Z]+"
                title="Please enter alphabets only."
                disabled={isSubmitting || !!userData}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <input
                required
                type="text"
                name="lastname"
                placeholder="Last Name"
                className="form-control"
                value={contact.lastname}
                onChange={onContactChange}
                pattern="[a-zA-Z]+"
                title="Please enter alphabets only."
                disabled={isSubmitting || !!userData}
              />
            </Grid>

            <Grid item xs={12}>
              <input
                required
                type="text"
                name="company_name"
                placeholder="Company Name"
                className="form-control"
                value={contact.company_name}
                onChange={onContactChange}
                disabled={isSubmitting || !!userData}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <input
            required
            disabled={isSubmitting}
            type="text"
            name="project_name"
            placeholder="Project Name"
            className="form-control"
            value={game.project_name}
            onChange={onGameChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            required={!game.google_play_link}
            disabled={isSubmitting}
            type="url"
            name="app_store_link"
            placeholder="iOS Store Link"
            className="form-control"
            pattern="https?://.+"
            value={game.app_store_link}
            onChange={onGameChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            required={!game.app_store_link}
            disabled={isSubmitting}
            type="url"
            name="google_play_link"
            placeholder="Google Play Link"
            className="form-control"
            pattern="https?://.+"
            value={game.google_play_link}
            onChange={onGameChange}
          />
        </Grid>

        <Grid item xs={12}>
          <input
            required
            className="form-check-input ml-auto"
            type="checkbox"
            checked={isAcceptPrivacyPolicy}
            onChange={() => setPrivacyPolicy(!isAcceptPrivacyPolicy)}
          />
          <label className="form-check-label pl-4">
            I agree with Kwalee’s{" "}
            <a
              href={"https://www.kwalee.com/privacy-policy/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and consent to them storing my name and email so they can send their
            marketing emails to me. I also acknowledge that I can unsubscribe
            any time to stop receiving them.
          </label>
        </Grid>

        <Grid item xs={12}>
          <button
            type="submit"
            disabled={isSubmitting || isDisabled}
            className="btn btn-secondary btn-hover-outline w-100"
          >
            {t("submit")} <i className="fa fa-arrow-right" aria-hidden="true" />
          </button>
        </Grid>
      </Grid>
    </form>
  );
}
