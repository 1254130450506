import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Header from "./components/common/header/header";

export default function LoadingSuspense({ children }) {
  return <Suspense fallback={<Placeholder />}>{children}</Suspense>;
}

export function Placeholder() {
  const { t } = useTranslation();

  return (
    <div id="wrapper">
      <Header />

      <section className="full-page-headed mb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12">
              <div className="loader_holder text-center">
                <h2>{t("loading")}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
