import axios from "axios";
import { push } from "connected-react-router";
import i18next from "i18next";
import endPoints from "../_helpers/endPoints.json";
import { axiosConfig } from "../data/axiosConfig";
import {
  ADMIN_ATTRIBUTIONS,
  ADMIN_PROJECT,
} from "../data/service/admin/admin.endpoints";

import { getErrorMapping as getErrorMappingRequest } from "../data/service/project/project.service";
import isNumber from "../util/isNumber";
import { hideConfirmbox, showConfirmbox } from "./confirmbox";
import { enqueueSnackbar } from "./notifier";
import {
  ADD_FIRST_STEP_FAILURE,
  ADD_FIRST_STEP_SUCCESS,
  ADD_VIDEO_FAILURE,
  ADD_VIDEO_REQUEST,
  ADD_VIDEO_SUCCESS,
  FACEBOOK_VALIDATE_FAILURE,
  FACEBOOK_VALIDATE_REQUEST,
  FACEBOOK_VALIDATE_SUCCESS,
  GET_ADMIN_ATTRIBUTIONS_SUCCESS,
  GET_ADMIN_PROJECT_SHEET_SUCCESS,
  GET_ADMIN_PROJECTS_TESTS_FAILURE,
  GET_ADMIN_PROJECTS_TESTS_ID_FAILURE,
  GET_ADMIN_PROJECTS_TESTS_ID_SUCCESS,
  GET_ADMIN_PROJECTS_TESTS_SUCCESS,
  GET_ADMIN_TRIGGER_APPROVAL_FAILURE,
  GET_ADMIN_TRIGGER_APPROVAL_SUCCESS,
  GET_ADMIN_USERS_SUCCESS,
  GET_DETAILS_PROJECT_SUCCESS,
  GET_ERROR_MAPPING_FAILURE,
  GET_ERROR_MAPPING_SUCCESS,
  GET_LIST_PROJECT_FAILURE,
  GET_LIST_PROJECT_SUCCESS,
  GET_ORGANISATION_FAILURE,
  GET_ORGANISATION_REQUEST,
  GET_ORGANISATION_SUCCESS,
  GET_PROJECT_HEAD_FAILURE,
  GET_PROJECT_HEAD_SUCCESS,
  GET_PROJECT_INFORMATION_FAILURE,
  GET_PROJECT_INFORMATION_SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_RESOURCE_FAILURE,
  GET_PROJECT_RESOURCE_SUCCESS,
  GET_PROJECT_SHEET_FOR_DETAILS_SUCCESS,
  GET_PROJECT_SHEET_SUCCESS,
  GET_TASK_FAILURE,
  GET_TASK_SUCCESS,
  GET_TASK_TEMPLATE_SUCCESS,
  GET_WALLET_DETAILS_FAILURE,
  GET_WALLET_DETAILS_SUCCESS,
  PROJECT_DELETE_SUCCESS,
  PROJECT_SHEET_GET_SUCCESS,
  PROJECT_SHEET_UPDATE_SUCCESS,
  PROJECT_TEST_GET_SUCCESS,
  PROJECT_TESTS_GET_SUCCESS,
  SUBMIT_PROJECT_TEST_FAILURE,
  SUBMIT_PROJECT_TEST_SUCCESS,
  SUBMIT_SDK_BUTTON_FAILURE,
  SUBMIT_SDK_BUTTON_SUCCESS,
  SUBMIT_STEP_FOUR_FAILURE,
  SUBMIT_STEP_FOUR_SUCCESS,
  SUBMIT_STEP_LAST_FAILURE,
  SUBMIT_STEP_LAST_SUCCESS,
  SUBMIT_STEP_ONE_FAILURE,
  SUBMIT_STEP_ONE_SUCCESS,
  SUBMIT_STEP_THREE_FAILURE,
  SUBMIT_STEP_THREE_SUCCESS,
  SUBMIT_STEP_TWO_FAILURE,
  SUBMIT_STEP_TWO_SUCCESS,
  SUBMIT_STORE_BUTTON_FAILURE,
  SUBMIT_STORE_BUTTON_SUCCESS,
  SUBMIT_VIDEO_BUTTON_FAILURE,
  SUBMIT_VIDEO_BUTTON_SUCCESS,
  UPDATE_ADMIN_TRIGGER_APPROVAL_SUCCESS,
  UPDATE_ORGANISATION_FAILURE,
  UPDATE_ORGANISATION_SUCCESS,
  VALIDATE_APP_STORE_FAILURE,
  VALIDATE_APP_STORE_SUCCESS,
  VIDEO_FAILURE,
  VIDEO_SUCCESS,
} from "./types";

export const submitStepOne = (apiName, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.post(apiName, data, {
      headers: {
        "Permission-Code": "project_collection_api",
      },
    });
    if (res.data.is_valid) {
      // window.location.reload();
      dispatch({
        type: SUBMIT_STEP_ONE_SUCCESS,
        payload: res.data,
        isValid: res.data.is_valid,
      });
    } else {
      dispatch({
        type: SUBMIT_STEP_ONE_FAILURE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (err.response.data && err.response.data.is_valid === false) {
      dispatch(
        showConfirmbox({
          title: i18next.t("error.game.inUse"),
          content: i18next.t(err.response.data.loc_key, {
            ...err.response.data.loc_kw,
            ns: "server",
          }),
          confirmText: i18next.t("game.newName"),
          cancelText: null,
          icon: "fa fa-info-circle",
          onConfirm: () => dispatch(hideConfirmbox()),
        })
      );
    }
    dispatch({
      type: SUBMIT_STEP_ONE_FAILURE,
      payload: err.response,
    });
  }
};

export const submitStepTwo = (apiName, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.put(apiName, data, {
      headers: {
        "Permission-Code": "project_collection_api",
      },
    });

    if (res.data.is_valid) {
      window.location.reload();
      dispatch({
        type: SUBMIT_STEP_TWO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: SUBMIT_STEP_TWO_FAILURE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (err.response.data && err.response.data.is_valid === false) {
      dispatch(
        enqueueSnackbar(
          i18next.t(err.response.data.loc_key, {
            ...err.response.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
    }
    dispatch({
      type: SUBMIT_STEP_TWO_FAILURE,
      payload: err.response,
    });
  }
};

export const submitStepThree = (apiName, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.post(apiName, data, {
      headers: {
        "Permission-Code": "project_collection_api",
      },
    });
    if (res.data.is_valid) {
      window.location.reload();
      dispatch({
        type: SUBMIT_STEP_THREE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: SUBMIT_STEP_THREE_FAILURE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (err.response.data && err.response.data.is_valid === false) {
      dispatch(
        enqueueSnackbar(
          i18next.t(err.response.data.loc_key, {
            ...err.response.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
    }
    dispatch({
      type: SUBMIT_STEP_THREE_FAILURE,
      payload: err.response,
    });
  }
};

export const submitStepFour = (apiName, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.put(apiName, data, {
      headers: {
        "Permission-Code": "project_collection_api",
      },
    });

    if (res.data.is_valid) {
      dispatch(push("/submit-game/thank-you"));

      dispatch({
        type: SUBMIT_STEP_FOUR_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: SUBMIT_STEP_FOUR_FAILURE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (err.response.data && err.response.data.is_valid === false) {
      dispatch(
        enqueueSnackbar(
          i18next.t(err.response.data.loc_key, {
            ...err.response.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
    }
    dispatch({
      type: SUBMIT_STEP_FOUR_FAILURE,
      payload: err.response,
    });
  }
};

export const lastStep = (apiName) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(apiName, {
      headers: {
        "Permission-Code": "project_collection_api",
      },
    });

    dispatch({
      type: SUBMIT_STEP_LAST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SUBMIT_STEP_LAST_FAILURE,
      payload: err.response,
    });
  }
};

export const submitStep2 = (apiName, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.put(apiName, data, {
      headers: {
        "Permission-Code": "task_api",
      },
    });

    dispatch({
      type: ADD_FIRST_STEP_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_FIRST_STEP_FAILURE,
      payload: err.response.data,
    });
  }
};

export const submitVideoValidateStep = (apiName, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.post(apiName, data, {
      headers: {
        "Permission-Code": "validate_video_api",
      },
    });
    if (res) {
      dispatch({
        type: ADD_VIDEO_REQUEST,
      });
    }
    if (res.data.is_valid) {
      dispatch({
        type: ADD_VIDEO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch(
        enqueueSnackbar(
          i18next.t(res.data.loc_key, {
            ...res.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
      dispatch({
        type: ADD_VIDEO_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
    dispatch({
      type: ADD_VIDEO_FAILURE,
      payload: err.response.data,
    });
  }
};

export const submitVideos = (apiName, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.post(apiName, data);

    dispatch({
      type: VIDEO_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VIDEO_FAILURE,
      payload: err.response.data,
    });
  }
};

export const getProjectList = () => async (dispatch) => {
  try {
    const res1 = await axiosConfig.get("/projects", {
      headers: {
        "Permission-Code": "project_collection_api",
      },
    });

    const res4 = await axiosConfig.get("/tasks", {
      headers: {
        "Permission-Code": "project_collection_api",
      },
    });

    let finalOutput = [];
    if (res1.data?.data) {
      for (let i = 0; i < res1.data.data.length; i += 1) {
        let item1 = { ...res1.data.data[i] };
        let item2 = { tasks: [], tests: [] };
        if (res1.data.data[i].object_id) {
          const res2 = await axiosConfig.get(
            `/projects/${res1.data.data[i].object_id}/sheet`
          );
          if (res2?.data?.data) {
            item2 = { ...res2.data.data, ...item2 };
          }

          try {
            const res3 = await axiosConfig.get(
              `/projects/${res1.data.data[i].object_id}/tests`
            );
            if (res3.data && res3.data.data) {
              item2.tests = res3.data.data;
            }
          } catch (error) {
            console.error(error);
          }
        }

        if (res4 && res4.data && res4.data.data) {
          item2["tasks"] = res4.data.data.filter(
            (item) => item.project_id === item1.object_id
          );
          item2["task_template"] = [];
        }

        finalOutput.push({ ...item2, ...item1 });
      }
    }

    dispatch({
      type: GET_PROJECT_LIST_SUCCESS,
      payload: finalOutput,
    });
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_PROJECT_LIST_FAILURE, payload: err.response });
  }
};

export const getProjectResource = (data) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/projects/${data}`);
    if (res.data) {
      dispatch({
        type: GET_PROJECT_RESOURCE_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar(
        i18next.t(error.response.data.loc_key, {
          ...error.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
    dispatch({
      type: GET_PROJECT_RESOURCE_FAILURE,
      payload: i18next.t(error.response.data.loc_key, {
        ...error.response.data.loc_kw,
        ns: "server",
      }),
    });
  }
};

export const getProjectInformation = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(endPoints.GetProjectInformation + id, {
      headers: {
        "Permission-Code": "project_resource_api",
      },
    });
    if (res.data && res.data.data && !res.data.data.submission_status) {
      dispatch({
        type: GET_PROJECT_INFORMATION_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
    dispatch({
      type: GET_PROJECT_INFORMATION_FAILURE,
      payload: i18next.t(err.response.data.loc_key, {
        ...err.response.data.loc_kw,
        ns: "server",
      }),
    });
  }
};

export const getListProject =
  ({ search, status, platform, page = 1, pageSize, abortController } = {}) =>
  async (dispatch) => {
    try {
      const res = await axiosConfig.get(endPoints.CreateProject, {
        caller: {
          func: getListProject,
        },
        params: {
          name: search,
          tested_platforms: JSON.stringify(platform),
          project_test_flag: status,
          page: isNaN(page) ? undefined : page,
          page_size: pageSize,
        },
        signal: abortController.signal,
      });
      if (res.data) {
        dispatch({
          type: GET_LIST_PROJECT_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      if (err.response?.status === 404) {
        dispatch({
          type: GET_LIST_PROJECT_SUCCESS,
          payload: {
            page: 1,
            page_size: pageSize,
            projects: [],
            total_size: 0,
          },
        });
      } else {
        dispatch({ type: GET_LIST_PROJECT_FAILURE, payload: err.response });
      }
    }
  };

export const getDetailProject = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(endPoints.CreateProject + id);
    if (res.data) {
      dispatch({
        type: GET_DETAILS_PROJECT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const getTask =
  (projectId, testId, tries = 5) =>
  async (dispatch) => {
    tries++;

    try {
      const res = await axiosConfig.get(
        `/projects/${projectId}/tests/${testId}/submission`
      );
      if (res.data) {
        dispatch({
          type: GET_TASK_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      if (tries >= 5) {
        dispatch(
          enqueueSnackbar(
            i18next.t(err.response.data.loc_key, {
              ...err.response.data.loc_kw,
              ns: "server",
            }),
            "warning"
          )
        );
        dispatch({
          type: GET_TASK_FAILURE,
          payload: err.response?.data,
        });
      } else {
        setTimeout(() => dispatch(getTask(projectId, testId, tries)), 1000);
      }
    }
  };

export const updateTask =
  (projectId, testId, pageId, clientId, status) => async (dispatch) => {
    try {
      const res = await axiosConfig.put(
        `/projects/${projectId}/tests/${testId}/submission/pages/${pageId}/items/${clientId}`,
        { status }
      );
      if (res.data) {
        dispatch({
          type: GET_TASK_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar(
          i18next.t(err.response.data.loc_key, {
            ...err.response.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
      dispatch({
        type: GET_TASK_FAILURE,
        payload: err.response?.data,
      });
    }
  };

export const getProjectSheet = () => async (dispatch) => {
  try {
    const res = await axiosConfig.get(endPoints.GameStore);
    if (res.data) {
      dispatch({
        type: GET_PROJECT_SHEET_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const getTaskTemplate = (data) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(
      `${endPoints.TaskTemplate}?task_type=${data}`
    );
    if (res.data) {
      dispatch({
        type: GET_TASK_TEMPLATE_SUCCESS,
        payload: res.data.data,
        is_valid: res.data.is_valid,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const facebookValidate = (projectId, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.post(
      `/projects/${projectId}/validate_fb_application_id`,
      data
    );
    if (res) {
      dispatch({
        type: FACEBOOK_VALIDATE_REQUEST,
      });
    }
    if (res.data) {
      dispatch(
        enqueueSnackbar(
          i18next.t(res.data.loc_key, {
            ...res.data.loc_kw,
            ns: "server",
          }),
          "success"
        )
      );
      dispatch({
        type: FACEBOOK_VALIDATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
    dispatch({
      type: FACEBOOK_VALIDATE_FAILURE,
      payload: err.response.data,
    });
  }
};

export const submitProjectTest = (data1, data2) => async (dispatch) => {
  try {
    const res = await axiosConfig.post(
      `${endPoints.CreateProject}/${data1}/tests`,
      data2
    );
    if (res.data) {
      dispatch({
        type: SUBMIT_PROJECT_TEST_SUCCESS,
        payload: res.data,
        isValid: res.data.is_valid,
      });
    }
    return res?.data;
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
    dispatch({
      type: SUBMIT_PROJECT_TEST_FAILURE,
      payload: i18next.t(err.response.data.loc_key, {
        ...err.response.data.loc_kw,
        ns: "server",
      }),
    });
  }
};

export const getProjectHead = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(
      `${endPoints.CreateProject}/${id}/tests/query`
    );

    if (res) {
      dispatch({
        type: GET_PROJECT_HEAD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROJECT_HEAD_FAILURE,
      payload: error.response?.data,
    });
  }
};

/**
 * For SDK page we need to take response is_valid:true from projectSheetUpdate then we call taskUpdate api.
 */
export const submitSdkButton =
  (data1, data2, id, testId) => async (dispatch) => {
    const requestOne = await axiosConfig.put(`/projects/${id}/sheet`, data1);
    const projectSheet = await axiosConfig.get(`/projects/${id}/sheet`);
    const requestThree = await axiosConfig.put(
      `/projects/${id}/tests/${testId}`,
      {
        asset_ids_by_type: {
          facebook: [
            projectSheet.data.data.assets.find(
              (asset) => asset.asset_type === "facebook"
            ).asset_id,
          ],
        },
      }
    );
    const requestTwo = await axiosConfig.put(
      `/projects/${id}/tests/${testId}/submission/pages/facebook_app_id/items/fb_05`,
      data2
    );

    await axios
      .all([requestOne, requestTwo, requestThree])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const responseThree = responses[1];
          dispatch({
            type: SUBMIT_SDK_BUTTON_SUCCESS,
            res1: responseOne.data.is_valid,
            res2: responseTwo.data,
            res3: responseThree.data.is_valid,
          });
        })
      )
      .catch((errors) => {
        dispatch(
          enqueueSnackbar(
            i18next.t(errors.response.data.loc_key, {
              ...errors.response.data.loc_kw,
              ns: "server",
            }),
            "warning"
          )
        );
        dispatch({
          type: SUBMIT_SDK_BUTTON_FAILURE,
          payload: i18next.t(errors.response.data.loc_key, {
            ...errors.response.data.loc_kw,
            ns: "server",
          }),
        });
      });
  };

export const projectSheetUpdate = (data, id) => async (dispatch) => {
  try {
    const res = await axiosConfig.put(`/projects/${id}/sheet`, data);
    if (res.data) {
      dispatch({
        type: PROJECT_SHEET_UPDATE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: PROJECT_SHEET_GET_SUCCESS,
        payload: {
          ...res.data,
          message: undefined,
        },
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

/**
 * For STORE page we need to take response is_valid:true from projectSheetGet then we call projectSheetUpdate after taking response isValid:true
 * from projectSheetUpdate api we will call taskUpdate.
 */
export const submitStoreButton =
  (data1, data2, id, testId, storeType) => async (dispatch) => {
    // projectSheetGet
    const projectTest = await axiosConfig.get(
      `/projects/${id}/tests/${testId}`
    );
    // projectSheetUpdate
    const requestTwo = await axiosConfig.put(`/projects/${id}/sheet`, data1);
    const requestOne = await axiosConfig.get(`/projects/${id}/sheet`);

    const requestFour = await axiosConfig.put(
      `/projects/${id}/tests/${testId}`,
      {
        ...projectTest.data.data,
        platform: storeType,
        asset_ids_by_type: {
          ...projectTest.data.data.asset_ids_by_type,
          store: [
            requestOne.data.data.assets.find(
              (asset) =>
                asset.asset_type === "store" &&
                isNumber(asset.value) === (storeType === "ios")
            ).asset_id,
          ],
        },
      }
    );

    // taskUpdate
    const requestThree = await axiosConfig.put(
      `/projects/${id}/tests/${testId}/submission/pages/store_url/items/store_05`,
      data2
    );

    await axios
      .all([requestOne, requestTwo, requestThree, requestFour])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const responseThree = responses[2];
          const responseFour = responses[3];
          dispatch({
            type: SUBMIT_STORE_BUTTON_SUCCESS,
            res1: responseOne.data.is_valid,
            res2: responseTwo.data.is_valid,
            res3: responseThree.data,
            res4: responseFour.data.is_valid,
            payload: responseOne.data,
          });
        })
      )
      .catch((errors) => {
        dispatch(
          enqueueSnackbar(
            i18next.t(errors.response.data.loc_key, {
              ...errors.response.data.loc_kw,
              ns: "server",
            }),
            "warning"
          )
        );
        dispatch({
          type: SUBMIT_STORE_BUTTON_FAILURE,
          payload: i18next.t(errors.response.data.loc_key, {
            ...errors.response.data.loc_kw,
            ns: "server",
          }),
        });
      });
  };

/**
 * For Video page we need to take response is_valid:true from projectSheetGet then we call projectSheetUpdate after taking response isValid:true
 * from projectSheetUpdate we will call taskUpdate api.
 */
export const submitVideoButton =
  (sheetData, taskStatus, assetIds, id, testId, pageId) => async (dispatch) => {
    // projectSheetGet
    const projectTest = await axiosConfig.get(
      `/projects/${id}/tests/${testId}`
    );
    const requestTwo = await axiosConfig.put(
      `/projects/${id}/sheet`,
      sheetData
    );
    const requestOne = await axiosConfig.get(`/projects/${id}/sheet`);
    // projectSheetUpdate

    const requestFour = await axiosConfig.put(
      `/projects/${id}/tests/${testId}`,
      {
        ...projectTest.data.data,
        asset_ids_by_type: {
          ...projectTest.data.data.asset_ids_by_type,
          video: assetIds,
        },
      }
    );

    // taskUpdate
    const requestThree = await axiosConfig.put(
      `/projects/${id}/tests/${testId}/submission/pages/${pageId}/items/video_01`,
      taskStatus
    );

    await axios
      .all([requestOne, requestTwo, requestThree, requestFour])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const responseThree = responses[2];
          const responseFour = responses[3];

          window.dataLayer.push({
            event: "submit_game",
          });

          dispatch({
            type: SUBMIT_VIDEO_BUTTON_SUCCESS,
            res1: responseOne.data.is_valid,
            res2: responseTwo.data.is_valid,
            res3: responseThree.data,
            res4: responseFour.data.is_valid,
            payload: responseOne.data,
          });
        })
      )
      .catch((errors) => {
        dispatch(enqueueSnackbar(i18next.t("error.unknown"), "warning"));
        dispatch({
          type: SUBMIT_VIDEO_BUTTON_FAILURE,
          payload: i18next.t(errors.response.data.loc_key, {
            ...errors.response.data.loc_kw,
            ns: "server",
          }),
        });
      });
  };
/**
 * Video Page end
 */

export const validateAppStore = (data, id, name) => async (dispatch) => {
  try {
    const res = await axiosConfig.post(
      `/projects/${id}/validate_app_store`,
      data
    );

    name = name.replaceAll(/[^a-z0-9]+/gi, "").toLowerCase();

    if (
      name ===
      res.data?.data?.game_name.replaceAll(/[^a-z0-9]+/gi, "").toLowerCase()
    ) {
      dispatch({
        type: VALIDATE_APP_STORE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch(
        enqueueSnackbar(i18next.t("error.game.name.appMismatch"), "warning")
      );
      dispatch({
        type: VALIDATE_APP_STORE_FAILURE,
        payload: { is_valid: false },
      });
    }
  } catch (err) {
    console.error(err);

    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
    dispatch({
      type: VALIDATE_APP_STORE_FAILURE,
      payload: err.response.data,
    });
  }
};

export const projectSheetGet = (data) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/projects/${data}/sheet`);
    if (res.data) {
      dispatch({
        type: PROJECT_SHEET_GET_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const getProjectSheetForDetails = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/projects/${id}/sheet`);
    if (res.data) {
      dispatch({
        type: GET_PROJECT_SHEET_FOR_DETAILS_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const projectTestsGet = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/projects/${id}/tests`);
    if (res.data) {
      const tests = res.data.data;

      tests.forEach((test) => processRelativeMetrics(test));

      dispatch({
        type: PROJECT_TESTS_GET_SUCCESS,
        payload: tests,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const projectTestGet = (projectId, testId) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/projects/${projectId}/tests`);
    if (res.data) {
      let test;

      if (!testId) {
        const sortByCreation = res.data.data.sort((a, b) => {
          if (a.created_on < b.created_on) {
            return -1;
          }
          if (a.created_on > b.created_on) {
            return 1;
          }
          return 0;
        });
        test = sortByCreation[0];
      } else test = res.data.data.find((test) => test.object_id === testId);

      processRelativeMetrics(test);

      dispatch({
        type: PROJECT_TEST_GET_SUCCESS,
        payload: test,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const TOTALLED_METRICS = [
  "session_count",
  "total_session_duration_ms",
  "user_count",
];

export function processRelativeMetrics(test) {
  // Combine relative metrics on same day
  const relativeMetrics = new Map();
  for (const metrics of test.relative_metrics) {
    let totals = relativeMetrics.get(metrics.day_since_install);
    if (totals) {
      TOTALLED_METRICS.forEach((key) => (totals[key] += metrics[key]));
    } else {
      relativeMetrics.set(
        metrics.day_since_install,
        (totals = {
          day_since_install: metrics.day_since_install,
        })
      );

      TOTALLED_METRICS.forEach((key) => (totals[key] = metrics[key]));
    }
  }

  test.totalled_relative_metrics = Array.from(relativeMetrics.values()).sort(
    (a, b) => a.day_since_install - b.day_since_install
  );
}

export const deleteGame =
  (id, requestRefund = false) =>
  async (dispatch) => {
    try {
      const res = await axiosConfig.delete(`${endPoints.ListProject}/${id}`, {
        params: {
          project_test_cost_refund: requestRefund,
        },
      });
      if (res.data) {
        dispatch(
          enqueueSnackbar(
            i18next.t(res.data.loc_key, {
              ...res.data.loc_kw,
              ns: "server",
            }),
            "success"
          )
        );
        dispatch({
          type: PROJECT_DELETE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar(
          i18next.t(err.response.data.loc_key, {
            ...err.response.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
    }
  };

export const getAdminTriggerApproval = () => async (dispatch) => {
  try {
    const res = await axiosConfig.get(
      "/admin/trigger_approvals?filter_pending=true"
    );
    if (res.data) {
      dispatch({
        type: GET_ADMIN_TRIGGER_APPROVAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);

    if (err.isAxiosError && err.response?.status === 404) {
      dispatch({
        type: GET_ADMIN_TRIGGER_APPROVAL_SUCCESS,
        payload: {
          data: [],
        },
      });
    } else {
      console.error(err);
      dispatch({
        type: GET_ADMIN_TRIGGER_APPROVAL_FAILURE,
        payload: err.response.status,
      });
    }
  }
};

export const updateAdminTriggerApproval = (id, data) => async (dispatch) => {
  try {
    const res = await axiosConfig.put(`/admin/trigger_approvals/${id}`, data);
    if (res.data) {
      dispatch(
        enqueueSnackbar(
          i18next.t("game.approvalUpdateSuccess", { status: data.status }),
          "success"
        )
      );
      dispatch({
        type: UPDATE_ADMIN_TRIGGER_APPROVAL_SUCCESS,
        payload: i18next.t(res.data.loc_key, {
          ...res.data.loc_kw,
          ns: "server",
        }),
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

// temp
export const getAdminUsers = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/admin/users/${id}`);
    if (res.data) {
      dispatch({
        type: GET_ADMIN_USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const getAdminProjectSheet = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/admin/projects/${id}/sheet`);
    if (res.data) {
      dispatch({
        type: GET_ADMIN_PROJECT_SHEET_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const getOrganisation = () => async (dispatch) => {
  dispatch({
    type: GET_ORGANISATION_REQUEST,
  });

  try {
    const res = await axiosConfig.get("/organisation");
    if (res.data) {
      dispatch({
        type: GET_ORGANISATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error.response?.data?.loc_key) {
      dispatch(
        enqueueSnackbar(
          i18next.t(error.response.data.loc_key, {
            ...error.response.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
    }
    dispatch({
      type: GET_ORGANISATION_FAILURE,
      payload: i18next.t(error.response.data.loc_key, {
        ...error.response.data.loc_kw,
        ns: "server",
      }),
    });
  }
};

export const updateOrganisation = (data) => async (dispatch) => {
  try {
    const res = await axiosConfig.put("/organisation", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(
      enqueueSnackbar(
        i18next.t(res.data.loc_key, {
          ...res.data.loc_kw,
          ns: "server",
        }),
        "success"
      )
    );
    dispatch({
      type: UPDATE_ORGANISATION_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar(
        i18next.t(error.response.data.loc_key, {
          ...error.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
    dispatch({
      type: UPDATE_ORGANISATION_FAILURE,
      payload: i18next.t(error.response.data.loc_key, {
        ...error.response.data.loc_kw,
        ns: "server",
      }),
    });
  }
};

export const getWalletDetails =
  ({
    page,
    page_size = 10,
    include_logs = false,
    include_replenishes = false,
  } = {}) =>
  async (dispatch) => {
    try {
      const res = await axiosConfig.get("/wallet", {
        caller: {
          func: getWalletDetails,
        },
        params: {
          page,
          page_size,
          include_logs,
          include_replenishes,
        },
      });
      dispatch({
        type: GET_WALLET_DETAILS_SUCCESS,
        payload: res.data,
        statusCode: res.status,
      });
    } catch (error) {
      dispatch({
        type: GET_WALLET_DETAILS_FAILURE,
        payload: error.response?.data,
        statusCode: error.response?.status,
      });
    }
  };

export const getAdminProjectsTest = (id) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/admin/projects/${id}/tests`);
    dispatch({
      type: GET_ADMIN_PROJECTS_TESTS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ADMIN_PROJECTS_TESTS_FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAdminProjectTestId = (id, testId) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(`/admin/projects/${id}/tests/${testId}`);
    dispatch({
      type: GET_ADMIN_PROJECTS_TESTS_ID_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ADMIN_PROJECTS_TESTS_ID_FAILURE,
      payload: error.response?.data,
    });
  }
};

export const getAdminAttributions = () => async (dispatch) => {
  try {
    const res = await axiosConfig.get(ADMIN_ATTRIBUTIONS);
    if (res.data) {
      dispatch({
        type: GET_ADMIN_ATTRIBUTIONS_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);

    if (err.isAxiosError && err.response?.status === 404) {
      dispatch({
        type: GET_ADMIN_ATTRIBUTIONS_SUCCESS,
        payload: [],
      });
    } else {
      console.error(err);
      dispatch({
        type: GET_ADMIN_TRIGGER_APPROVAL_FAILURE,
        payload: err.response.status,
      });
    }
  }
};

export const updateAdminProjectAttributions =
  (id, value) => async (dispatch) => {
    try {
      await axiosConfig.put(ADMIN_PROJECT.replace(/{id}/gi, id), {
        secondary_attribution_ids: value,
      });
    } catch (err) {
      dispatch(
        enqueueSnackbar(
          i18next.t(err.response.data.loc_key, {
            ...err.response.data.loc_kw,
            ns: "server",
          }),
          "warning"
        )
      );
    }
  };

export const getErrorMapping = (id) => async (dispatch) => {
  try {
    const mapping = await getErrorMappingRequest(id);
    dispatch({
      type: GET_ERROR_MAPPING_SUCCESS,
      payload: mapping,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR_MAPPING_FAILURE,
      payload: error,
    });
  }
};
