import { faCouch, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  makeStyles,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { push } from "connected-react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleSubmitGamePopover } from "../../../actions/confirmbox";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: theme.spacing(2),
  },
  button: {
    display: "block",
    color: "inherit",

    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: "1px solid #000",

    transition: "all 0.2s ease-in-out",

    "&:hover": {
      transform: "scale(1.1)",
      backgroundColor: theme.palette.kwaleeYellow,
      color: "inherit",
      textDecoration: "none",
    },

    "& p": {
      marginTop: theme.spacing(1),
    },
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: "300px",
  },
}));

export default function SubmitGamePopover() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const open = useSelector((state) => state.submitGamePopover.isOpen);

  const classes = useStyles();

  const setOpen = (isOpen) => {
    dispatch(toggleSubmitGamePopover(isOpen));
  };

  return (
    <>
      <li className="nav-item">
        <Tooltip
          title={t("header.navigation.route.index.submitGame.description")}
        >
          <Link
            to="/submit-game"
            className="nav-link"
            onClick={(event) => {
              event.preventDefault();
              setOpen(true);
            }}
          >
            {t("header.navigation.route.index.submitGame.navigation.title")}
          </Link>
        </Tooltip>
      </li>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title}>
            What type of game would you like to submit?
          </Typography>
          <Box className={classes.buttonContainer}>
            <Button
              target="/submit-game"
              icon={faCouch}
              title="Hyper Casual"
              description="A game which is easy-to-play and usually free-to-play, minimalistic user interfaces."
            />
            <Button
              target="/hybrid"
              icon={faStarHalfAlt}
              title="Hybrid Casual"
              description="Incorporates the simplicity of hyper-casual gameplay with more sophisticated progression mechanics that also appeal to mid-core audiences."
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Button({ target, icon, title, description }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onNavigate = () => {
    dispatch(toggleSubmitGamePopover(false));

    dispatch(push(target));
  };

  return (
    <>
      <Link
        component="button"
        className={classes.button}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={onNavigate}
      >
        <FontAwesomeIcon icon={icon} size="2x" />
        <Typography>{title}</Typography>
      </Link>

      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{description}</Typography>
      </Popover>
    </>
  );
}
