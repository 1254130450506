import { withStyles } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import store from "./_helpers/store";
import BetaHiddenProvider from "./components/common/BetaHiddenProvider";
import ConfirmBox from "./components/common/ConfirmBox";
import HashLinkScroll from "./components/common/HashLinkScroll";
import Notifier from "./components/common/Notifier";
import Feedback from "./components/feedback/Feedback";
import GDPRBanner from "./components/gdpr/GDPRBanner";
import InviteProvider from "./components/invite/InviteProvider";
import LicenseModal from "./components/license/LicenseModal";
import Maintenance from "./components/maintenance/Maintenance";
import ErrorBoundary from "./ErrorBoundary";
import LoadingSuspense from "./LoadingSuspense";
import { ROUTES_FLAT } from "./routes/definitions";
import RouteRenderer from "./routes/RouteRenderer";
import SnackbarStyles from "./style/snackbar";
import { MuiProviders } from "./theme/theme";
import UserProviders from "./UserProviders";
import history from "./util/createBrowserHistory";

function App(props) {
  const { classes } = props;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <BetaHiddenProvider>
            <MuiProviders>
              <UserProviders>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{ horizontal: "right", vertical: "top" }}
                  classes={{
                    root: classes.root,
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                  }}
                >
                  <InviteProvider>
                    <ErrorBoundary>
                      <HashLinkScroll>
                        <Notifier />

                        <LoadingSuspense>
                          <RouteRenderer routes={ROUTES_FLAT} />
                        </LoadingSuspense>

                        <LicenseModal />
                      </HashLinkScroll>
                    </ErrorBoundary>
                  </InviteProvider>

                  <ConfirmBox />
                  <Feedback />

                  <GDPRBanner />
                  <Maintenance />
                </SnackbarProvider>
              </UserProviders>
            </MuiProviders>
          </BetaHiddenProvider>
        </CookiesProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default withStyles(SnackbarStyles)(App);
