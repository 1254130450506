import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import usePermissions from "../permissions/PermissionLoader";
import useUserData from "../UserDataContext";
import { loginRequired } from "./authorisations";
import ComponentRoute from "./ComponentRoute";

export const HomeRedirect = ({ from }) => <Redirect from={from} to="/" />;

const PrivateRoute = ({ path, exact, component, isAuthorised, ...rest }) => {
  const userData = useUserData();
  const permissions = usePermissions();
  const location = useLocation();

  let isAuthorisedResult;
  if (typeof isAuthorised === "boolean") {
    isAuthorisedResult = isAuthorised;
  } else {
    if (isAuthorised === undefined || isAuthorised === null) {
      isAuthorised = loginRequired;
    }

    isAuthorisedResult = isAuthorised({ userData, permissions });
  }

  if (!isAuthorisedResult) {
    if (!!userData) {
      // TODO No permission page
      // Redirect to home page if user is logged in
      return <HomeRedirect />;
    }

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            pathname: location.pathname,
            search: location.search,
            hash: location.hash,
          },
        }}
      />
    );
  }

  return (
    <ComponentRoute path={path} component={component} exact={exact} {...rest} />
  );
};

export default PrivateRoute;
