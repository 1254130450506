import React from "react";
import { PERMISSION_ACCESS_READ } from "../../../permissions/permissionAccessLevels";
import {
  PERMISSION_ADMIN_ARES_ERROR_MAPPING,
  PERMISSION_ADMIN_CURRENCY,
  PERMISSION_ADMIN_EMAIL_REPORT_API,
  PERMISSION_ADMIN_INVITE_LINK,
  PERMISSION_ADMIN_LICENSE,
  PERMISSION_ADMIN_LOG,
  PERMISSION_ADMIN_MESSAGE_CATEGORY,
  PERMISSION_ADMIN_MESSAGE_TEMPLATE,
  PERMISSION_ADMIN_ORGANISATION_PROJECT_COLLECTION,
  PERMISSION_ADMIN_PROJECT_TEST_ACCOUNT,
  PERMISSION_ADMIN_ROLE,
  PERMISSION_ADMIN_TRIGGER_APPROVAL,
  PERMISSION_ADMIN_USER_FEEDBACK,
} from "../../../permissions/permissions";
import { internalUserRequired, permissionRequired } from "../../authorisations";
import ROUTES_ADMIN_ORGANISATION from "./organisation.admin.routes";
import ROUTES_ADMIN_PROJECT from "./project.admin.routes";
import ROUTES_ADMIN_USER from "./user.admin.routes";

const ROUTES_ADMIN = [
  {
    id: "admin.approvals",

    path: "/admin",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_TRIGGER_APPROVAL,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 0,
      group: "Approvals",
    },

    Component: React.lazy(() => import("../../../components/admin/Admin")),
  },
  {
    id: "admin.search",

    path: "/admin/search",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_ORGANISATION_PROJECT_COLLECTION,
      PERMISSION_ACCESS_READ
    ),

    Component: React.lazy(() =>
      import("../../../components/admin/search/Search")
    ),
  },
  {
    id: "admin.invite",

    path: "/admin/invite",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_INVITE_LINK,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 3,
      group: "Attribution",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/InviteLinkAdmin")
    ),
  },
  {
    id: "admin.tag",

    path: "/admin/tag",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_INVITE_LINK,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 4,
      group: "Attribution",
    },

    Component: React.lazy(() => import("../../../components/admin/TagAdmin")),
  },
  {
    id: "admin.testAccount",

    path: "/admin/test-account",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_PROJECT_TEST_ACCOUNT,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 14,
      group: "Project",
    },

    Component: React.lazy(() =>
      import(
        "../../../components/admin/project/TestAccount/ProjectTestAccountAdmin"
      )
    ),
  },
  {
    id: "admin.message.category",

    path: "/admin/message-category",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_MESSAGE_CATEGORY,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 15,
      group: "Message",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/MessageCategoryAdmin")
    ),
  },
  {
    id: "admin.message.template",

    path: "/admin/message-template",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_MESSAGE_TEMPLATE,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 16,
      group: "Message",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/MessageTemplatesAdmin")
    ),
  },
  {
    id: "admin.currency",

    path: "/admin/currency",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_CURRENCY,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 20,
      group: "Admin",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/CurrencyAdmin")
    ),
  },
  {
    id: "admin.feedback",

    path: "/admin/feedback",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_USER_FEEDBACK,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      group: "Approvals",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/FeedbackAdmin")
    ),
  },
  {
    id: "admin.license",

    path: "/admin/license",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_LICENSE,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 21,
      group: "Admin",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/LicensesAdmin")
    ),
  },
  {
    id: "admin.role",

    path: "/admin/role",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_ROLE,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 7,
      group: "Users",
    },

    Component: React.lazy(() => import("../../../components/admin/RolesAdmin")),
  },
  {
    id: "admin.logs",

    path: "/admin/logs",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_LOG,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 15,
    },

    Component: React.lazy(() => import("../../../components/admin/LogsAdmin")),
  },
  {
    id: "admin.funnel",

    path: "/admin/funnel",
    exact: true,
    isAuthorised: internalUserRequired,

    navigation: {
      internal: true,
      order: 14,
      group: "Report",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/funnel/FunnelAdmin")
    ),
  },
  {
    id: "admin.locale",

    path: "/admin/locale",
    exact: true,
    isAuthorised: internalUserRequired,

    navigation: {
      internal: true,
      order: 18,
      group: "Admin",
    },

    Component: React.lazy(() =>
      import("../../../components/locale/LocaleDashboard")
    ),
  },
  {
    id: "admin.reports",

    path: "/admin/report",
    exact: true,
    isAuthorised: internalUserRequired,

    navigation: {
      internal: true,
      order: 12,
      group: "Report",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/report/ReportAdmin")
    ),
  },
  {
    id: "admin.attributions",

    path: "/admin/attribution",
    exact: true,
    isAuthorised: internalUserRequired,

    navigation: {
      internal: true,
      order: 2,
      group: "Attribution",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/AttributionAdmin")
    ),
  },
  {
    id: "admin.errorMapping",

    path: "/admin/error-mapping",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_ARES_ERROR_MAPPING,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 19,
      group: "Admin",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/ErrorMappingAdmin")
    ),
  },
  {
    id: "admin.email_report",

    path: "/admin/report/email",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_EMAIL_REPORT_API,
      PERMISSION_ACCESS_READ
    ),

    navigation: {
      internal: true,
      order: 13,
      group: "Report",
    },

    Component: React.lazy(() =>
      import("../../../components/admin/report/EmailReportAdmin")
    ),
  },
  ...ROUTES_ADMIN_USER,
  ...ROUTES_ADMIN_ORGANISATION,
  ...ROUTES_ADMIN_PROJECT,
];

export default ROUTES_ADMIN;
