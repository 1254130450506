import store from "../../_helpers/store";
import { GET_LICENCE_SUCCESS } from "../../actions/types";
import { failedRequests } from "../../components/license/LicenseModal";
import { axiosConfig, isValidURL } from "../axiosConfig";

function interceptLicense(err) {
  if (isValidURL(err.config) && err.response?.status === 451) {
    if (!!err.config.caller) {
      failedRequests.push(err.config.caller);
    }

    store.dispatch({
      type: GET_LICENCE_SUCCESS,
      payload: err.response.data,
    });
  }

  throw err;
}

axiosConfig.interceptors.response.use((response) => response, interceptLicense);
