import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ZoomIn, ZoomOut } from "@material-ui/icons";
import cx from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { NODE_ENV } from "../../_helpers/config";
import endPoints from "../../_helpers/endPoints.json";
import { userActions } from "../../actions/auth";
import { enqueueSnackbar } from "../../actions/notifier";
import { axiosConfig } from "../../data/axiosConfig";
import KwaleeLogo from "../../media/logo.png";
import useExperiment from "../../util/useExperiment";
import EventLogo from "../common/EventLogo";
import Trans from "../common/Trans";
import { InviteContext } from "../invite/InviteProvider";
import Logo from "../Logo";
import useUTMData from "./UTMTrackerContext";

const Signup = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();
  const invite = useContext(InviteContext);
  const utmData = useUTMData();

  const [email, handleUserName] = useState("");

  const [licences, setLicenses] = useState([]);
  const [acceptedLicenses, setAcceptedLicenses] = useState([]);
  const [licenseModal, setLicenseModal] = useState();

  const isExpandedSignupLayoutExperiment =
    useExperiment({ experimentId: "7gfjxnCkQJW2_Xcw4r1hyg" }) === "1";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const canSubmit = () => {
    if (email.trim().length < 1 || !validateEmail(email)) {
      return false;
    }

    if (
      !licences.every(
        (license) =>
          !!acceptedLicenses.find(
            (l) =>
              license.licence_id === l.licence_id &&
              license.version_number === l.version_number
          )
      )
    ) {
      return false;
    }

    return true;
  };
  const isDisabled = !canSubmit();

  const submit = () => {
    if (!canSubmit()) {
      return;
    }

    dispatch(
      userActions.register(endPoints.Signup, {
        email,
        licence_acknowledgements: acceptedLicenses,
        invite_link: invite,
        acquisition_data:
          Object.keys(utmData ?? {}).length > 0
            ? JSON.stringify(utmData)
            : undefined,
        utm_data: utmData,
      })
    );
  };

  const acceptLicense = (license, accept) => {
    const licenses = [...acceptedLicenses];
    const index = licenses.findIndex(
      (l) =>
        license.licence_id === l.licence_id &&
        license.version_number === l.version_number
    );
    if (accept) {
      if (index >= 0) {
        return;
      }

      licenses.push({
        licence_id: license.licence_id,
        version_number: license.version_number,
      });
    } else {
      if (index < 0) {
        return;
      }

      licenses.splice(index, 1);
    }

    setAcceptedLicenses(licenses);
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  useEffect(() => {
    getLicenses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLicenses = async () => {
    try {
      const res = await axiosConfig.get("/licences", {
        params: {
          location: "registry",
        },
      });
      setLicenses(res.data.data.location_licences);
    } catch (err) {
      console.error(err);

      dispatch(enqueueSnackbar(t("error.license.loading"), "warning"));
    }
  };

  return (
    <div className="full-page">
      {isExpandedSignupLayoutExperiment ? (
        <div className="page-container">
          <div className="full-page-section flex-column">
            <div className="logo text-center">
              <img className={classes.kwaleeLogo} src={KwaleeLogo} alt={""} />
            </div>
            <div className={classes.containerSection}>
              <div className={classes.welcomeSection}>
                <h1 className={classes.leftHeader}>
                  Welcome to Our
                  {isMobile ? " " : <br />}
                  Publishing Portal
                </h1>
                <ul className="list-section">
                  <li className={classes.subText}>
                    Submit your mobile game for testing
                  </li>
                  <li className={classes.subText}>Get coaching and support</li>
                  <li className={classes.subText}>
                    Learn about our expert publishing services
                  </li>
                </ul>
              </div>
              {!isMobile && (
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  flexItem
                />
              )}
              <div
                className={`full-page-form-container ${classes.formSection}`}
              >
                <div className="full-page-head">
                  <EventLogo style={{ marginBottom: -26 }} />
                  <h1 className={classes.leftHeader}>
                    {t("page.signup.title")}
                  </h1>
                </div>
                <form
                  id="loginForm"
                  onSubmit={(event) => {
                    event.preventDefault();

                    submit();
                  }}
                >
                  <div
                    className={cx("form-group input-group", {
                      invalid: !validateEmail(email),
                    })}
                    style={{ padding: "0 2em" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src="img/icon-envelope.svg" alt="icon-envelope" />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="email"
                      placeholder={t("email")}
                      autoComplete="email"
                      onChange={(e) => handleUserName(e.target.value)}
                      value={email}
                    />
                  </div>

                  {licences.length > 0 && (
                    <>
                      <div className="licenses">
                        <div>
                          {licences.map((license, index) => {
                            const isAccepted = !!acceptedLicenses.find(
                              (l) =>
                                license.licence_id === l.licence_id &&
                                license.version_number === l.version_number
                            );

                            return (
                              <div key={index} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isAccepted}
                                  onChange={(event) => {
                                    event.preventDefault();

                                    setLicenseModal(license);
                                  }}
                                />
                                <label
                                  className="form-check-label btn-link"
                                  onClick={(event) => {
                                    event.preventDefault();

                                    setLicenseModal(license);
                                  }}
                                >
                                  {license.display_name}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <label className={classes.agreementLabel}>
                        {t("licenseAgree")}
                      </label>
                    </>
                  )}

                  <div
                    className="submit-btn-group "
                    style={{ padding: "0 2em" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-secondary btn-hover-outline w-100"
                      disabled={isDisabled}
                    >
                      {t("page.signup.signUp")}{" "}
                      <i className="fa fa-arrow-right" aria-hidden="true" />
                    </button>
                  </div>
                  <hr />
                  <div className="form-group text-center">
                    <Trans
                      i18nKey="page.signup.login"
                      components={{
                        login: (
                          <Link
                            className="text-dark font-weight-bold"
                            to="/login"
                            style={{ display: "contents" }}
                          />
                        ),
                      }}
                    />
                  </div>
                  <div className="form-group text-center">
                    <Trans
                      i18nKey="page.login.help"
                      components={{
                        mailto: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            className="text-dark font-weight-bold"
                            href="mailto:portal-support@kwalee.com"
                          />
                        ),
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="full-page-section">
          <div className="full-page-form">
            <div className="full-page-form-container">
              <div className="full-page-head">
                <Logo />
                <EventLogo style={{ marginBottom: -26 }} />
                <h1>{t("page.signup.title")}</h1>
              </div>
              <form
                id="loginForm"
                onSubmit={(event) => {
                  event.preventDefault();

                  submit();
                }}
              >
                <div
                  className={cx("form-group input-group", {
                    invalid: !validateEmail(email),
                  })}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src="img/icon-envelope.svg" alt="icon-envelope" />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="email"
                    placeholder={t("email")}
                    autoComplete="email"
                    onChange={(e) => handleUserName(e.target.value)}
                    value={email}
                  />
                </div>

                {licences.length > 0 && (
                  <>
                    <label className="mt-2">{t("licenseAgree")}</label>

                    <div className="licenses">
                      <div>
                        {licences.map((license, index) => {
                          const isAccepted = !!acceptedLicenses.find(
                            (l) =>
                              license.licence_id === l.licence_id &&
                              license.version_number === l.version_number
                          );

                          return (
                            <div key={index} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isAccepted}
                                onChange={(event) => {
                                  event.preventDefault();

                                  setLicenseModal(license);
                                }}
                              />
                              <label
                                className="form-check-label btn-link"
                                onClick={(event) => {
                                  event.preventDefault();

                                  setLicenseModal(license);
                                }}
                              >
                                {license.display_name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

                <div className="submit-btn-group ">
                  <button
                    type="submit"
                    className="btn btn-secondary btn-hover-outline w-100"
                    disabled={isDisabled}
                  >
                    {t("page.signup.signUp")}{" "}
                    <i className="fa fa-arrow-right" aria-hidden="true" />
                  </button>
                </div>
                <hr />
                <div className="form-group text-center">
                  <Trans
                    i18nKey="page.signup.login"
                    components={{
                      login: (
                        <Link
                          className="text-dark font-weight-bold"
                          to="/login"
                          style={{ display: "contents" }}
                        />
                      ),
                    }}
                  />
                </div>
                <div className="form-group text-center">
                  <Trans
                    i18nKey="page.login.help"
                    components={{
                      mailto: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          className="text-dark font-weight-bold"
                          href="mailto:portal-support@kwalee.com"
                        />
                      ),
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <LicenseModal
        url={licenseModal?.document_url}
        name={licenseModal?.display_name}
        onClose={(agreed) => {
          if (agreed) {
            acceptLicense(licenseModal, true);
          }

          setLicenseModal(undefined);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  kwaleeLogo: {
    paddingBottom: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "16rem",
      padding: 0,
    },
  },
  agreementLabel: {
    fontSize: 15,
    marginTop: 2,
  },
  containerSection: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "baseline",
    flexWrap: "wrap",
    width: "100%",
  },
  welcomeSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  divider: {
    borderRight: "2.5px dashed #FFF",
    "&: .MuiDivider-root": {
      backgroundColor: "none !important",
    },
  },
  formSection: {
    margin: "0 !important",
    width: "auto !important",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
    },
  },
  leftHeader: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      margin: "10px 0 !important",
    },
    fontSize: "32px",
    textAlign: "center",
  },
  subText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      padding: 0,
    },
    fontSize: "20px",
    padding: "10px 0",

    "&::marker": {
      fontSize: "30px",
    },
  },
  content: {
    padding: 0,
  },
  document: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,

    "& .react-pdf__Page__canvas": {
      margin: "0 auto",
    },
  },
  zoom: {
    position: "sticky",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    display: "flex",

    "& > div": {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",

      "& > span": {
        backgroundColor: `${theme.palette.common.white} !important`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
        padding: theme.spacing(1),
      },
    },
  },
}));

const SCROLL_THRESHOLD = 50;

const endcapProps = {
  display: "block",
  backgroundColor: "#ffcb05",
  zIndex: 1,
  transition: "box-shadow 0.3s ease-in-out",
};

export const LicenseModal = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const contentRef = useRef();

  const hasVerticalScroll = () =>
    (contentRef.current?.scrollHeight ?? 0) > (contentRef?.clientHeight ?? 0);

  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(undefined);

  const [isTop, setTop] = useState(true);
  const [isBottom, setBottom] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onUpdateWidth = () => {
    setWidth(contentRef.current?.clientWidth - 48);
  };

  useEffect(() => {
    onUpdateWidth();
  }, []);

  const onScroll = useCallback((event) => {
    if (!event) {
      return;
    }

    const target = "target" in event ? event.target : event;

    setTop(!hasVerticalScroll() || target.scrollTop <= SCROLL_THRESHOLD);
    setBottom(
      !hasVerticalScroll() ||
        target.scrollHeight - target.scrollTop <=
          target.clientHeight + SCROLL_THRESHOLD
    );
  }, []);

  useEffect(() => {
    const onResize = () => {
      onScroll(contentRef.current);
      onUpdateWidth();
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onScroll]);

  return (
    <Dialog open={!!props.url} onClose={() => props.onClose(false)}>
      <DialogTitle
        id="customized-dialog-title"
        style={{
          ...endcapProps,
          boxShadow: `0 2px 20px -6px ${
            !hasVerticalScroll() || isTop ? "transparent" : "black"
          }`,
        }}
        onClose={() => props.onClose(false)}
      >
        {props.name}
      </DialogTitle>
      <DialogContent
        className={classes.content}
        ref={contentRef}
        onScroll={onScroll}
      >
        <Document
          className={classes.document}
          loading="Loading license..."
          error="Failed to load license."
          file={
            NODE_ENV === "production"
              ? props.url
              : `https://api.codetabs.com/v1/proxy?quest=${props.url}`
          }
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log(error)}
        >
          {Array.from(new Array(numPages), (_, i) => (
            <Page key={i} pageNumber={i + 1} scale={scale} width={width} />
          ))}
        </Document>

        <Box className={classes.zoom}>
          <Box>
            <IconButton
              color="inherit"
              aria-label="zoom in"
              component="span"
              onClick={() => setScale((scale) => scale + 0.1)}
            >
              <ZoomIn />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="zoom out"
              component="span"
              onClick={() => setScale((scale) => Math.max(0.1, scale - 0.1))}
            >
              <ZoomOut />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          ...endcapProps,
          boxShadow: `0 -2px 20px -6px ${
            !hasVerticalScroll() || isBottom ? "transparent" : "black"
          }`,
        }}
      >
        <div className="w-100 small p-2">
          {t("modal.license.acknowledgeStatement", {
            license: props.name,
          })}
        </div>
        <Box textAlign="right" p={2}>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => props.onClose(false)}
          >
            {t("close")}
          </button>
          <button
            autoFocus
            className="btn btn-sm btn-secondary btn-hover-outline"
            disabled={!isBottom}
            onClick={() => props.onClose(true)}
          >
            {t("agree")}
          </button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Signup;
