import i18next from "i18next";
import endPoints from "../_helpers/endPoints.json";
import { axiosConfig } from "../data/axiosConfig";
import {
  getLocalStorageUserData,
  setLocalStorageUserData,
} from "../UserDataContext";
import { enqueueSnackbar } from "./notifier";
import {
  AGREE_LICENCE_FAILURE,
  AGREE_LICENCE_SUCCESS,
  GET_LICENCE_FAILURE,
  GET_LICENCE_SUCCESS,
  USER_DATA_FAIL,
  USER_DATA_SUCCESS,
} from "./types";

export const getUserData = (apiName) => async (dispatch) => {
  try {
    const res = await axiosConfig.get(apiName, {
      headers: {
        "Permission-Code": "user_profile_api",
      },
    });
    dispatch({
      type: USER_DATA_SUCCESS,
      payload: res.data,
    });
    setLocalStorageUserData({
      ...getLocalStorageUserData(),
      locale: res.data.data.locale,
    });
  } catch (err) {
    dispatch({
      type: USER_DATA_FAIL,
      payload: i18next.t("error.user.notFound"),
    });
  }
};

export const editUserData = (apiName, userData) => async (dispatch) => {
  try {
    const res = await axiosConfig.put(apiName, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Permission-Code": "user_profile_api",
      },
    });
    dispatch(
      enqueueSnackbar(
        i18next.t(res.data.loc_key, {
          ...res.data.loc_kw,
          ns: "server",
        }),
        "success"
      )
    );
    dispatch(getUserData(endPoints.GetAccount));
  } catch (err) {
    dispatch(
      enqueueSnackbar(
        i18next.t(err.response.data.loc_key, {
          ...err.response.data.loc_kw,
          ns: "server",
        }),
        "warning"
      )
    );
  }
};

export const getLicence = () => async (dispatch) => {
  try {
    const res = await axiosConfig.get("/licences");
    dispatch({
      type: GET_LICENCE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LICENCE_FAILURE,
      payload: error.response.data,
    });
  }
};

export const agreeLicence = (data) => async (dispatch, getState) => {
  try {
    const res = await axiosConfig.post("/licences", data);
    dispatch({
      type: AGREE_LICENCE_SUCCESS,
      payload: res.data,
    });

    const licenses = (
      getState().getLicence?.data?.outstanding_licences ?? []
    ).filter(
      (license) =>
        license.licence_id !== data.licence_id ||
        license.version_number !== data.version_number
    );

    dispatch({
      type: GET_LICENCE_SUCCESS,
      payload: {
        is_valid: true,
        data: { outstanding_licences: licenses },
      },
    });
  } catch (error) {
    dispatch({
      type: AGREE_LICENCE_FAILURE,
      payload: error.response.data,
    });
  }
};
