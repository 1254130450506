import md5 from "md5";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import endPoints from "../../_helpers/endPoints.json";
import { userActions } from "../../actions/auth.jsx";
import { Required } from "../account/EditAccount";
import EventLogo from "../common/EventLogo";
import Trans from "../common/Trans";
import Logo from "../Logo";
import ComingSoonWrapper from "../../media/coming-soon-background.svg";
import Devices from "../../media/devices-1.webp";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pageSection: {
    width: "65%",
    [theme.breakpoints.down("md")]: { width: "55%" },
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
  deviceImageStyle: {
    position: "absolute",
    right: 0,
    bottom: 0,
    height: "auto",
    width: "100%",
    maxWidth: "90%",

    [theme.breakpoints.down("sm")]: { maxWidth: "80%" },
  },
  comingSoonBanner: {
    backgroundImage: `url(${ComingSoonWrapper})`,
    width: "35%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: 1,
    position: "relative",

    [theme.breakpoints.down("md")]: { width: "45%" },

    [theme.breakpoints.down("sm")]: { display: "none" },
  },
  contentBox: {
    position: "absolute",
    top: 120,
    left: 120,
    [theme.breakpoints.down("sm")]: { top: 150, left: 60 },

    "& h3": {
      fontSize: "2.5rem",
      fontFamily: "'Unbounded', sans-serif",
    },
    "& h6": {
      fontSize: 16,
      [theme.breakpoints.down("sm")]: { fontSize: 14 },
    },
    "& p": {
      [theme.breakpoints.down("md")]: { fontSize: 12 },
    },
  },
}));

const Login = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const location = useLocation();

  const classes = useStyles();

  const [text, changeText] = useState({
    email: "",
    password: "",
  });

  const [passwordView, changePasswordView] = useState(false);
  const [rememberMe, handleRememberMe] = useState(false);
  const [submitCheck, handleSubmitCheck] = useState(false);

  function handleText(e) {
    const { name, value } = e.target;
    changeText({ ...text, [name]: value });
  }

  function handlePasswordView() {
    changePasswordView((prevState) => !prevState);
  }

  const submit = () => {
    const redirect = location?.state;

    handleSubmitCheck(true);
    if (text.email === "" || text.password === "") return;
    if (!validateEmail(text.email)) return;
    const data = {
      email: text.email,
      password: `f(${md5(text.password)})`,
    };
    dispatch(userActions.login(endPoints.Login, data, redirect));
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const isDisabled = text.email.trim().length < 1 && text.password.length < 1;

  return (
    <div className="full-page d-flex">
      <div className={`full-page-section ${classes.pageSection}`}>
        <div className="full-page-form">
          <div className="full-page-form-container">
            <div className="full-page-head">
              <Logo />
              <EventLogo />
              <h1>
                <Trans i18nKey="page.login.welcome" />
              </h1>
            </div>
            <form
              id="loginForm"
              onSubmit={(event) => {
                event.preventDefault();

                submit();
              }}
            >
              <div
                className="form-group input-group"
                style={{ marginBottom: "5px" }}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src="img/icon-envelope.svg" alt="icon-envelope" />
                  </span>
                </div>
                <input
                  className="form-control"
                  name="email"
                  type="email"
                  placeholder={t("email")}
                  autoComplete="email"
                  onChange={handleText}
                  value={text.email}
                />
              </div>
              <div className="form-group input-group">
                {submitCheck && (
                  <>
                    {text.email === "" ? (
                      <Required />
                    ) : (
                      !validateEmail(text.email) && (
                        <Required>{t("error.email.invalid")}</Required>
                      )
                    )}
                  </>
                )}
              </div>
              <div
                className="form-group input-group"
                style={{ marginBottom: "5px" }}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src="img/icon-password.svg" alt="icon-password" />
                  </span>
                </div>
                <input
                  className="form-control"
                  name="password"
                  type={passwordView ? "text" : "password"}
                  placeholder={t("password.text")}
                  autoComplete="current-password"
                  onChange={handleText}
                  value={text.password}
                />
                <div
                  className="input-group-append"
                  onClick={handlePasswordView}
                >
                  <span className="input-group-text">
                    <i className={`fa fa-eye${passwordView ? "" : "-slash"}`} />
                  </span>
                </div>
              </div>
              <div className="form-group input-group ">
                {submitCheck && text.password === "" && <Required />}
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="checkboxbtn">
                    <input
                      type="checkbox"
                      id="checkbox2"
                      defaultChecked={rememberMe}
                    />
                    <label
                      htmlFor="checkbox2"
                      onClick={() =>
                        handleRememberMe((prevState) => !prevState)
                      }
                    >
                      {t("rememberMe")}
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="forgot-password text-right">
                    <Link className="text-dark" to="/lost-password">
                      {t("forgotPassword")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="submit-btn-group ">
                <button
                  type="submit"
                  disabled={isDisabled}
                  className="btn btn-secondary btn-hover-outline w-100"
                >
                  {t("login")}{" "}
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
              <hr />
              <div className="form-group text-center">
                <Trans
                  i18nKey="page.login.signup"
                  components={{
                    signup: (
                      <Link
                        className="text-dark font-weight-bold"
                        to="/signup"
                        style={{ display: "contents" }}
                      />
                    ),
                  }}
                />
              </div>
              <div className="form-group text-center">
                <Trans
                  i18nKey="page.login.help"
                  components={{
                    mailto: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        className="text-dark font-weight-bold"
                        href="mailto:portal-support@kwalee.com"
                      />
                    ),
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={classes.comingSoonBanner}>
        <div className={classes.contentBox}>
          <h3>Coming Soon</h3>
          <h6>New Kwalee Publishing Portal</h6>
          <p>
            Your one-stop shop for submitting, testing, and analysing your
            game’s performance with real players.
          </p>
        </div>

        <img className={classes.deviceImageStyle} src={Devices} alt={""} />
      </div>
    </div>
  );
};

export default connect(null)(Login);
