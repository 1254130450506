import { axiosConfig } from "../../axiosConfig";
import { processEndpoint } from "../service.helper";
import {
  INVITED_EMAIL_VERIFICATION,
  ORGANISATION,
} from "./organisation.endpoints";

export async function inviteToOrganisation(email) {
  return await processEndpoint(
    axiosConfig.patch(ORGANISATION, {
      email,
    })
  );
}

export async function acceptOrganisationInvitation(
  auth_token,
  password,
  licence_acknowledgements
) {
  const result = await processEndpoint(
    axiosConfig.post(INVITED_EMAIL_VERIFICATION, {
      auth_token,
      password,
      has_accepted_tcs: true,
      licence_acknowledgements,
    }),
    {
      returnData: (res) => res.data,
    }
  );

  if (result.is_valid) {
    window.dataLayer.push({
      event: "verify_password",
      invited: true,
    });

    window.lintrk("track", { conversion_id: 4658650 });
  }

  return result;
}
