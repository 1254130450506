import React from "react";
import { loginRequired } from "../authorisations";
import { HomeRedirect } from "../PrivateRoute";

const KwaleeSdk = React.lazy(() =>
  import("../../components/instructions/sdk/KwaleeSdk")
);

const ROUTES_GENERAL = [
  {
    id: "welcome",

    path: "/welcome",
    exact: true,
    isAuthorised: loginRequired,

    hasHeading: false,

    Component: React.lazy(() => import("../../components/welcome/Welcome")),
  },
  {
    id: "storeSetup",

    path: "/store-setup",
    exact: true,
    isAuthorised: loginRequired,

    hasHeading: false,

    Component: () => <HomeRedirect from="/store-setup" />,

    children: [
      {
        id: "android",

        path: "/store-setup/android",
        exact: true,
        isAuthorised: loginRequired,

        hasHeading: false,

        Component: React.lazy(() =>
          import("../../components/instructions/store-setup/GooglePlay")
        ),
      },
      {
        id: "ios",

        path: "/store-setup/ios",
        exact: true,
        isAuthorised: loginRequired,

        hasHeading: false,

        Component: React.lazy(() =>
          import("../../components/instructions/store-setup/AppleStore")
        ),
      },
    ],
  },
  {
    id: "sdkSetup",

    path: "/sdk-setup",
    exact: true,
    isAuthorised: loginRequired,

    hasHeading: false,

    Component: () => <HomeRedirect from="/sdk-setup" />,

    children: [
      {
        id: "kwalee",

        path: "/sdk-setup/kwalee",
        exact: true,
        isAuthorised: loginRequired,

        hasHeading: false,

        Component: () => <KwaleeSdk implementAnalytics />,

        children: [
          {
            id: "cpi",

            path: "/sdk-setup/kwalee/cpi",
            exact: true,
            isAuthorised: loginRequired,

            hasHeading: false,

            Component: () => <KwaleeSdk />,
          },
        ],
      },
    ],
  },
];

export default ROUTES_GENERAL;
