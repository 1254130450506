import cx from "classnames";
import moment from "moment";
import React from "react";
import hypercasualHeroes from "../../media/hypercasual-heroes.png";

const EVENT_WEEK_TO_DAY_THRESHOLD = 21;
const EVENT_END_DATE = moment.utc("2021-09-01 01:00:00"); // Midnight, end of 31st August UK time

export default function EventLogo({
  showCountdown = true,
  className,
  style,
  timeRemainingStyle,
}) {
  const today = moment.utc();
  const millisRemaining = EVENT_END_DATE.diff(today, "milliseconds");
  const daysRemaining = millisRemaining / (24 * 60 * 60 * 1000);

  let daysRemainingDisplay;
  if (daysRemaining > 0) {
    if (daysRemaining <= 1) {
      daysRemainingDisplay = "Last Day!";
    } else if (Math.floor(daysRemaining) <= EVENT_WEEK_TO_DAY_THRESHOLD) {
      daysRemainingDisplay = `${Math.floor(daysRemaining)} Days Left!`;
    } else {
      const weeksRemaining = Math.ceil(daysRemaining / 7);
      daysRemainingDisplay = `${weeksRemaining} ${
        weeksRemaining === 1 ? "Week" : "Weeks"
      } Left!`;
    }
  }

  if (!daysRemainingDisplay) {
    return null;
  }

  return (
    <a
      href="https://kwalee.com/blog/inside-kwalee/introducing-hypercasual-heroes"
      target="_blank"
      rel="noopener noreferrer"
      className={cx("logo-countdown", className)}
      style={style}
    >
      <img src={hypercasualHeroes} alt="Hypercasual Heroes" />

      {showCountdown && (
        <div style={timeRemainingStyle}>{daysRemainingDisplay}</div>
      )}
    </a>
  );
}
