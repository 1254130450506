import cx from "classnames";
import { push } from "connected-react-router";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import zxcvbn from "zxcvbn";
import endPoints from "../../_helpers/endPoints.json";
import { userActions } from "../../actions/auth";
import { enqueueSnackbar } from "../../actions/notifier";
import { axiosConfig } from "../../data/axiosConfig";
import { acceptOrganisationInvitation } from "../../data/service/organisation/organisation.service";
import { setLocalStorageUserData } from "../../UserDataContext";
import Logo from "../Logo";
import { LicenseModal } from "../signup/Signup";
import { PASSWORD_VALIDATION } from "./CreatePassword";
import PasswordStrengthMeter from "./PasswordStrengthMeter";

const AcceptInvite = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState();
  const [strongPassword, handleStrongPassword] = useState(false);

  const [passwordView, changePasswordView] = useState(false);

  const [licences, setLicenses] = useState([]);
  const [acceptedLicenses, setAcceptedLicenses] = useState([]);
  const [licenseModal, setLicenseModal] = useState();

  const queryParams = new URLSearchParams(history.location.search ?? "");
  const token = queryParams.get("token") ?? undefined;

  useEffect(() => {
    if (!token) {
      history.push("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    let testedResult = "";
    if (password && password !== "") {
      testedResult = zxcvbn(password);
    }

    setPasswordStrength(testedResult);

    if (typeof testedResult === "object") {
      switch (testedResult.score) {
        case 3:
        case 4:
          handleStrongPassword(true);
          return;
        default:
          break;
      }
    }

    handleStrongPassword(false);
  }, [password]);

  const isMeetingRequirements = PASSWORD_VALIDATION.exec(password);

  const canSubmit = () => {
    if (!isMeetingRequirements) {
      return false;
    }

    if (confirmPassword !== password) {
      return false;
    }

    if (
      !licences.every(
        (license) =>
          !!acceptedLicenses.find(
            (l) =>
              license.licence_id === l.licence_id &&
              license.version_number === l.version_number
          )
      )
    ) {
      return false;
    }

    return true;
  };
  const isDisabled = !canSubmit();

  useEffect(() => {
    getLicenses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLicenses = async () => {
    try {
      const res = await axiosConfig.get("/licences", {
        params: {
          location: "invite_accepted",
        },
      });

      setLicenses(res.data.data.location_licences);
    } catch (err) {
      console.error(err);

      dispatch(enqueueSnackbar(t("error.license.loading"), "warning"));
    }
  };

  const acceptLicense = (license, accept) => {
    const licenses = [...acceptedLicenses];
    const index = licenses.findIndex(
      (l) =>
        license.licence_id === l.licence_id &&
        license.version_number === l.version_number
    );
    if (accept) {
      if (index >= 0) {
        return;
      }

      licenses.push({
        licence_id: license.licence_id,
        version_number: license.version_number,
      });
    } else {
      if (index < 0) {
        return;
      }

      licenses.splice(index, 1);
    }

    setAcceptedLicenses(licenses);
  };

  const submit = async () => {
    if (confirmPassword.length < 1) {
      return;
    }
    if (password.length < 8 || !strongPassword) {
      dispatch(enqueueSnackbar(t("error.password.weak"), "warning"));
      return;
    }
    if (password !== confirmPassword) {
      dispatch(enqueueSnackbar(t("error.password.noMatch"), "warning"));
      return;
    }
    if (
      !password.match(/[a-z]/g) &&
      !password.match(/[A-Z]/g) &&
      !password.match(/[0-9]/g)
    ) {
      dispatch(enqueueSnackbar(t("error.password.requirements")));
      return;
    }

    try {
      const encodedPassword = `f(${md5(password)})`;

      const result = await acceptOrganisationInvitation(
        token,
        encodedPassword,
        acceptedLicenses
      );

      if (result.is_valid) {
        dispatch(
          enqueueSnackbar(t(result.loc_key, { ...result.loc_kw, ns: "server" }))
        );

        const email = result?.data?.email;

        setLocalStorageUserData(undefined);

        if (email) {
          dispatch(
            userActions.login(
              endPoints.Login,
              {
                email,
                password: encodedPassword,
              },
              "/"
            )
          );
        } else {
          dispatch(push("/"));
        }
      }
    } catch (e) {
      console.error(e);

      dispatch(
        enqueueSnackbar(
          t(e.response.data.loc_key, {
            ...e.response.data.loc_kw,
            ns: "server",
          })
        )
      );
    }
  };

  function handlePasswordView() {
    changePasswordView((prevState) => !prevState);
  }

  return (
    <div className="full-page">
      {/*Main wrapper start*/}
      <div className="full-page-section">
        <div className="full-page-form">
          <div className="full-page-form-container">
            <div className="full-page-head">
              <Logo />
              <h1>{t("page.acceptInvite.title")}</h1>
            </div>
            <form id="loginForm">
              <div
                className={cx("form-group input-group", {
                  invalid: !isMeetingRequirements,
                })}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src="img/icon-password.svg" alt="icon-password" />
                  </span>
                </div>
                <input
                  className="form-control"
                  type={passwordView ? "text" : "password"}
                  placeholder={t("password.new")}
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <div
                  className="input-group-append"
                  onClick={handlePasswordView}
                >
                  <span className="input-group-text">
                    <i className={`fa fa-eye${passwordView ? "" : "-slash"}`} />
                  </span>
                </div>
              </div>

              <div
                className={cx("form-group input-group", {
                  invalid: confirmPassword !== password,
                })}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src="img/icon-password.svg" alt="icon-password" />
                  </span>
                </div>
                <input
                  className="form-control"
                  type="password"
                  placeholder={t("password.confirm")}
                  autoComplete="new-password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
              </div>

              <div className="row">
                <div className="col-12">
                  <p className="small">{t("password.requirements")}</p>
                  <PasswordStrengthMeter result={passwordStrength} />
                </div>
              </div>

              {licences.length > 0 && (
                <>
                  <label>{t("licenseAgree")}</label>

                  <div className="licenses">
                    <div>
                      {licences.map((license, index) => {
                        const isAccepted = !!acceptedLicenses.find(
                          (l) =>
                            license.licence_id === l.licence_id &&
                            license.version_number === l.version_number
                        );

                        return (
                          <div key={index} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={isAccepted}
                              onChange={(event) => {
                                event.preventDefault();

                                setLicenseModal(license);
                              }}
                            />
                            <label
                              className="form-check-label btn-link"
                              onClick={(event) => {
                                event.preventDefault();

                                setLicenseModal(license);
                              }}
                            >
                              {license.display_name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              <div className="submit-btn-group ">
                <button
                  type="button"
                  disabled={isDisabled}
                  className="btn btn-secondary btn-hover-outline w-100"
                  onClick={submit}
                >
                  {t("confirm")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <LicenseModal
        url={licenseModal?.document_url}
        name={licenseModal?.display_name}
        onClose={(agreed) => {
          if (agreed) {
            acceptLicense(licenseModal, true);
          }

          setLicenseModal(undefined);
        }}
      />
    </div>
  );
};

export default AcceptInvite;
