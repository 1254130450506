import React from "react";
import { UTMTrackerProvider } from "./components/signup/UTMTrackerContext";
import { PermissionLoader } from "./permissions/PermissionLoader";
import { UserDataProvider } from "./UserDataContext";

const UserProviders = ({ children }) => (
  <UTMTrackerProvider>
    <UserDataProvider>
      <PermissionLoader>{children}</PermissionLoader>
    </UserDataProvider>
  </UTMTrackerProvider>
);

export default UserProviders;
