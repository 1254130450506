import {
  ADD_VIDEO_REQUEST,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FAILURE,
  VIDEO_SUCCESS,
  VIDEO_FAILURE,
} from "../actions/types";

export function videoValidation(
  state = { filename: "", videoLoading: false },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case ADD_VIDEO_REQUEST:
      return {
        ...state,
        videoLoading: true,
      };
    case ADD_VIDEO_SUCCESS:
      return {
        ...state,
        ...payload,
        filename: payload.data.filename,
        videoLoading: false,
      };
    case ADD_VIDEO_FAILURE:
      return {
        ...state,
        ...payload,
        filename: "",
      };
    default:
      return state;
  }
}

export function videoUpload(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case VIDEO_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case VIDEO_FAILURE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
