export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAIL = "USER_DATA_FAIL";

export const EDIT_DATA_SUCCESS = "EDIT_DATA_SUCCESS";
export const EDIT_DATA_FAIL = "EDIT_DATA_FAIL";
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const ADD_STORE_FAILURE = "ADD_STORE_FAILURE";
export const GAME_STAGE_SUCCESS = "GAME_STAGE_SUCCESS";
export const ADD_VIDEO_REQUEST = "ADD_VIDEO_REQUEST";
export const ADD_VIDEO_SUCCESS = "ADD_VIDEO_SUCCESS";
export const ADD_VIDEO_FAILURE = "ADD_VIDEO_FAILURE";
export const VIDEO_SUCCESS = "VIDEO_SUCCESS";
export const VIDEO_FAILURE = "VIDEO_FAILURE";
export const ADD_FIRST_STEP_SUCCESS = "ADD_FIRST_STEP_SUCCESS";
export const ADD_FIRST_STEP_FAILURE = "ADD_FIRST_STEP_FAILURE";

export const GET_PROJECT_LIST_REQUEST = "GET_PROJECT_LIST_REQUEST";
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_FAILURE = "GET_PROJECT_LIST_FAILURE";

export const GET_PROJECT_INFORMATION_SUCCESS =
  "GET_PROJECT_INFORMATION_SUCCESS";
export const GET_PROJECT_INFORMATION_FAILURE =
  "GET_PROJECT_INFORMATION_FAILURE";

export const SUBMIT_STEP_ONE_SUCCESS = "SUBMIT_STEP_ONE_SUCCESS";
export const SUBMIT_STEP_ONE_FAILURE = "SUBMIT_STEP_ONE_FAILURE";
export const SUBMIT_STEP_ONE_PURGE = "SUBMIT_STEP_ONE_PURGE";

export const SUBMIT_STEP_TWO_SUCCESS = "SUBMIT_STEP_TWO_SUCCESS";
export const SUBMIT_STEP_TWO_FAILURE = "SUBMIT_STEP_TWO_FAILURE";

export const SUBMIT_STEP_THREE_SUCCESS = "SUBMIT_STEP_THREE_SUCCESS";
export const SUBMIT_STEP_THREE_FAILURE = "SUBMIT_STEP_THREE_FAILURE";

export const SUBMIT_STEP_FOUR_SUCCESS = "SUBMIT_STEP_FOUR_SUCCESS";
export const SUBMIT_STEP_FOUR_FAILURE = "SUBMIT_STEP_FOUR_FAILURE";

export const SUBMIT_STEP_LAST_SUCCESS = "SUBMIT_STEP_LAST_SUCCESS";
export const SUBMIT_STEP_LAST_FAILURE = "SUBMIT_STEP_LAST_FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

/**
 * ------------------------- New flow api -------------------------------
 */

export const GET_LIST_PROJECT_REQUEST = "GET_LIST_PROJECT_REQUEST";
export const GET_LIST_PROJECT_SUCCESS = "GET_LIST_PROJECT_SUCCESS";
export const GET_LIST_PROJECT_FAILURE = "GET_LIST_PROJECT_FAILURE";

export const GET_DETAILS_PROJECT_SUCCESS = "GET_DETAILS_PROJECT_SUCCESS";
export const GET_DETAILS_PROJECT_FAILURE = "GET_DETAILS_PROJECT_FAILURE";

export const GET_TASK_REQUEST = "GET_TASK_REQUEST";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";
export const GET_TASK_PURGE = "GET_TASK_PURGE";

export const GET_PROJECT_SHEET_SUCCESS = "GET_PROJECT_SHEET_SUCCESS";
export const GET_PROJECT_SHEET_FAILURE = "GET_PROJECT_SHEET_FAILURE";

export const GET_TASK_TEMPLATE_SUCCESS = "GET_TASK_TEMPLATE_SUCCESS";
export const GET_TASK_TEMPLATE_FAILURE = "GET_TASK_TEMPLATE_SUCCESS";

export const FACEBOOK_VALIDATE_REQUEST = "FACEBOOK_VALIDATE_REQUEST";
export const FACEBOOK_VALIDATE_SUCCESS = "FACEBOOK_VALIDATE_SUCCESS";
export const FACEBOOK_VALIDATE_FAILURE = "FACEBOOK_VALIDATE_FAILURE";

export const TASK_UPDATE_SUCCESS = "TASK_UPDATE_SUCCESS";
export const TASK_UPDATE_FAILURE = "TASK_UPDATE_FAILURE";

export const PROJECT_SHEET_UPDATE_SUCCESS = "PROJECT_SHEET_UPDATE_SUCCESS";
export const PROJECT_SHEET_UPDATE_FAILURE = "PROJECT_SHEET_UPDATE_FAILURE";

export const PROJECT_SHEET_GET_SUCCESS = "PROJECT_SHEET_GET";
export const PROJECT_SHEET_FAILURE = "PROJECT_SHEET_FAILURE";
export const PROJECT_SHEET_PURGE = "PROJECT_SHEET_PURGE";

export const VALIDATE_APP_STORE_REQUEST = "VALIDATE_APP_STORE_REQUEST";
export const VALIDATE_APP_STORE_SUCCESS = "VALIDATE_APP_STORE_SUCCESS";
export const VALIDATE_APP_STORE_FAILURE = "VALIDATE_APP_STORE_FAILURE";
export const VALIDATE_APP_STORE_PURGE = "VALIDATE_APP_STORE_PURGE";

export const GET_PROJECT_SHEET_FOR_DETAILS_SUCCESS =
  "GET_PROJECT_SHEET_FOR_DETAILS_SUCCESS";
export const GET_PROJECT_SHEET_FOR_DETAILS_FAILURE =
  "GET_PROJECT_SHEET_FOR_DETAILS_FAILURE";

export const PROJECT_TESTS_GET_SUCCESS = "PROJECT_TESTS_GET_SUCCESS";
export const PROJECT_TESTS_GET_FAILURE = "PROJECT_TESTS_GET_FAILURE";

export const PROJECT_TEST_GET_SUCCESS = "PROJECT_TEST_GET_SUCCESS";
export const PROJECT_TEST_GET_FAILURE = "PROJECT_TEST_GET_FAILURE";
export const PROJECT_TEST_PURGE = "PROJECT_TEST_PURGE";

export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS";
export const VALIDATE_EMAIL_FAILURE = "VALIDATE_EMAIL_FAILURE";

export const LOST_PASSWORD_EMAIL_SUCCESS = "LOST_PASSWORD_EMAIL_SUCCESS";
export const LOST_PASSWORD_EMAIL_FAILURE = "LOST_PASSWORD_EMAIL_FAILURE";

export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_FAILURE = "PROJECT_DELETE_FAILURE";

export const SUBMIT_SDK_BUTTON_SUCCESS = "SUBMIT_SDK_BUTTON_SUCCESS";
export const SUBMIT_SDK_BUTTON_FAILURE = "SUBMIT_SDK_BUTTON_FAILURE";
export const SUBMIT_SDK_BUTTON_PURGE = "SUBMIT_SDK_BUTTON_PURGE";

export const SUBMIT_STORE_BUTTON_SUCCESS = "SUBMIT_STORE_BUTTON_SUCCESS";
export const SUBMIT_STORE_BUTTON_FAILURE = "SUBMIT_STORE_BUTTON_FAILURE";
export const SUBMIT_STORE_BUTTON_PURGE = "SUBMIT_STORE_BUTTON_PURGE";

export const SUBMIT_VIDEO_BUTTON_SUCCESS = "SUBMIT_VIDEO_BUTTON_SUCCESS";
export const SUBMIT_VIDEO_BUTTON_FAILURE = "SUBMIT_VIDEO_BUTTON_FAILURE";
export const SUBMIT_VIDEO_BUTTON_PURGE = "SUBMIT_VIDEO_BUTTON_PURGE";

export const SUBMIT_PROJECT_TEST_SUCCESS = "SUBMIT_PROJECT_TEST_SUCCESS";
export const SUBMIT_PROJECT_TEST_FAILURE = "SUBMIT_PROJECT_TEST_FAILURE";

export const GET_PROJECT_RESOURCE_SUCCESS = "GET_PROJECT_RESOURCE_SUCCESS";
export const GET_PROJECT_RESOURCE_FAILURE = "GET_PROJECT_RESOURCE_FAILURE";

export const GET_ADMIN_TRIGGER_APPROVAL_REQUEST =
  "GET_ADMIN_TRIGGER_APPROVAL_REQUEST";
export const GET_ADMIN_TRIGGER_APPROVAL_SUCCESS =
  "GET_ADMIN_TRIGGER_APPROVAL_SUCCESS";
export const GET_ADMIN_TRIGGER_APPROVAL_FAILURE =
  "GET_ADMIN_TRIGGER_APPROVAL_FAILURE";

export const UPDATE_ADMIN_TRIGGER_APPROVAL_SUCCESS =
  "UPDATE_ADMIN_TRIGGER_APPROVAL_SUCCESS";
export const UPDATE_ADMIN_TRIGGER_APPROVAL_FAILURE =
  "UPDATE_ADMIN_TRIGGER_APPROVAL_FAILURE";

export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAILURE = "GET_ADMIN_USERS_FAILURE";

export const GET_ADMIN_PROJECT_SHEET_SUCCESS =
  "GET_ADMIN_PROJECT_SHEET_SUCCESS";
export const GET_ADMIN_PROJECT_SHEET_FAILURE =
  "GET_ADMIN_PROJECT_SHEET_FAILURE";

export const GET_ADMIN_ATTRIBUTIONS_REQUEST = "GET_ADMIN_ATTRIBUTIONS_REQUEST";
export const GET_ADMIN_ATTRIBUTIONS_SUCCESS = "GET_ADMIN_ATTRIBUTIONS_SUCCESS";
export const GET_ADMIN_ATTRIBUTIONS_FAILURE = "GET_ADMIN_ATTRIBUTIONS_FAILURE";

export const GET_ORGANISATION_REQUEST = "GET_ORGANISATION_REQUEST";
export const GET_ORGANISATION_SUCCESS = "GET_ORGANISATION_SUCCESS";
export const GET_ORGANISATION_FAILURE = "GET_ORGANISATION_FAILURE";

export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_FAILURE = "UPDATE_ORGANISATION_FAILURE";

export const GET_WALLET_DETAILS_SUCCESS = "GET_WALLET_DETAILS_SUCCESS";
export const GET_WALLET_DETAILS_FAILURE = "GET_WALLET_DETAILS_FAILURE";

export const GET_PROJECT_HEAD_SUCCESS = "GET_PROJECT_HEAD_SUCCESS";
export const GET_PROJECT_HEAD_FAILURE = "GET_PROJECT_HEAD_FAILURE";

export const GET_ADMIN_PROJECTS_TESTS_SUCCESS =
  "GET_ADMIN_PROJECTS_TESTS_SUCCESS";
export const GET_ADMIN_PROJECTS_TESTS_FAILURE =
  "GET_ADMIN_PROJECTS_TESTS_FAILURE";

export const GET_ADMIN_PROJECTS_TESTS_ID_SUCCESS =
  "GET_ADMIN_PROJECTS_TESTS_ID_SUCCESS";
export const GET_ADMIN_PROJECTS_TESTS_ID_FAILURE =
  "GET_ADMIN_PROJECTS_TESTS_ID_FAILURE";

export const GET_LICENCE_SUCCESS = "GET_LICENCE_SUCCESS";
export const GET_LICENCE_FAILURE = "GET_LICENCE_FAILURE";

export const AGREE_LICENCE_SUCCESS = "AGREE_LICENCE_SUCCESS";
export const AGREE_LICENCE_FAILURE = "AGREE_LICENCE_FAILURE";

export const MAINTENANCE_STATE = "MAINTENANCE_STATE";

export const RELEVANT_FEEDBACK_INFO = "RELEVANT_FEEDBACK_INFO";

/* Notification - snackbar constants */
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

/* Confirmbox constants */
export const SHOW_CONFIRMBOX = "SHOW_CONFIRMBOX";
export const HIDE_CONFIRMBOX = "HIDE_CONFIRMBOX";

export const GET_ERROR_MAPPING_SUCCESS = "GET_ERROR_MAPPING_SUCCESS";
export const GET_ERROR_MAPPING_FAILURE = "GET_ERROR_MAPPING_FAILURE";

export const TOGGLE_SUBMIT_GAME_POPOVER = "TOGGLE_SUBMIT_GAME_POPOVER";
