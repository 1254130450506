import React from "react";
import { PERMISSION_ACCESS_READ } from "../../../permissions/permissionAccessLevels";
import {
  PERMISSION_ADMIN_ORGANISATION,
  PERMISSION_ADMIN_ORGANISATION_NOTIFICATION_PREFERENCE,
} from "../../../permissions/permissions";
import { permissionRequired } from "../../authorisations";

const ROUTES_ADMIN_ORGANISATION = [
  {
    id: "admin.organisation.preferences",

    path: "/admin/organisation/preferences",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_ORGANISATION_NOTIFICATION_PREFERENCE,
      PERMISSION_ACCESS_READ
    ),

    Component: React.lazy(() =>
      import(
        "../../../components/admin/organisation/OrganisationPreferenceAdmin"
      )
    ),
  },
  {
    id: "admin.organisation",

    path: "/admin/organisation",
    exact: true,
    isAuthorised: permissionRequired(
      PERMISSION_ADMIN_ORGANISATION,
      PERMISSION_ACCESS_READ
    ),

    Component: React.lazy(() =>
      import("../../../components/admin/organisation/OrganisationsAdmin")
    ),

    navigation: {
      internal: true,
      order: 6,
      group: "Users",
    },

    children: [
      {
        id: "inspect",

        path: "/admin/organisation/:organisationId",
        exact: true,
        isAuthorised: permissionRequired(
          PERMISSION_ADMIN_ORGANISATION,
          PERMISSION_ACCESS_READ
        ),

        Component: React.lazy(() =>
          import("../../../components/admin/organisation/OrganisationAdmin")
        ),
      },
    ],
  },
];

export default ROUTES_ADMIN_ORGANISATION;
