import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
        <div className="copy-right">
          <p>{t("footer.copyright", { year: new Date().getFullYear() })}</p>
        </div>
      </div>
    </footer>
  );
};

export default connect(null)(Footer);
