import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Logo from "../Logo";

export function NotFound() {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div className="full-page">
      {/*Main wrapper start*/}
      <div className="full-page-section">
        <div className="full-page-form">
          <div className="full-page-form-container">
            <div className="full-page-head">
              <Logo />
              <h1>{t("page.notFound.title")}</h1>
              <button
                type="button"
                onClick={() => history.push("/")}
                className="btn btn-secondary btn-hover-outline w-100"
              >
                {t("page.notFound.goHome")}{" "}
                <i className="fa fa-arrow-right" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
