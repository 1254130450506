import { useEffect, useState } from "react";

export default function useExperiment({ experimentId }) {
  const [variant, setVariant] = useState(undefined);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // @ts-ignore
      if (window.google_optimize !== undefined) {
        // Set the variant to the state.
        // @ts-ignore
        setVariant(window.google_optimize.get(experimentId));

        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [experimentId]);

  useEffect(() => {
    console.group(`Experiment: ${experimentId}`);
    console.log(`Variant: ${variant}`);
    console.groupEnd();
  }, [experimentId, variant]);

  return variant;
}
